import { 
    ContactsFieldIdMapping, 
    JobsFieldIdMapping, 
    DealsFieldIdMapping, 
    CompaniesFieldIdMapping, 
    GrowersFieldIdMapping,
    BranchesFieldIdMapping,
    LabsFieldIdMapping,
    ShiftsFieldIdMapping,
    ShiftTimesFieldIdMapping,
    RobotFieldIdMapping,
    DropoffsFieldIdMapping,
    ShiftDropoffsFieldIdMapping,
    LabTestsFieldIdMapping,
    BoxFieldIdMapping,
    BoxShipmentsFieldIdMapping,
    CourierPickupsFieldIdMapping,
    JobReadyFieldIdMapping,
} from "@rogoag/airtable/rogo.at";
import { FieldSet } from "airtable";
import axios, { AxiosRequestConfig } from 'axios';
import { inflate } from 'pako';
import { BaseDataModel } from "./types/data_models";

export const USER_NOT_FOUND = "User not found";
export const USER_NOT_LINKED = "User must be linked to at least one company";
export const NEW_USER_TOKEN = "new_user";

export const REQUEST_CONFIG_NOAUTH: AxiosRequestConfig<any> = {
    headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
    },
};

export function getLoginToken() {
    return localStorage.getItem('rogo_id_token');
}
export function setLoginToken(token: string) {
    return localStorage.setItem('rogo_id_token', token);
}

export function REQUEST_CONFIG_AUTH(): AxiosRequestConfig<any> {
    return {
        headers: { Authorization: `Bearer ${getLoginToken()}` },
    };
}

export function REQUEST_CONFIG_AUTH_COMPRESSED(): AxiosRequestConfig<any> {
    return {
        headers: { Authorization: `Bearer ${getLoginToken()}` },
        responseType: 'blob',
    };
}

export function REQUEST_CONFIG_AUTH_UPLOAD(): AxiosRequestConfig<any> {
    return {
        headers: { 
            Authorization: `Bearer ${getLoginToken()}`, 
            'Content-Type': 'multipart/form-data' 
        },
    };
}

export async function getCompressed(url: string) {
    const response = await axios.get(url, REQUEST_CONFIG_AUTH_COMPRESSED());
    const decompressedData = inflate(await response.data.arrayBuffer(), { to: 'string' });
    var records = JSON.parse(decompressedData);
    return records;
}

export type FieldIdMapping = typeof JobsFieldIdMapping 
                            | typeof DealsFieldIdMapping
                            | typeof CompaniesFieldIdMapping
                            | typeof GrowersFieldIdMapping
                            | typeof ContactsFieldIdMapping
                            | typeof BranchesFieldIdMapping
                            | typeof LabsFieldIdMapping
                            | typeof ShiftsFieldIdMapping
                            | typeof ShiftTimesFieldIdMapping
                            | typeof RobotFieldIdMapping
                            | typeof DropoffsFieldIdMapping
                            | typeof ShiftDropoffsFieldIdMapping
                            | typeof LabTestsFieldIdMapping
                            | typeof BoxFieldIdMapping
                            | typeof BoxShipmentsFieldIdMapping
                            | typeof CourierPickupsFieldIdMapping
                            | typeof JobReadyFieldIdMapping;

export interface ProAgricaAuth {
    access_token: string;
    expires_in: number;
    id_token: string;
    refresh_token: string;
    scope: string;
    token_type: string;
}
export interface AuthUser {
    id: string;
    name: string;
    token: string;
    username: string;
    email: string;
    full_name: string;
    disabled: boolean;
    company: string[];
    proagrica_auth?: ProAgricaAuth;
    isRogoEmployee: boolean;
    isAdmin: boolean;
}

/**
 * For querying data.
 * Supports nested filters.
 */
export interface Filter {
    type?: "AND" | "OR";
    fields: (FilterField | Filter)[];
}
/**
 * Use only one field per object.
 * Doesn't support multiple fields per object.
 * Use array instead for that.
 */
export interface FilterField {
    id?: string;
    companyName?: string;
    season?: string;
    active?: boolean;
    configOnly?: boolean;
}
export type FilterFieldIds = {
    [key in keyof FilterField]: string;
};

// Short duration JWT token (5-10 min)
export function getJwtToken() {
    return localStorage.getItem("jwt")
}

export function setJwtToken(token: string) {
    localStorage.setItem("jwt", token)
}

// Longer duration refresh token (30-60 min)
export function getRefreshToken() {
    return localStorage.getItem("refreshToken")
}

export function setRefreshToken(token: string) {
    localStorage.setItem("refreshToken", token)
}

const SPRING = "A-Spr";
const SUMMER = "B-Sum";
const FALL = "C-Fa";
const WINTER = "D-Win";

/**
 * @returns The season code (e.g. "24A-Spr")
 */
export function getSeasonCode(date: Date): string {
    const year = date.getFullYear() % 100; // Get last two digits of the year
    const month = date.getMonth(); // Get month (0-11)

    let season: string;
    if (month >= 2 && month <= 4) {
        season = SPRING; // March - May
    } else if (month >= 5 && month <= 7) {
        season = SUMMER; // June - August
    } else if (month >= 8 && month <= 10) {
        season = FALL; // September - November
    } else {
        season = WINTER; // December - February
    }

    return `${year}${season}`;
}

/**
 * @param seasonCode The season code (e.g. "24A-Spr")
 * @returns The previous season code (e.g. "23D-Win")
 */
export function getPreviousSeasonCode(date: Date): string {
    const seasonCode = getSeasonCode(date);
    const year = parseInt(seasonCode.slice(0, 2), 10);
    const season = seasonCode.slice(2);

    let previousSeason: string;
    let previousYear = year;

    switch (season) {
        case SPRING:
            previousSeason = WINTER;
            previousYear -= 1;
            break;
        case SUMMER:
            previousSeason = SPRING;
            break;
        case FALL:
            previousSeason = SUMMER;
            break;
        case WINTER:
            previousSeason = FALL;
            break;
        default:
            throw new Error("Invalid season code");
    }

    return `${previousYear < 10 ? '0' : ''}${previousYear}${previousSeason}`;
}

export function getBaseDataURL(router: string) {
    return `${import.meta.env.VITE_ROGO_API_URL}/data/${router}`;
}

export function parseDates<T extends FieldSet, U extends BaseDataModel<T>>(records: U[]) {
    records.forEach(record => {
        for (const key in record) {
            if (record[key] && typeof record[key] === 'string' && !isNaN(Date.parse(record[key])) && (key as string).includes('date')) {
                record[key] = new Date(record[key]) as any;
            }
        }
    });
}