import { Box, Button, TextField, Typography } from '@mui/material';
import React, { ChangeEvent } from 'react';
import { useNavigate } from 'react-router-dom';
import { LoadingComponent } from '../helpers/LoadingComponent';
import { API } from '../../api';
import { Oops } from '../../utilities/oops';

interface PasswordResetProps {
    setLoggedIn: (user: any) => void;
}

export default function PasswordReset(props: PasswordResetProps) {
    const [email, setEmail] = React.useState('');
    const [emailError, setEmailError] = React.useState('');
    const [error, setError] = React.useState('');
    const [loading, setLoading] = React.useState(false);
    const [password, setPassword] = React.useState('');
    const [passwordError, setPasswordError] = React.useState('');
    const [confirmPassword, setConfirmPassword] = React.useState('');
    const [confirmPasswordError, setConfirmPasswordError] = React.useState('');

    const navigate = useNavigate();

    const queryString = window.location.hash.split('?')[1];
    const params = new URLSearchParams(queryString);
    const token = params.get('token') ?? "";

    const handlePasswordReset = async (event: ChangeEvent<HTMLFormElement>) => {
        event.preventDefault();
        setLoading(true);
        if (emailError) {
            setLoading(false);
            return;
        }
        setError('');


        try {
            await API.Authentication.resetPassword(email);
            alert('Check your email for a link to reset your password.');
            navigate('/login');
        } catch (error: any) {
            Oops.handleError(error, "Authentication.PasswordReset.handlePasswordReset", email);
        } finally {
            setLoading(false);
        }
    };

    const handlePasswordUpdate = async (event: ChangeEvent<HTMLFormElement>) => {
        event.preventDefault();
        setLoading(true);
        if (emailError) {
            setLoading(false);
            return;
        }
        setError('');

        try {
            if (await API.Authentication.updatePassword(email, password, token)){
                alert('Your password has been updated.');
            } else {
                throw Error('Password update failed');
            }
            navigate('/login');
        } catch (error: any) {
            Oops.handleError(error, "Authentication.PasswordReset.handlePasswordUpdate", email);
        } finally {
            setLoading(false);
        }
    };

    const emailPattern = /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/;
    const handleEmailChange = (event: { target: { value: React.SetStateAction<string>; }; }) => {
        setEmailError('');
        // validate that username is an email with regex
        if (!emailPattern.test(event.target.value.toString())) {
            setEmailError("Invalid email address");
        }

        setEmail(event.target.value);
    };

    function handlePasswordChange(event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>): void {
        setPasswordError('');
        const password = event.target.value;
        if (password == '') {
            setPasswordError('Password cannot be empty')
            return;
        }

        if (password.length < 8) {
            setPasswordError('Password must be at least 8 characters');
            return;
        }


        setPassword(password);
        if (password != confirmPassword) {
            setPasswordError('Passwords do not match');
            return;
        }

    }

    function handleConfirmPasswordChange(event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>): void {
        setConfirmPasswordError('');
        const confirmPassword = event.target.value;
        if (confirmPassword == '') {
            setConfirmPasswordError('Password cannot be empty')
            return;
        }

        if (confirmPassword.length < 8) {
            setConfirmPasswordError('Password must be at least 8 characters');
            return;
        }

        setConfirmPassword(password);
        if (confirmPassword != password) {
            setConfirmPasswordError('Passwords do not match');
            return;
        }
    }

    const [isTokenValid, setIsTokenValid] = React.useState(false);

    React.useEffect(() => {
        async function checkToken() {
            try {
                var validatedEmail = await API.Authentication.validateNewPasswordToken(token);
                var isValid = isValidToken(validatedEmail);
                setIsTokenValid(isValid);

                function isValidToken(validatedEmail: string) {
                    var isEmptyToken = (token === null || token === '');
                    var isValid = false;
                    if (isEmptyToken){
                        isValid = false;
                    } else if (validatedEmail !== null && validatedEmail !== '' && !isEmptyToken) {
                        setEmail(validatedEmail);
                        isValid = true;
                    } else {
                        isValid = false;
                    }
                    return isValid;
                }
            } catch (error: any) {
                Oops.handleError(error, "Authentication.PasswordReset.checkToken", email || validatedEmail);
            }
        }
        checkToken();
    }, []);

    if (isTokenValid) {
        return (
            <Box
                maxWidth="md"
                margin="auto"
                component="form"
                noValidate
                sx={{
                    mt: 1,
                }}
                onSubmit={handlePasswordUpdate}
            >
                {(loading) && (
                    <Box display="flex" justifyContent="center" alignItems="center">
                        <LoadingComponent />
                    </Box>
                )}
                <TextField
                    variant="outlined"
                    margin="normal"
                    error={!!passwordError}
                    helperText={passwordError}
                    required
                    disabled={loading}
                    fullWidth
                    name="password"
                    label="New Password"
                    type="password"
                    id="password"
                    autoComplete="current-password"
                    onChange={handlePasswordChange}
                    onBlur={handlePasswordChange}
                    data-sentry-component="New Password Field"
                />
                <TextField
                    variant="outlined"
                    margin="normal"
                    error={!!confirmPasswordError}
                    helperText={confirmPasswordError}
                    required
                    disabled={loading}
                    fullWidth
                    name="Confirm password"
                    label="Confirm Password"
                    type="password"
                    id="Confirm password"
                    autoComplete="confirm-password"
                    onChange={handleConfirmPasswordChange}
                    onBlur={handleConfirmPasswordChange}
                    data-sentry-component="Confirm Password Field"
                />
                {error &&
                    <Typography
                        variant="body2"
                        color="textSecondary"
                        align="center"
                        style={{ color: 'red' }}
                    >
                        {error}
                    </Typography>}
                <Typography style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '5vh' }}>
                    Please enter your new password.
                </Typography>
                <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    sx={{ mt: 3, mb: 2 }}
                    data-sentry-component="Submit Button"
                >
                Update Password</Button>
    
            </Box>
    
        );
    } else {
        return (
            <Box
                maxWidth="md"
                margin="auto"
                component="form"
                noValidate
                sx={{
                    mt: 1,
                }}
                onSubmit={handlePasswordReset}
            >
                {(loading) && (
                    <Box display="flex" justifyContent="center" alignItems="center">
                        <LoadingComponent />
                    </Box>
                )}
                <TextField
                    margin="normal"
                    required
                    id="email"
                    label="Email Address"
                    name="email"
                    autoComplete="email"
                    autoFocus
                    error = {!!emailError}
                    helperText = {emailError}
                    onChange={handleEmailChange}
                    onBlur = {handleEmailChange}
                    data-sentry-component="Email Field"
                />
                {error &&
                    <Typography
                        variant="body2"
                        color="textSecondary"
                        align="center"
                        style={{ color: 'red' }}
                    >
                        {error}
                    </Typography>}
                <Typography style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '5vh' }}>
                    Please enter the email associated with your Rogo Account.
                </Typography>
                <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    sx={{ mt: 3, mb: 2 }}
                    data-sentry-component="Submit Button"
                >
                Reset Password</Button>
    
            </Box>
    
        );
    }

}
