import React, { useCallback, useState } from 'react';
import AdjustIcon from '@mui/icons-material/Adjust';
import {
    AdvancedMarker,
    InfoWindow,
    useAdvancedMarkerRef,
    useMap
} from '@vis.gl/react-google-maps';
import useGlobalDOMEvents from '../../../hooks/useGlobalDOMEvents';
import { randomInt } from 'crypto';

interface Props {
    position: google.maps.LatLngLiteral;
    label: string;
    infoWindowText: React.ReactNode;
    iconColor?: "error" | "inherit" | "disabled" | "action" | "primary" | "secondary" | "info" | "success" | "warning";
}

const MAX_ZOOM_SCALE = 16;

export const MarkerWithInfoWindow = (props: Props) => {
    // calculate a unique ID for the marker based on the position + label
    const markerId = props.position.lat.toString() + props.position.lng.toString() + props.label;
    const [infowindowOpen, setInfoWindowOpen] = useState(false);
    const [markerRef, marker] = useAdvancedMarkerRef();

    const map = useMap();
    
    useGlobalDOMEvents({
        message(payload) {
            // @ts-ignore
            const { type, source } = payload.data
            if (type === 'closeInfoWindow' && source !== markerId) {
                setInfoWindowOpen(false);
            }
        }
    });

    
    const mapZoom = map?.getZoom() ?? MAX_ZOOM_SCALE;

    return (
        <>
            <AdvancedMarker
                ref={markerRef}
                onClick={() => {
                    window.postMessage({ type: 'closeInfoWindow', source: markerId }, '*');
                    setInfoWindowOpen(true);
                }}
                position={props.position}
                title={props.label}
                style={{
                    // translate 50% in Y direction
                    transform: 'translateY(50%)',
                    scale: Math.min(mapZoom / MAX_ZOOM_SCALE, 1)
                }}
            >
                <AdjustIcon color={props.iconColor} />
            </AdvancedMarker>
            {infowindowOpen && (
                <InfoWindow
                    pixelOffset={[0, 15]}
                    anchor={marker}
                    maxWidth={220}
                    onCloseClick={() => setInfoWindowOpen(false)}>
                    {props.infoWindowText}
                </InfoWindow>
            )}
        </>
    );
};