import { CognitoUser, CognitoUserPool, AuthenticationDetails } from 'amazon-cognito-identity-js';
import { DownloadOptions, ZipOptions } from "@mapbox/shp-write";
import axios from "axios";
import { AuthUser, getLoginToken } from '../common';
import { API, Job } from '..';

export interface CognitoConfig {
    UserPoolId: string;
    ClientId: string;
    Region: string;
}

const cognitoConfig: CognitoConfig = {
    UserPoolId: 'us-east-1_Bp6WnxrES',
    ClientId: 'hl92e3d9hkbt20g1c94ho5u0q',
    Region: 'us-east-1',
};

export interface AgVanceBoundary {
    fieldGuid: string;
    name: string;
    farmName: string;
    fieldBoundaryGuid: string;
    county: string;
    township: string;
    range: string;
    section: string;
    watershedNum: null | number;
    watershedName: null | string;
    calculatedArea: number;
    activeYn: boolean;
    fieldBoundaryPolygonGuid: string;
    shape: {
        type: string;
        coordinates: number[][][];
    };
    customerGuid: string;
    customerName: string;
    stateAbbr: string;
    centroid: {
        type: string;
        coordinates: number[];
    };
}

export interface AgVanceBoundariesData {
    boundaries: AgVanceBoundary[];
}


export interface AgVanceBoundaryCentroidResult {
    customerGuid: string;
    fieldGuid: string;
    x: number;
    y: number;
}

export interface BoundaryCentroidResultsData {
    centroidResults: AgVanceBoundaryCentroidResult[];
}


export interface AgVanceField {
    id: string;
    name: string;
    activeYn: boolean;
    boundaryId: string;
    customerId: string;
    customerName: string;
    customerEnrolled: boolean;
    farmName: string;
    acres: number;
    events: number;
    recs: number;
}

export interface AgVanceFieldsData {
    fields: AgVanceField[];
}


export interface AgVanceSoilSamplePointDepth {
    depthId: string;
    endDepth: number;
    hasImportData: boolean | null;
    invalidDepth: boolean | null;
    scanCode: string | null;
    soilSamplePointDepthGuid: string;
    soilSamplePointGuid: string;
    startDepth: number;
}

export interface AgVanceSoilSamplePoint {
    agEventGuid: string;
    sampleType: string;
    sequenceId: number;
    sampleId: number;
    shape: string;
    status: number;
    soilSamplePointGuid: string;
    productivityRatingGuid: string | null;
    samplePointDepths: AgVanceSoilSamplePointDepth[];
    // for updates?
    batchSamplingEventGuid: string | null;
    isNew: boolean | null;
    fieldGuid: string | null;
}

export interface AgVanceSampleType {
    agEventTransactionTypeGuid: string;
    sampleTypeGuid: string;
    sampleType: string;
    typeName: string;
}

export interface AgVanceEventAreaDetails {
    agEventGuid: string;
    eventAreaGuid: string;
    agEventTransactionTypeGuid: string;
    agEventModel: AgEventModel;
}

export interface AgVanceEventArea {
    applyToArea: boolean;
    calculatedArea: number;
    classBreak: string | null;
    details: AgVanceEventAreaDetails[];
    fieldBoundaryGuid: string | null;
    generalGuid: string;
    id: number;
    polygons: Polygon[];
    zoneGuid: string;
}

export interface AgVancePerson {
    agEventGeneralPersonGuid: string;
    agEventGeneralGuid: string;
    personGuid: string;
    personName: string;
}

export interface AgVanceUpdateAgEvent {
    UserGuid: string;
    Model: AgVanceUpdateEventDetails[]
}

export interface AgVanceEventDetails {
    agEventGeneralGuid: string;
    agEventTypeList: AgVanceSampleType[];
    airTemperature: number | null;
    canEditEquipment: boolean;
    cloudCoverPercentage: number | null;
    copyRecGeneralGuid: string | null;
    creationType: string; // Imported?
    creationTypeGuid: string;
    cropGrowingDays: number | null;
    croppingSeasonCycleGuid: string;
    croppingSeasonGuid: string;
    customerGuid: string;
    densityRatingGuid: string | null;
    directionGuid: string | null;
    endDate: string;
    endTime: string;
    equipmentList: any[], // TODO not sure what this should be
    eventAreaList: AgVanceEventArea[];
    eventName: string;
    fieldBoundaryGuid: string;
    fieldGuid: string;
    filters: Record<string, any>; // The actual structure of filters may vary
    generalWeatherGuid: string | null;
    humidityPercentage: number | null;
    isClassified: boolean;
    isFromEquationRec: boolean;
    notes: string;
    personList: AgVancePerson[];
    precipitation: number | null;
    recommendation: string;
    soilConditionGuid: string | null;
    soilTemperature: number | null;
    startDate: string;
    startTime: string;
    windSpeed: number | null;
    zoneFileGuid: string | null;
}

export interface AgVanceUpdateEventDetails extends AgVanceEventDetails {
    modifiedBy: string;
    modifiedDate: string;
    momentEndDate: string;
    momentStartDate: string;
    reInterpolateFieldFoundYn: boolean;
    surfaceExploded: boolean;
}

export interface AgVanceEventDetailsData {
    event_details: AgVanceEventDetails[];
}

export interface LoginTheme {
    uiThemeGuid: string;
    name: string;
    id: string;
    colorScheme: string;
}

export interface AgVanceUserRole {
    userRoleGuid: string;
    name: string;
    description: string;
    accessLevel: number;
    activeYn: boolean;
    companyGuid: string;
    isAssigned: boolean;
    // ... (other role properties)
}

export interface AgVancePersonProperties {
    isUser: boolean;
    firstName: string;
    lastName: string;
    addressList: null | any[]; // Replace `any[]` with the actual type if known
    personGuid: null | string;
    userRoles: null | any[]; // Replace `any[]` with the actual type if known
    personOrgLevelList: OrgLevel[]; // Replace `any[]` with the actual type if known
    personOrgLevelIdList: string[]; // Replace `any[]` with the actual type if known
    personPhoneNumberList: null | any[]; // Replace `any[]` with the actual type if known
    personSalespersonList: null | any[]; // Replace `any[]` with the actual type if known
    personLicenseList: null | any[]; // Replace `any[]` with the actual type if known
    addUserAccountVisible: boolean;
    addEmailVisible: boolean;
    middleName: null | string;
    personAliasGuid: null | string;
    titleGuid: null | string;
    suffix: null | string;
    dateOfBirth: null | string;
    gender: null | string;
    activeYn: boolean;
    fullName: null | string;
    employeeGuid: null | string;
    startDate: null | string;
    endDate: null | string;
    supervisorGuid: null | string;
    supervisorName: null | string;
    payTypeGuid: null | string;
    payRate: null | string;
    payRateUnitGuid: null | string;
    personAddressList: null | any[]; // Replace `any[]` with the actual type if known
    personUrlList: null | any[]; // Replace `any[]` with the actual type if known
    personEmailList: null | any[]; // Replace `any[]` with the actual type if known
    jobTitleGuid: null | string;
    jobTitle: null | string;
    notes: null | string;
    personAutoReportList: null | any[]; // Replace `any[]` with the actual type if known
    userGuid: string;
    userTypeGuid: null | string;
    userRoleGuid: string;
    userActiveYn: boolean;
    loginGuid: string;
    loginEmail: string;
    timeZoneGuid: string;
    deleteUser: boolean;
    userCustomerList: any[]; // Replace `any[]` with the actual type if known
}

export interface AgVanceUser {
    activeYn: boolean;
    companyGuid: string;
    companyId: string;
    companyName: string;
    enrollmentRequiredYN: boolean;
    lastUsedCompanyGuid: string;
    lastUsedCustomerGuid: string;
    lastUsedCustomerName: string;
    lastUsedLocationGuid: string;
    lockCustomersNotEnrolledYn: boolean;
    loginGuid: string;
    loginTheme: LoginTheme;
    nounsOrgLevelGuid: string;
    orgLevelList: OrgLevel[];
    ownerPersonalityId: number;
    personGuid: string;
    personProperties: AgVancePersonProperties;
    realTimeFieldUpdates: boolean;
    role: AgVanceUserRole;
    systemProperties: SystemProperties;
    timeZoneGuid: string;
    topOrgLevelGuids: string[];
    userGuid: string;
    userRoleGuid: string;
    userTypeGuid: string;
    userTypeId: number;
}

export interface OrgLevel {
    city: string;
    orgLevelGuid: string;
    orgLevelName: string;
    orgLevelParents: string[];
    selected: boolean;
    state: string;
    agvanceLinked: boolean;
    enrollmentRequired: boolean;
    lockCustomersNotEnrolled: boolean;
}

export interface SystemProperties {
    systemGuid: null;
    id: number;
    systemName: string;
    privacyPolicyTitle: string;
    copyrightHolder: string;
    systemLogoDark: string;
    systemLogoLight: string;
    brandingYn: boolean;
    brandLogo: null;
    brandLogoURL: null;
    privacyPolicyText: string;
    privacyPolicyUpdatedDate: string;
    areaLimit: number;
    activeYn: boolean;
    brandingName: string;
    systemFavicon: null;
    testResultIssuesEmailList: null;
    sendResultsToEmailAddress: null;
    releaseNotes: null;
}

export interface UserProfile {
    userRoleGuid: string;
    name: string;
    description: string;
    accessLevel: number;
    activeYn: boolean;
    companyGuid: string;
    isAssigned: boolean;
    account: boolean;
    telematics: boolean;
    activeInactive: boolean;
    agBytes: boolean;
    agBytesCountryStateSetup: boolean;
    agBytesCropsSetup: boolean;
    agBytesEquipment: boolean;
    agBytesGenes: boolean;
    agBytesGrowthStages: boolean;
    agBytesSampleAttributesSetup: boolean;
    agBytesNutrientRemove: boolean;
    agBytesNutrientTargets: boolean;
    agBytesNutrients: boolean;
    agBytesObservations: boolean;
    agBytesPicklistSetup: boolean;
    agBytesProductsSetup: boolean;
    agBytesUnitsSetup: boolean;
    agBytesTraits: boolean;
    agBytesVarietyHybrid: boolean;
    agBytesVarietyHybridAttributes: boolean;
    analysisLayers: boolean;
    agEvents: boolean;
    agEventsApplication: boolean;
    agEventsCustom: boolean;
    agEventsEcData: boolean;
    agEventsHarvest: boolean;
    agEventsIrrigation: boolean;
    agEventsPlanting: boolean;
    locationMigrationTool: boolean;
    analytics: boolean;
    exports: boolean;
    exportSamplePoints: boolean;
    exportFieldBoundary: boolean;
    exportRecs: boolean;
    exportSurfaces: boolean;
    exportEventData: boolean;
    sampling: boolean;
    fertilizer: boolean;
    manureCompost: boolean;
    nematode: boolean;
    soil: boolean;
    tissue: boolean;
    water: boolean;
    samplingOptions: boolean;
    mergeEvents: boolean;
    zoneInterpolation: boolean;
    zoneSampling: boolean;
    scouting: boolean;
    agEventsTillage: boolean;
    financials: boolean;
    import: boolean;
    assignToAll: boolean;
    manageFields: boolean;
    cluBoundaries: boolean;
    resurfacing: boolean;
    agvanceBillableArea: boolean;
    notifications: boolean;
    reportWizard: boolean;
    agrIntelligenceReports: boolean;
    fieldReports: boolean;
    summaryReports: boolean;
    reportReportBooks: boolean;
    agBytesEquationParameterSetup: boolean;
    agBytesEquationSetSetup: boolean;
    agBytesEquationSetPlantingSetup: boolean;
    setup: boolean;
    setupAdminScripts: boolean;
    setupHierarchyZapper: boolean;
    setupReportBooks: boolean;
    setupReportBookOptions: boolean;
    setupCustomerProfile: boolean;
    aliasName: boolean;
    communityData: boolean;
    customerAdd: boolean;
    customerDelete: boolean;
    customerEdit: boolean;
    customerSalesperson: boolean;
    moveField: boolean;
    acrossCustomers: boolean;
    combineFields: boolean;
    nonFieldFeatures: boolean;
    setupEquipment: boolean;
    setupHierarchy: boolean;
    setupOwnerHierarchy: boolean;
    setupPersonProfile: boolean;
    personImportExport: boolean;
    personSalesperson: boolean;
    productAssignment: boolean;
    autoCreateReports: boolean;
    importExport: boolean;
    importFilters: boolean;
    systemOwner: boolean;
    systemSettings: boolean;
    setupUILabels: boolean;
    setupUserSetup: boolean;
    setupUserImportExport: boolean;
    setupUserRole: boolean;
    setupVendorSetup: boolean;
    setupVendorTestingLab: boolean;
    recommendations: boolean;
    manualApplication: boolean;
    equationApplication: boolean;
    manualPlanting: boolean;
    equationPlanting: boolean;
    workOrders: boolean;
    batch: boolean;
    batchEvents: boolean;
    batchRecs: boolean;
    batchEditRecs: boolean;
    batchAnalysisLayers: boolean;
    layers: boolean;
    layerAnalysis: boolean;
    layerEvents: boolean;
    layerRecs: boolean;
    preferences: boolean;
    realTimeUpdates: boolean;
    orgLevelQuickChanger: boolean;
    errorMessage: null | string;
}

export interface UserData {
    userProfile: UserProfile;
    systemProperties: SystemProperties;
}

export interface ImportPoints {
    importedPoints: number;
    totalPoints: number;
}

export interface EventSummary {
    croppingSeasonName: string;
    croppingSeasonGuid: string;
    agEventTypeName: string;
    agEventTransactionTypeGuid: string;
    agEventDisplayName: string;
    eventDate: string;
    modifiedDate: string;
    modifiedBy: string;
    agEventName: string;
    agEventGeneralGuid: string;
    coverageArea: string;
    cropDisplayName: string;
    cropGuids: string[];
    fieldGuid: string;
    agEventGuid: string;
    hasSoilSampleResults: boolean;
    numberOfSampleDepths: string;
    eventId: string;
    calculatedArea: number;
    importPoints: ImportPoints;
    importedStatus: number;
    activeYn: boolean;
    isImportedYn: boolean;
    isFromEquationRec: boolean;
    aggregatedEvent: boolean;
}

export interface EventArea {
    zonePolygons: ZonePolygon[];
    agEventList: AgEvent[];
    eventAreaGuid: string;
    eventAreaId: number;
    agEventGeneralGuid: string;
    applyEventToArea: boolean;
    calculatedArea: number;
    eventAreaClassBreak: null;
    fieldBoundaryGuid: null;
    eventGeneralGuid: string;
}

export interface AgEventModel {
    agEventGuid: string;
    agEventTransactionType: string;
    areaDimension: string;
    batchSamplingEventGuid: string | null;
    cropGuid: string | null;
    cropPurposeGuid: string | null;
    depthIAGuid: string;
    eventId: string;
    gridSize: number;
    gridUnitGuid: string;
    height: number;
    interpolationTypeId: number;
    offsetX: number;
    offsetY: number;
    pointPlacement: string;
    rotation: number;
    sampleMethodGuid: string;
    samplePoints: AgVanceSoilSamplePoint[];
    sampleTypeGuid: string;
    singleSampleIdPerZone: boolean;
    width: number;
}

// TODO this shape isn't always like this...
export type Shape = any;
// export interface Shape {
//     spatialReference: {
//         wkid: number;
//     };
//     rings: number[][][];
// }

export interface Polygon {
    shape: Shape;
    polygonGuid: null | string;
}

export interface ZonePolygon {
    shape: Shape;
    polygonGuid: null;
}

interface AgEventTypeInfo {
    agEventTransactionTypeGuid: string;
    sampleTypeGuid: string;
    agEventTransactionTypeName: string;
    sampleType: string;
    typeName: string;
}

interface AgEvent {
    agEventTypeInfo: AgEventTypeInfo;
    agEventGuid: string;
    eventAreaGuid: string;
    agEventTransactionTypeGuid: string;
    agEventModel: AgEventModel;
}

interface AgVanceJobImport {
    user: AgVanceUser;
    fields: {
        [fieldGuid: string]: {
            field: AgVanceField;
            boundary: AgVanceBoundary;
            event_details: AgVanceEventDetails[];
            event_summaries: EventSummary[];
        }
    }
}

const shapePointRegex = /POINT \((?<longitude>-?\d+\.\d+) (?<latitude>-?\d+\.\d+)\)/;

export class AgVanceAPI {
    private baseUrl: string;

    constructor() {
        this.baseUrl = `${import.meta.env.VITE_ROGO_API_URL}/agvance`;
    }

    async agvanceToJobData(result: string, authUser: AuthUser) {
    
        const jobData = await this.importAgVanceData();
        const user = await API.Data.Contacts.get.byId(authUser.id);
        const deals = await API.Data.Deals.get.byContact(user);
        const fields: Job[] = [];
        const baseObject: Pick<Job, "submitterName" | "submitterEmail"> = {
            submitterEmail: 'stephen.h@rogoag.com',
            submitterName: 'Stephen Heindel',
        }
    
        // clone the base object for each field/job
    
        for (const fieldGuid in jobData['fields']) {
            const fieldData = jobData['fields'][fieldGuid];
            const { event_summaries, event_details: eventDetails, field } = fieldData;
            const boundaryDataPolygon = fieldData.boundary.shape;
            const options: DownloadOptions & ZipOptions = {
                types: {
                    polygon: `${fieldGuid}_bnd`,
                    point: `${fieldGuid}_pts`,
                },
                compression: 'DEFLATE',
                outputType: 'blob'
            };
            const boundaryGeoJson: GeoJSON.FeatureCollection = {
                type: "FeatureCollection" as const,
                features: [{
                    type: "Feature" as const,
                    // @ts-ignore
                    geometry: { ...boundaryDataPolygon },
                    properties: {},
                }]
            };
            // console.log(boundaryGeoJson);
            // @ts-ignore
            const boundaryShp = await zip<"blob">(boundaryGeoJson, options)
            let ptsShp: Blob | undefined = undefined;
            const eventSummary = event_summaries?.length ? event_summaries[0] : undefined;
            // console.log(eventSummary);
            let eventGuid = '';
            if (eventSummary) {
                const details = eventDetails.find(
                    (detail) =>
                        detail.fieldGuid === eventSummary.fieldGuid
                );
    
                eventGuid = details?.agEventGeneralGuid || '';
    
                // TODO what are these event area polygons?
                // details?.eventAreaList[0].polgyons
                const samplePoints = details?.eventAreaList[0].details[0].agEventModel.samplePoints;
                // console.log(samplePoints);
                if (samplePoints) {
                    // TODO might filter out deals based on grid size
                    const gridSize = details?.eventAreaList[0].details[0].agEventModel.gridSize;
                    const samplePointGeoJSON = samplePoints.map(samplePoint => {
                        // also has a .status property, curious what that is
                        // POINT (-87.1656557016318 40.7676476427914)
                        const match = shapePointRegex.exec(samplePoint.shape);
                        if (match) {
                            const { longitude, latitude } = match.groups!;
                            // console.log(longitude, latitude);
                            return {
                                type: "Feature" as const,
                                geometry: {
                                    type: "Point" as const,
                                    coordinates: [longitude, latitude]
                                },
                                id: samplePoint.sampleId.toString(),
                                properties: {
                                    'spl id': samplePoint.sampleId.toString(),
                                    'st dpth': samplePoint.samplePointDepths[0].startDepth.toString(),
                                    'end dpth': samplePoint.samplePointDepths[0].endDepth.toString(),
                                    'guid': samplePoint.soilSamplePointGuid,
                                    'fld ID': fieldGuid,
                                }
                            }
                        }
                    });
    
                    const pointsGeoJson = {
                        type: "FeatureCollection" as const,
                        features: samplePointGeoJSON,
                    };
                    // console.log(pointsGeoJson);
                    // @ts-ignore
                    ptsShp = await zip<"blob">(pointsGeoJson, options)
                }
            }
    
            // @ts-ignore
            const job: Job = {
                ...baseObject,
                files: {
                    boundarySHP: [new File([boundaryShp], `${fieldGuid}_${(new Date()).valueOf()}_bnd.zip`)],
                    boundaryGeoJSON: boundaryGeoJson,
                    pointsSHP: ptsShp ? [new File([ptsShp], `${fieldGuid}_${(new Date()).valueOf()}_pts.zip`)] : [],
                },
                branchIds: [], // TODO update
                dealIds: [],
                externalId: eventGuid,
                fieldId: fieldGuid,
                farmName: field.farmName,
                fieldName: field.name,
                fieldPriority: 'Regular Turn (default)',
                growerPhoneNumber: '',
                growerName: field.customerName,
                growerIds: [],
                labSubmissionCode: '',
                isPointsAttached: !!ptsShp,
                isReadyToSample: false,
                labInstructions: '',
                submissionNotes: '',
                eventId: eventSummary?.eventId ?? '',
                depth: 0,
                frequencyOfMicros: undefined,
                jobFlags: [],
                id: Math.random().toString(36).substring(7)
            };
            fields.push(job);
        }
        return [deals, fields] as const;
    }
    
    async authenticate(username: string, password: string) {
        const poolData = {
            UserPoolId: cognitoConfig.UserPoolId,
            ClientId: cognitoConfig.ClientId,
        };
    
        const userPool: CognitoUserPool = new CognitoUserPool(poolData);
    
        const authenticationData = {
            Username: username,
            Password: password,
        };
    
        const authenticationDetails: AuthenticationDetails = new AuthenticationDetails(authenticationData);
    
        const userData = {
            Username: username,
            Pool: userPool,
        };
    
        const cognitoUser: CognitoUser = new CognitoUser(userData);
    
        return new Promise<string>((resolve, reject) => {
            cognitoUser.authenticateUser(authenticationDetails, {
                onSuccess: (session) => {
                    const idToken: string = session.getIdToken().getJwtToken();
                    // console.log('Id Token:', idToken);
                    localStorage.setItem('agvance_id_token', idToken);
                    return resolve(idToken);
                },
                onFailure: (err) => {
                    console.error('Authentication failed:', err);
                    reject(err);
                },
            })
        });
    }

    async importAgVanceData(): Promise<AgVanceJobImport> {
        const rogo_access_token = getLoginToken();
        const agvance_token = localStorage.getItem('agvance_id_token');
        const response = await axios.get(`${this.baseUrl}/jobs/import?agvance_jwt=${agvance_token}`, {
            headers: {
                Authorization: `Bearer ${rogo_access_token}`,
            }
        });
    
        return response.data;
    }
}
