import {
    Box,
    Button,
    Typography,
    Grid,
    Card,
    Accordion,
    AccordionSummary,
    AccordionDetails
} from "@mui/material";
import JSZip from "jszip";
import { toast } from "react-toastify";
import { GeoFileType, KeyedShapefile, RogoFeatures } from "../../../utilities/types";
import { ListOfFiles } from "./ListOfFiles";
import { SHP } from "../../../geojson_converters/shp";
import { KeyGenerationSelector } from "./KeyGenerationSelector";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { FeatureCollection } from "geojson";
import { Oops } from '../../../utilities/oops';
import { Add, NotInterested } from "@mui/icons-material";

interface FileTaggingAndSplittingProps {
    keyedShapefileMap: Map<string, KeyedShapefile[]>;
    setKeyedShapefileMap: React.Dispatch<React.SetStateAction<Map<string, KeyedShapefile[]>>>;
    selectableFiles: Record<string, Record<GeoFileType, File[]>>;
    setSelectableFiles: React.Dispatch<React.SetStateAction<Record<string, Record<GeoFileType, File[]>>>>;
    originalShapefiles: File[];
    setOriginalShapefiles: React.Dispatch<React.SetStateAction<File[]>>;
    loading: boolean;
    setLoading: React.Dispatch<React.SetStateAction<boolean>>;
}

export function FileTaggingAndSplitting(props: FileTaggingAndSplittingProps) {
    async function processShapeFiles(files: File[]) {
        const fileMap: Record<string, File[]> = {};
        async function processDir(dir: JSZip.JSZipObject, path: string) {
            // console.log('processDir', path, dir.name);
        }
        // recursively search all directories for relevant shapes, folders, or files
        async function processZip(file: File, path: string) {
            const zip = await JSZip.loadAsync(file);
            await Promise.all(Object.keys(zip.files).map(async (key) => {
                const zipEntry = zip.file(key);
                if (!zipEntry) return;
    
                const entryName = zipEntry.name;
    
                if (entryName.includes("__MACOSX")) return;
    
                if (zipEntry.dir) {
                    processDir(zipEntry, path);
                } else if (
                    entryName.endsWith('.shp') ||
                    entryName.endsWith('.shx') ||
                    entryName.endsWith('.dbf') ||
                    entryName.endsWith('.prj')
                ) {
                    // strip extension from path
                    const lastPeriod = entryName.lastIndexOf('.');
                    const filenameWithoutExtension = entryName.substring(0, lastPeriod);
                    // console.log('filenameWithoutExtension', filenameWithoutExtension);
                    const fullPath = `${path}/${filenameWithoutExtension}`;
                    if (!fileMap[fullPath]) {
                        fileMap[fullPath] = [];
                    }
                    fileMap[fullPath].push(new File([await zipEntry.async('blob')], entryName));
                } else if (entryName.endsWith('.zip')) {
                    await processZip(new File([await zipEntry.async('blob')], entryName), `${path}/${entryName}`);
                }
            }));
        }
        for (const file of files) {
            // console.log(`Processing ${file.name}`, fileMap);
            if (file.name.endsWith(".zip")) {
                await processZip(file, file.name);
            } // TODO other formats?
        }
    
        // console.log('fileMap', fileMap);
    
        return fileMap;
    
        // if (event.target.files) {
        //     // @ts-ignore
    
        //     //setBoundaryFiles([...event.target.files]);
        // }
    }

    return (<>
        <Grid item xs={12}>
            <Box display={'flex'}>
                <Button style={{ width: '80%', marginRight: 5 }} variant='contained' onClick={async () => {
                    const loadingFiles = toast.loading("Loading files...", { closeButton: true })
                    try {
                        // show file dialog
                        const fileInput = document.createElement('input');
                        fileInput.type = 'file';
                        fileInput.accept = '.zip';
                        fileInput.multiple = true;
                        fileInput.onchange = (event) => {
                            event.preventDefault();
                            props.setLoading(true);
                            // @ts-ignore
                            const newFiles: File[] = Array.from(event.target.files);
                            const oldFiles = [...props.originalShapefiles];
                            for (const newFile of newFiles) {
                                if (oldFiles.find(oldFile => oldFile.name === newFile.name)) {
                                    // remove from oldFiles
                                    const index = oldFiles.findIndex(oldFile => oldFile.name === newFile.name);
                                    oldFiles.splice(index, 1);
                                }
                            }

                            const allFiles = [...oldFiles, ...newFiles];

                            const asyncProcessing = async () => {
                                const fileMap = await processShapeFiles(allFiles);

                                props.setOriginalShapefiles(allFiles);

                                const features: RogoFeatures[] = [];
                                const featureCollections: FeatureCollection[] = [];
                                const keyedShapefiles = await Promise.all(Object.keys(fileMap).map(async fullPath => {

                                    const shapefiles = fileMap[fullPath];
                                    // zip shapefiles into single file
                                    const zip = new JSZip();
                                    shapefiles.forEach((file, index) => {
                                        // console.log('Adding file', file.name);
                                        zip.file(file.name, file);
                                    });
                                    if (!fullPath.endsWith('.zip')) {
                                        fullPath = fullPath + '.zip';
                                    }
                                    const shpBuffer = await zip.generateAsync({ type: "nodebuffer" });
                                    const geoJSON = await SHP.toGeoJSON(shpBuffer);
                                    featureCollections.push(geoJSON);
                                    // @ts-ignore
                                    const shapeCounts: Record<string, number> = {};

                                    if (!geoJSON.features.length) {
                                        return [fullPath, null] as const;
                                    }

                                    geoJSON.features.forEach(feature => {
                                        // @ts-ignore
                                        features.push(feature);
                                        const type = feature.geometry.type;
                                        if (!shapeCounts[type]) {
                                            shapeCounts[type] = 0;
                                        }
                                        shapeCounts[type]++;
                                    });
                                    // console.log(shapeCounts);
                                    const shpProperties: Record<string, string> = { ...geoJSON.features[0].properties }
                                    // @ts-ignore
                                    const dbfKeys = Object.keys(shpProperties).sort();
                                    const folderLevels = fullPath.split('/');
                                    const types = Object.keys(shapeCounts).sort();
                                    const groupKey = `${folderLevels.length},${dbfKeys.join(',')},${types.join(",")}`;
                                    const file = new File([shpBuffer], fullPath);
                                    const keyedFile: KeyedShapefile = file as KeyedShapefile;
                                    keyedFile.geoJSON = geoJSON;
                                    keyedFile.dbfKeys = dbfKeys;
                                    keyedFile.dbfValues = Object.values(shpProperties);
                                    keyedFile.geoType = 'Unknown';
                                    keyedFile.path = fullPath;
                                    keyedFile.shapeCounts = shapeCounts;

                                    // & {
                                    //     groupKey,
                                    //     dbfKeys,
                                    //     dbfValues: dbfKeys.map(key => shpProperties[key]),
                                    //     geoType: "Unknown",
                                    //     path: fullPath,
                                    //     geoJSON,
                                    //     shapeCounts
                                    // };
                                    // console.log(keyedFile);
                                    return [groupKey, keyedFile] as const;
                                }));

                                // const blob = await groupFeatures(featureCollections);
                                // // download blob
                                // const url = URL.createObjectURL(blob);
                                // const a = document.createElement('a');
                                // a.href = url;
                                // a.download = `all_features_${Date.now()}.zip`;
                                // a.click();

                                const newKeyedShapefiles: Map<string, KeyedShapefile[]> = new Map();

                                for (const [groupKey, keyedShapefile] of keyedShapefiles) {
                                    if (!keyedShapefile) continue;

                                    if (!newKeyedShapefiles.has(groupKey)) {
                                        newKeyedShapefiles.set(groupKey, []);
                                    }
                                    newKeyedShapefiles.get(groupKey)?.push(keyedShapefile);
                                }

                                props.setKeyedShapefileMap(newKeyedShapefiles);

                                props.setLoading(false);
                                toast.dismiss(loadingFiles);
                            }

                            asyncProcessing();
                        };
                        fileInput.onclose = () => {
                            // console.log(fileInput.files);
                        };
                        fileInput.click();
                    } catch (error) {
                        Oops.handleError(error, "SubmitJobs.CSVImportForm.uploadFilesDialog");
                        toast.dismiss(loadingFiles);
                    }
                }}>
                    <Add className="icon-in-button" />Add Shapefiles
                </Button>
                <Button style={{ width: '20%' }} variant='contained' onClick={async () => {
                    props.setKeyedShapefileMap(new Map());
                    props.setOriginalShapefiles([]);
                }}>
                    <NotInterested className="icon-in-button" />Clear
                </Button>
                {/* UploadTypes */}
            </Box>
        </Grid>
        { !!props.originalShapefiles.length && <>
            <Grid item xs={12}>
                <Accordion defaultExpanded={true}>
                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                        <Typography variant='h5'>{`Original Files (${props.originalShapefiles.length} uploaded)`}</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <ListOfFiles files={props.originalShapefiles} truncate={3} onClear={(file: File) => {
                            const newFiles = [...props.originalShapefiles];
                            const index = newFiles.findIndex(f => f.name === file.name);
                            newFiles.splice(index, 1);
                            props.setOriginalShapefiles(newFiles);
                        }} />
                    </AccordionDetails>
                </Accordion>
            </Grid>
            <Grid item xs={12}>
                <Accordion defaultExpanded={true}>
                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                        <Typography variant="h5">File Tagging and Splitting</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        {[...props.keyedShapefileMap.keys()].map((groupKey, index) => {
                            const shapefiles = props.keyedShapefileMap.get(groupKey);
                            if (!shapefiles) return null;

                            const dbfKeys = shapefiles[0].dbfKeys;
                            const folderTokens = shapefiles[0].path.split("/");
                            const folderOptions: string[] = [];
                            const optionTooltips: string[][] = [];

                            for (let folderLevel = 1; folderLevel <= folderTokens.length; folderLevel++) {
                                folderOptions.push("Folder Level " + folderLevel);
                                const tooltip = shapefiles.map((shapefile) => {
                                    const tokens = shapefile.path.split("/");
                                    return tokens[folderLevel - 1];
                                });
                                optionTooltips.push(tooltip);
                            }

                            dbfKeys.forEach((key) => {
                                optionTooltips.push(
                                    [...new Set(shapefiles.map((shapefile) => {
                                        return shapefile.geoJSON.features.map((feature) => {
                                            if (!feature.properties) return "";
                                            return feature.properties[key] as string;
                                        }).flat();
                                    }).flat())].filter((value) => value !== "" && value !== undefined)
                                );
                            });

                            return (
                                <Card elevation={4} style={{ marginBottom: 10, padding: 15 }} key={groupKey}>
                                    <KeyGenerationSelector
                                        index={index + 1}
                                        options={[...folderOptions, ...dbfKeys]}
                                        inputFiles={shapefiles}
                                        setOutputFiles={(files, type) => {
                                            props.setSelectableFiles((prevFiles => {
                                                const newFiles = { ...prevFiles };
                                                if (!newFiles[groupKey]) {
                                                    newFiles[groupKey] = {
                                                        Points: [],
                                                        Boundary: [],
                                                        Zones: [],
                                                        Unknown: []
                                                    };
                                                }
                                                newFiles[groupKey][type] = files;
                                                return newFiles;
                                            }));
                                        }}
                                        optionTooltips={optionTooltips}
                                    />
                                </Card>
                            );
                        })}
                    </AccordionDetails>
                </Accordion>
            </Grid>
        </> }
    </>);
}