import axios from 'axios';
import { REQUEST_CONFIG_AUTH_UPLOAD } from '../common';
import { IAirtableAttachment } from '@rogoag/airtable';

const REGION = "us-east-2"
const BUCKET = 'airtableuploads';
export type FileResponseType = 'arraybuffer' | 'blob' | 'json' | 'text';

export class S3_API {
    constructor() {}

    async doesFileExist(filename: string, recordId: string = "") {
        try {
            const url = this._makeS3Url(filename, recordId);
            const response = await axios.head(url, { headers: { 'Cache-Control': 'no-cache' } });
            return response.status === 200
        } catch (error: any) {
            try {
                // try from root folder
                const url2 = this._makeS3Url(filename);
                const response2 = await axios.head(url2, { headers: { 'Cache-Control': 'no-cache' } });
                return response2.status === 200;
            } catch (e){
                return false;
            }
        }
    }

    async download<T=ArrayBuffer|Blob|string>(filename: string, responseType: FileResponseType, recordId = "", bucket: string = BUCKET) {
        try {
            const url = this._makeS3Url(filename, recordId, bucket);
            const response = await axios.get<T>(url, { responseType });
            if (response.status === 200) return response.data;
        } catch (error) {
            // try from root folder
            const url2 = this._makeS3Url(filename, "", bucket);
            const response2 = await axios.get<T>(url2, { responseType });
            if (response2.status === 200) return response2.data;
        }
    }

    async upload(file: File, folder: string = "", bucket: string = BUCKET) {
        const formData = new FormData();
        formData.append('file', file);

        let queryParams: string[] = [];
        queryParams.push(`bucket=${bucket}`);
        if (folder) queryParams.push(`folder=${encodeURIComponent(folder)}`);
        let queryString = queryParams.length ? `?${queryParams.join('&')}` : "";

        const url = `${import.meta.env.VITE_ROGO_API_URL}/aws/s3/upload${queryString}`;

        const response = await axios.post(url, formData, REQUEST_CONFIG_AUTH_UPLOAD());
        if (response.status !== 200) {
            throw new Error(`Failed to upload file: ${file.name}`);
        } else {
            return response.data;
        }
    }

    /**
     * Note that these will all upload to the same folder & bucket
     */
    async uploadFiles(fileList: File[], folder: string = "", bucket: string = BUCKET) {
        if (!fileList) return [];
        const fileArray = Array.from(fileList);
        const uploadResults = await Promise.all(fileArray.map(async (file) => {
            const response = await this.upload(file, folder, bucket);
            return { url: response.url, filename: file.name, } as IAirtableAttachment;
        }))
    
        return uploadResults;
    }

    private _makeS3Url(filename: string, folder = "", bucket: string = BUCKET) {
        folder = folder == "" ? "" : (folder.endsWith('/') ? folder : `${folder}/`);
        filename = this._isEncoded(filename) ? filename : encodeURIComponent(filename);
        return `https://${bucket}.s3.${REGION}.amazonaws.com/${folder}${filename}`;
    }
    
    private _isEncoded(uri: string) {
        uri = uri || '';
      
        return uri !== decodeURIComponent(uri);
    }
}
