import { Box, Autocomplete, TextField, Typography, Checkbox, Tooltip, Button } from "@mui/material";
import { GeoFileTypes } from "../../../utilities/types";
import { useState } from "react";
import { CloseOutlined, CloseRounded } from "@mui/icons-material";
import PortalTooltip from "../../helpers/PortalTooltip";

interface KeyGenerationFileFilterProps {
    options: string[];
    optionTooltips: string[][];
    updateFilter: (filterType: FilterType | null, filterKey: string[], matchType: MatchType | null, value: string | null) => void;
    removeSelf: () => void;
}

export const FilterTypes = ["Boundary", "Points", "Zones", "Exclude File"] as const;
export type FilterType = typeof FilterTypes[number];

export const ConditionTypes = ["If", "Always"] as const;
export type ConditionType = typeof ConditionTypes[number];

export const MatchTypes = ["Contains", "Doesn't Contain", "Exists"] as const;
export type MatchType = typeof MatchTypes[number];

export function KeyGenerationFileFilter(props: KeyGenerationFileFilterProps) {
    const [selectedFilterType, setSelectedFilterType] = useState<FilterType | null>(null);
    const [filterKey, setFilterKey] = useState<string[]>([]);
    const [matchType, setMatchType] = useState<MatchType | null>(null);
    const [matchValue, setMatchValue] = useState<string | null>('');
    //const [selectedFilter]

    const generateTooltips = (option: string) => {
        const tooltips = props.optionTooltips[props.options.indexOf(option)].slice(0, 3).map(tooltipString => {
            return <div>{tooltipString.toString()}<br /></div>;
        });

        if (props.optionTooltips[props.options.indexOf(option)].length > 3) {
            tooltips.push(<div>{props.optionTooltips[props.options.indexOf(option)].length - 3} more...</div>);
        }
        return tooltips;
    };


    return (<Box width={'100%'} display={'flex'} marginTop={2}>
        <PortalTooltip placement="top-start" title={"Select the type of filter you want to use. This can be to identify your geo data as boundarys, zones, or points, or to exclude certain files that aren't needed"}>
            <Autocomplete
                style={{ width: '200px', marginRight: 10 }}
                onChange={(event, value) => {
                    setSelectedFilterType(value);
                    props.updateFilter(value, filterKey, matchType, matchValue);
                }}
                renderInput={(params) => <TextField {...params} label="Select Filter Type" variant="outlined" />}
                options={FilterTypes} />
        </PortalTooltip>
        <Typography style={{ marginTop: 15, marginRight: 10 }}>if</Typography>
        <PortalTooltip placement="top-start" title={"Select the key(s) you want to filter on. This can be a file name, a column name, column value, or some combination of these"}>
            <Autocomplete
                disabled={!selectedFilterType}
                style={{ width: '300px', marginRight: 10 }}
                multiple
                // value={selectedKeys}
                options={["shape types", ...props.options]}
                disableCloseOnSelect
                onChange={async (event, value) => {
                    setFilterKey(value);
                    props.updateFilter(selectedFilterType, value, matchType, matchValue);
                }}
                renderOption={(_props, option, { selected }) => (
                    <li {..._props}>
                        <Checkbox checked={selected} />
                        <PortalTooltip placement='right-start' title={generateTooltips(option)}>
                            <Typography>{option}</Typography>
                        </PortalTooltip>
                    </li>
                )}
                renderInput={(params) => <TextField {...params} label="Select Filter Keys" variant="outlined" />}
            />
        </PortalTooltip>
        <PortalTooltip placement="top-start" title={"Select the way that you want to match against your selected keys. \n-Contains will look for that string\n-Doesn't contain will make sure the search string isn't in your key\n-Exists will return true if that value exists at all (this is especially useful for geo property keys)"}>
            <Autocomplete
                disabled={!filterKey.length}
                style={{ width: '200px', marginRight: 10 }}
                renderInput={(params) => <TextField {...params} label="Select Match Type" variant="outlined" />}
                renderOption={(_props, option, { selected }) => (
                    <li {..._props}>
                        <Checkbox checked={selected} />
                        <Typography>{option}</Typography>
                    </li>
                )}
                options={MatchTypes}
                onChange={(event, value) => {
                    setMatchType(value);
                    props.updateFilter(selectedFilterType, filterKey, value, matchValue);
                    // TOOD re-sort files
                }}
            />
        </PortalTooltip>
        {/* <Typography style={{ marginTop: 15, marginRight: 10 }}>has</Typography> */}
        <TextField disabled={!matchType} style={{ width: '200px' }} label="Value" variant="outlined" onChange={(event) => {
            setMatchValue(event.target.value);
            props.updateFilter(selectedFilterType, filterKey, matchType, event.target.value);
        }}
        />
        {/* Add icon (x) to remove this filter */}
        <Button style={{ marginTop: 5 }} onClick={props.removeSelf}>
            <CloseRounded onClick={props.removeSelf} />
        </Button>

    </Box>);
}