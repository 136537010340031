import { Typography } from "@mui/material";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

export const RegistrationContactNotice = () => {
    return (
      <div style={{ textAlign: 'center', marginTop: '20px' }}>
        <Typography variant="h4" gutterBottom>
          Registration Submitted!
        </Typography>
        <Typography variant="body1">
          Our team will be in touch with you shortly to complete your registration.
        </Typography>
        <Typography variant="body2" color="textSecondary" style={{ marginTop: '10px' }}>
          Thank you for your patience.
        </Typography>
      </div>
    );
  };