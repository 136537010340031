import { DealsFieldIdMapping } from "@rogoag/airtable/rogo.at";
import { Deals, } from '@rogoag/airtable';
import { Company, Deal, Contact } from '../../../types/data_models';
import { Filter, FilterFieldIds, FilterField } from '../../../common';
import { API } from "../../..";
import { DataAPI_Base, GetAPI_Base } from "../base";

class GetAPI_Deals extends GetAPI_Base<Deals, Deal> {
    constructor(fieldIds: FilterFieldIds | undefined = undefined) { super("deals", DealsFieldIdMapping, fieldIds); }

    public async activeAndPrimary(dealIds: string[]) {
        const filter = {
            type: 'OR',
            fields: dealIds.map((id) => {
                return {
                    type: 'AND',
                    fields: [
                        { id: id } as FilterField,
                        { active: true } as FilterField,
                        { configOnly: false } as FilterField
                    ]
                } as Filter;
            })
        } as Filter;
    
        return super.byFilter(filter);
    }

    /**
     * @param [loadAll=false]  Otherwise retrieves only active and primary deals
     */
    public async byCompany(company: Company, loadAll: boolean = false) {
        if (!company.dealIds || company.dealIds.length === 0) return [];
        const deals = loadAll 
            ? await super.byIds(company.dealIds) 
            : await this.activeAndPrimary(company.dealIds);
        return deals;
    }

    public async byContact(contact: Contact) {
        const companies = await API.Data.Companies.get.byContact(contact);
        const company = companies[0];
        return this.byCompany(company);
    }
}

export class DealsAPI extends DataAPI_Base<Deals, typeof DealsFieldIdMapping, Deal> {
    get: GetAPI_Deals;

    constructor() { 
        const filterFieldIds = { 
            configOnly: DealsFieldIdMapping['SAMPLING CONFIGURATION ONLY'],
            active: DealsFieldIdMapping['Active'],
        } as FilterFieldIds;
        super("deals", DealsFieldIdMapping, filterFieldIds);
        this.get = new GetAPI_Deals(filterFieldIds);
    }
}