import axios from 'axios';
import { FieldSet } from 'airtable';
import { REQUEST_CONFIG_AUTH, getBaseDataURL, parseDates } from '../../../common';
import { BaseDataModel } from '../../../types/data_models';

export class CreateAPI_Base<T extends FieldSet, U extends BaseDataModel<T>> {
    private router: string;
    protected baseUrl: string;

    constructor(router: string){
        this.router = router;
        this.baseUrl = getBaseDataURL(router);
    }

    async many(records: Partial<U>[]): Promise<U[]> {
        let url = this.baseUrl;

        const response = await axios.post(url, records, REQUEST_CONFIG_AUTH());
        if (response.status != 200) {
            throw new Error(`Create failed for ${this.router}: ${response.statusText}`);
        }
        var createdRecords = response.data as U[];
        parseDates<T, U>(createdRecords);
        return createdRecords;
    }

    async one(data: Partial<U>): Promise<U> {
        return (await this.many([data]))[0];
    }
}
