import { ContactsFieldIdMapping } from "@rogoag/airtable/rogo.at";
import { Contacts, } from '@rogoag/airtable';
import { Contact } from '../../../types/data_models';
import { FilterFieldIds, USER_NOT_FOUND, USER_NOT_LINKED } from '../../../common';
import { DataAPI_Base, GetAPI_Base } from "../base";

class GetAPI_Contacts extends GetAPI_Base<Contacts, Contact> {
    constructor(fieldIds: FilterFieldIds | undefined = undefined) { super("contacts", ContactsFieldIdMapping, fieldIds); }

    override async byId(id: string) {
        const contact = await super.byId(id);

        if (!contact.id) {
            throw new Error(USER_NOT_FOUND);
        } else if (contact.companyIds_All.length === 0) {
            throw new Error(USER_NOT_LINKED);
        }

        return contact;
    }
}

export class ContactsAPI extends DataAPI_Base<Contacts, typeof ContactsFieldIdMapping, Contact> {
    get: GetAPI_Contacts;
    
    constructor() { 
        super("contacts", ContactsFieldIdMapping); 
        this.get = new GetAPI_Contacts();
    }
}