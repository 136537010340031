import axios from 'axios';
import { FieldSet } from 'airtable';
import { REQUEST_CONFIG_AUTH, FieldIdMapping, getBaseDataURL, parseDates } from '../../../common';
import { BaseDataModel } from '../../../types/data_models';

interface UpdateRecordDict {
    id: string;
    fields: { [key: string]: any };
}

export class UpdateAPI_Base<T extends FieldSet, U extends FieldIdMapping, V extends BaseDataModel<T>> {
    private router: string;
    protected baseUrl: string;
    private fieldMapping: any;

    constructor(router: string, fieldMapping: any) {
        this.router = router;
        this.baseUrl = getBaseDataURL(router);
        this.fieldMapping = fieldMapping;
    }

    /**
     * @param records - Example: [
     *  {"id": "recwPQIfs4wKPyc9D", "fields": {"Name": "Tony Stark"}}, 
     *  {"id": "recwPQIfs4wKPyc9D", "fields": {"Name": "Tony Stark"}}
     * ]
     */
    public async many(records: Partial<T>[]) {
        const url = this.baseUrl;

        const updates: UpdateRecordDict[] = this.convertToUpdates(records);
        const response = await axios.put(url, updates, REQUEST_CONFIG_AUTH());
        if (response.status !== 200) {
            throw new Error(`Update failed for ${this.router}: ${response.statusText}`);
        }
        var updatedRecords = response.data as V[];
        parseDates<T, V>(updatedRecords);
        return updatedRecords;
    }

    /**
     * @param record - Example: {"Name": "Tony Stark"}
     */
    public async one(id: string, records: Partial<T>) {
        const url = `${this.baseUrl}/${id}`;

        const updates: { [key: string]: any } = this.convertToUpdate(records);
        const response = await axios.put(url, updates, REQUEST_CONFIG_AUTH());
        if (response.status !== 200) {
            throw new Error(`Update failed for ${this.router}: ${response.statusText}`);
        }
        var updatedRecord = response.data as V;
        parseDates<T, V>([updatedRecord]);
        return updatedRecord;
    }

    public async field(id: string, field: keyof U, value: any) {
        const url = `${this.baseUrl}/${id}/${field as string}/${value}`;

        const response = await axios.put(url, REQUEST_CONFIG_AUTH());
        if (response.status !== 200) {
            throw new Error(`Update failed for ${this.router}: ${response.statusText}`);
        }
    }

    private convertToUpdates(records: Partial<T>[]): UpdateRecordDict[] {
        var updates: UpdateRecordDict[] = [];
        for (let i = 0; i < records.length; i++) {
            var fields: { [key: string]: any; } = {};

            for (const field in this.fieldMapping) {
                if (records[i][field] !== undefined) {
                    fields[field] = records[i][field];
                }
            }

            var update: UpdateRecordDict = {
                id: records[i].id as string,
                fields: fields
            };
            updates.push(update);
        }
        return updates;
    }

    private convertToUpdate(record: Partial<T>): { [key: string]: any } {
        return this.convertToUpdates([record])[0].fields;
    }
}
