import * as Sentry from "@sentry/browser";
import axios from "axios";
import { DEBUG } from "./constants";
import { toast } from "react-toastify";
import { USER_NOT_FOUND, USER_NOT_LINKED } from "../api/common";

export namespace Oops {
    export function handleError(error: any, source: string = "", email: string = "", shouldCreateUniqueIssue: boolean = false) {
        if (DEBUG) console.error("OOPS", error);

        if (email) {
            Sentry.setUser({ email });
        }
        
        var message = getErrorMessage(error);
        switch (message) {
            case "User does not exist":
                toast.error("User does not yet exist in our database. Contact support@rogoag.com"); 
                Sentry.captureMessage(message);
                break;
            case "User is not registered":
                toast.error("User is not registered. Please register the user or contact software@rogoag.com");
                Sentry.captureMessage(message);
                break;
            case "User is already registered":
            case "User registration has already been submitted":
            case "Error updating password":
            case "User is undefined":
                Sentry.captureMessage(`${message}: ${email}`);
                break;

            // API errors
            case "Incorrect username or password":
                toast.error(message);
                Sentry.captureMessage(`Incorrect username or password for user ${email}`);
                break;
            case "Not authenticated":
            case "Could not validate credentials":
                toast.error("Authentication error. Please login.");
                Sentry.captureMessage(`Authentication error for user ${email}`);
                break;
            case USER_NOT_FOUND:
            case USER_NOT_LINKED:
                toast.error(message);
                logErrorToSentry(error);
                break;
            default:
                toast.error('An error occurred. Contact software@rogoag.com');
                logErrorToSentry(error, source, shouldCreateUniqueIssue);
                break;
        }
    }

    export function logErrorToSentry(error: Error | any, errorSource: string = "", shouldCreateUniqueIssue: boolean = false) {
        // var e = castErrorToSpecificType(error, errorSource);
    
        if (shouldCreateUniqueIssue) {
            Sentry.withScope(function (scope) {
                scope.setFingerprint(["custom-" + Date.now().toString()]); // forces Sentry to log each event as a separate issue, rather then grouping them together
                if (errorSource) scope.setTransactionName(errorSource);
                scope.captureException(error, { mechanism: { handled: true } });
            });
        } else {
            if (errorSource) Sentry.getCurrentScope().setTransactionName(errorSource);
            Sentry.captureException(error, { mechanism: { handled: true } });
        }
    }

    export function getErrorMessage(error: any) {
        var message = "";
        if (axios.isAxiosError(error) && error.response) {
            message = error.response.data.detail;
            if (DEBUG) console.error("Axios Message: ", message);
        } else {
            message = error.message;
        }
        return message;
    }

    export function getErrorStack(error: Error) {
        var stack = "";
        if (axios.isAxiosError(error) && error.response) {
            stack = error.stack ?? "";
        } else {
            stack = error.stack ?? "";
        }
        return stack;
    }
}