import { CompaniesFieldIdMapping } from "@rogoag/airtable/rogo.at";
import { Companies, } from '@rogoag/airtable';
import { Company, Contact } from '../../../types/data_models';
import { FilterFieldIds } from '../../../common';
import { DataAPI_Base, GetAPI_Base } from "../base";

class GetAPI_Companies extends GetAPI_Base<Companies, Company> {
    constructor(fieldIds: FilterFieldIds | undefined = undefined) { super("companies", CompaniesFieldIdMapping, fieldIds); }

    public async byContact(contact: Contact) {
        if (contact.companyIds_All.length === 0) throw new Error(`User must be linked to at least one company`);
    
        const allCompanies = await super.byIds(contact.companyIds_All);
        if (allCompanies[0].id && allCompanies[0].name.toLowerCase() === 'rogo ag') {
            var activeCompanies = await super.byViewId("viwW2z0QNYIQJtkN3"); // "Active Companies - Deals OR Customer Status Current"
            allCompanies.push(...activeCompanies);
        }

        const uniqueCompanies = allCompanies.filter((company, index, self) =>
            index === self.findIndex((t) => (
                t.id === company.id
            ))
        );
    
        return uniqueCompanies;
    }
}

export class CompaniesAPI extends DataAPI_Base<Companies, typeof CompaniesFieldIdMapping, Company> {
    get: GetAPI_Companies;
    constructor() { 
        super("companies", CompaniesFieldIdMapping); 
        this.get = new GetAPI_Companies();
    }
}