import { BranchesFieldIdMapping } from "@rogoag/airtable/rogo.at";
import { Branches, } from '@rogoag/airtable';
import { Branch, Company } from '../../../types/data_models';
import { FilterFieldIds } from '../../../common';
import { DataAPI_Base, GetAPI_Base } from "../base";

class GetAPI_Branches extends GetAPI_Base<Branches, Branch> {
    constructor(fieldIds: FilterFieldIds | undefined = undefined) { super("branches", BranchesFieldIdMapping, fieldIds); }

    public async byCompany(company: Company) {
        if (!company.branchIds || company.branchIds.length === 0) return [];
        const branches = await super.byIds(company.branchIds);
        return branches;
    }
}

export class BranchesAPI extends DataAPI_Base<Branches, typeof BranchesFieldIdMapping, Branch> {
    get: GetAPI_Branches;
    constructor() { 
        super("branches", BranchesFieldIdMapping); 
        this.get = new GetAPI_Branches();
    }
}