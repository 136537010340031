import { useEffect, useState } from "react";
import { Feature, FeatureCollection, MultiPolygon, Point, Polygon } from "geojson";
import { Map, MapCameraChangedEvent, useMap, useMapsLibrary } from '@vis.gl/react-google-maps';
import { Polygon as MapPolygon } from './map';
import { MarkerWithInfoWindow } from "./map/MarkerWithInfoWindow";
import { Typography } from "@mui/material";

interface SimpleMapProps {
    zoom?: number,
    boundaries?: Record<string, FeatureCollection<Polygon> | FeatureCollection<MultiPolygon>>,
    zones?: Record<string, FeatureCollection<Polygon> | FeatureCollection<MultiPolygon>>,
    points?: Record<string, FeatureCollection<Point>>
    height?: string,
    showPointsAtZoom?: number,
}

export default function GoogleMapComponent(props: SimpleMapProps) {
    const [mapPoints, setMapPoints] = useState<JSX.Element[]>([]);
    const [mapPolygons, setMapPolygons] = useState<JSX.Element[]>([]);
    const [visibleMapMarkers, setVisibleMapMarkers] = useState<JSX.Element[]>([]);
    const [visibleMapPolygons, setVisibleMapPolygons] = useState<JSX.Element[]>([]);
    const [center, setCenter] = useState<google.maps.LatLngLiteral>({ lat: 40.7671815905857, lng: -87.16533774444255 });
    const [zoom, setZoom] = useState<number>(12);
    const [bounds, setBounds] = useState<google.maps.LatLngBoundsLiteral | undefined>(undefined);
    const [kmlLayer, setKmlLayer] = useState<google.maps.KmlLayer | null>(null);

    const mapsLibrary = useMapsLibrary('maps');
    if (!mapsLibrary) {
        return <div>Loading...</div>;
    }

    const map = useMap();

    useEffect(() => {
        const getShapeFeatureProperty = (feature: Feature<Polygon | Point>, key: string) => {
            return feature.properties && key in feature.properties ? feature.properties[key] : "";
        }
        const bounds = new google.maps.LatLngBounds();
        const boundaries = Object.entries(props.boundaries ?? {});
        const zones = Object.entries(props.zones ?? {});
        const polygons = boundaries.concat(zones);
        const mapPolygons = polygons.map(([key, value]) => {
            return value.features?.map((feature: Feature<Polygon | MultiPolygon | Point>, index) => {
                if (feature.geometry.type !== 'Polygon' && feature.geometry.type !== 'MultiPolygon') {
                    return null;
                }
                if (feature.geometry.bbox) {
                    bounds.extend(new google.maps.LatLng(feature.geometry.bbox[1], feature.geometry.bbox[0]));
                    bounds.extend(new google.maps.LatLng(feature.geometry.bbox[3], feature.geometry.bbox[2]));
                }

                const coordinates = feature.geometry.type === 'Polygon' ? [feature.geometry.coordinates] : feature.geometry.coordinates;
                const paths = coordinates.map(coordinate => {
                    return coordinate.map(c => {
                        return c.map(cc => {
                            return { lat: cc[1], lng: cc[0] };
                        }).flat();
                    }).flat();
                });

                function addPolygonToMap(path: any) {
                    return <MapPolygon
                        fillOpacity={0.4}
                        fillColor={randomColor()}
                        strokeWeight={2}
                        key={`${key}-${index}`}
                        paths={path}
                        onClick={() => { }}
                    />
                }
                
                let HTML: JSX.Element[] = [];
                for (const path of paths) {
                    HTML.push(addPolygonToMap(path));
                }
                return HTML;
            });
        }).flat();

        const points = Object.entries(props.points ?? {});
        const mapPoints = points.map(([key, value]) => {
            return value.features?.map((feature: Feature<Polygon | Point>) => {
                if (feature.geometry.type !== 'Point') {
                    return null;
                }
                const sampleId = getShapeFeatureProperty(feature, 'spl id');
                const barcode = getShapeFeatureProperty(feature, 'spl brcd');
                const change = getShapeFeatureProperty(feature, 'change');
                const [changeType, changeReason] = change.includes(':') ? change.split(':') : ['', ''];
                const hasChange = changeType && changeType !== 'None';
                bounds.extend(new google.maps.LatLng(feature.geometry.coordinates[1], feature.geometry.coordinates[0]));
                return <MarkerWithInfoWindow
                    key={`${key}-${feature.geometry.coordinates[1].toString().replace(".", "")}-${feature.geometry.coordinates[0].toString().replace(".", "")}`}
                    iconColor={hasChange ? 'warning' : undefined}
                    position={{ lat: feature.geometry.coordinates[1], lng: feature.geometry.coordinates[0] }}
                    infoWindowText={
                        <Typography variant="body2">
                            Sample ID: {sampleId}<br />
                            Barcode: <b>{barcode}</b><br />
                            Moved? {hasChange ? `Yes (${changeType})` : 'No'}{changeReason ? `: ${changeReason}` : ''}
                        </Typography>
                    }
                    label={sampleId || ""}>
                </MarkerWithInfoWindow>;
            });
        }).flat();

        const validPoints = mapPoints.filter(Boolean) as JSX.Element[];
        const validPolygons = mapPolygons.flat().filter(Boolean) as JSX.Element[];

        setMapPoints(validPoints);
        setMapPolygons(validPolygons);

        const b = Object.values(props.boundaries || {});
        if (map && b.length) {
            map.fitBounds(bounds);
        }
    }, [props.boundaries, props.points]);

    // update visible based on bounds
    useEffect(() => {
        if (!map) return;

        const bounds = map.getBounds();
        if (!bounds) return;

        const zoom = map.getZoom();

        // // check all mapPoints to see if they are in bounds
        const visibleMapMarkers = mapPoints.filter((marker) => {
            const position = marker.props.position;
            if (!position) {
                return false;
            }

            if (!zoom || !props.showPointsAtZoom || zoom < props.showPointsAtZoom) {
                return false;
            }
            
            return bounds.contains(new google.maps.LatLng(position.lat, position.lng));
        });

        setVisibleMapMarkers(visibleMapMarkers);
        setVisibleMapPolygons(mapPolygons);
    }, [zoom, bounds]);

    return (
        <Map
            style={{ width: '100%', height: props.height ? props.height : '97vh' }}
            mapId={"customerMaps"}
            zoom={zoom}
            onBoundsChanged={(e: MapCameraChangedEvent) => { setBounds(e.detail.bounds) }}
            onZoomChanged={(e: MapCameraChangedEvent) => { setZoom(e.detail.zoom); }}
            defaultCenter={center}
            defaultZoom={10} >
            {visibleMapMarkers}
            {visibleMapPolygons}
        </Map>
    );
}

function randomColor() {
    var colors = [
        "#e51c23",
        "#e91e63",
        "#9c27b0",
        "#673ab7",
        "#3f51b5",
        "#5677fc",
        "#03a9f4",
        "#00bcd4",
        "#009688",
        "#259b24",
        "#8bc34a",
        "#afb42b",
        "#ff9800",
        "#ff5722",
        "#795548",
        "#607d8b",
        "#ffeb3b",
        "#cddc39",
        "#4caf50",
        "#8bc34a",
        "#cddc39",
        "#ffeb3b",
        "#ffc107",
        "#ff9800",
        "#ff5722",
        "#795548",
        "#9e9e9e",
        "#607d8b",
        "#3f51b5",
        "#2196f3",
        "#03a9f4",
        "#00bcd4",
        "#009688",
        "#4caf50",
        "#8bc34a",
        "#cddc39",
        "#ffeb3b",
        "#ffc107",
        "#ff9800",
        "#ff5722",
        "#795548",
        "#9e9e9e",
        "#607d8b"
    ]
    
    const index = Math.floor(Math.random() * colors.length);
    return colors[index];
}