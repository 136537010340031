import { JobsFieldIdMapping } from "@rogoag/airtable/rogo.at";
import { Jobs, } from '@rogoag/airtable';
import { Company, Job } from '../../../types/data_models';
import { Filter, FilterFieldIds, FilterField, getSeasonCode, getPreviousSeasonCode } from '../../../common';
import { CreateAPI_Base, DataAPI_Base, GetAPI_Base } from "../base";
import { API } from "../../..";

class CreateAPI_Jobs extends CreateAPI_Base<Jobs, Job> {
    constructor() { super("jobs"); }

    public async many(jobs: Partial<Job>[], source?: "Created Portal Single" | "Created Portal Bulk" | "Created Portal API Proagrica"): Promise<Job[]> {
        const user = await API.Authentication.getCurrentUser();
        const contact = await API.Data.Contacts.get.byId(user.id);

        const jobsToCreate = await Promise.all(jobs.map(async job => {
            // This is a hack to ensure that we can match the job after creation,
            // in order to upload the files into the appropriate S3 bucket
            job.rogoAppVersion = `temp_${crypto.randomUUID()}`;

            job.jobFlags = job.jobFlags ? [...job.jobFlags] : [];
            if (source) job.jobFlags.push(source);
            if (contact.isRogoEmployee) job.jobFlags.push("ROGO Created");

            return job;
        }));

        const createdJobs = await super.many(jobsToCreate);

        // The files are uploaded after job creation because we need the job id in order to upload the files into the appropriate "id" folder in S3
        // The job is then updated with the attachment URLs
        const jobsToUpdate = (await Promise.all(jobs.map(async job => {
            const matchingJob = createdJobs.find(id => id.rogoAppVersion === job.rogoAppVersion);

            if (matchingJob){
                const boundary = job.files?.boundarySHP ? await API.AWS.S3.uploadFiles(job.files.boundarySHP, matchingJob.id) : [];
                const points = job.files?.pointsSHP ? await API.AWS.S3.uploadFiles(job.files.pointsSHP, matchingJob.id) : [];
                const zones = job.files?.zonesSHP ? await API.AWS.S3.uploadFiles(job.files.zonesSHP, matchingJob.id) : [];
                const fieldNotes = job.files?.fieldNotes ? await API.AWS.S3.uploadFiles(job.files.fieldNotes, matchingJob.id) : [];
                
                matchingJob.rogoAppVersion = undefined;
                matchingJob.attachments = {
                    boundarySHP: boundary,
                    pointsSHP: points,
                    zonesSHP: zones,
                    fieldNotes: fieldNotes,
                }
    
                const job_AT: Partial<Jobs> = {
                    id: matchingJob.id,
                    "Rogo App Version": "",
                    "Bnd Shp": boundary,
                    "Pts Shp": points,
                    "Sample Zones Shp": zones,
                    "Field Notes": fieldNotes,
                }
    
                return job_AT;
            } else {
                throw new Error(`Matching job not found for ${job.rogoAppVersion}. Files were not uploaded.`);
            }
        }))).filter(job => !!job);

        const updatedJobs = await API.Data.Jobs.update.many(jobsToUpdate);
        return updatedJobs;
    }

    async one(job: Partial<Job>, source?: "Created Portal Single" | "Created Portal Bulk" | "Created Portal API Proagrica"): Promise<Job> {
        return (await this.many([job], source))[0];
    }
}

class GetAPI_Jobs extends GetAPI_Base<Jobs, Job> {
    constructor(fieldIds: FilterFieldIds | undefined = undefined) { super("jobs", JobsFieldIdMapping, fieldIds); }

    /**
     * @param [loadAll=false]  Otherwise retrieves jobs from the last 2 seasons
     */
    public async byCompany(company: Company, loadAll: boolean = false) {
        if (!company.name) return [];

        let filter = {} as Filter;
        if (loadAll) {
            filter = { fields: [{ companyName: company.name } as FilterField] } as Filter;
        } else {
            const today = new Date();
            const currentSeason = getSeasonCode(today);
            const previousSeason = getPreviousSeasonCode(today);
    
            filter = { 
                type: "AND",
                fields: [
                    { companyName: company.name } as FilterField,
                    { 
                        type: "OR", 
                        fields: [
                            { season: currentSeason } as FilterField,
                            { season: previousSeason } as FilterField
                        ] 
                    } as Filter
                ] 
            } as Filter;
        }
        
        const jobs = await super.byFilter(filter);
        return jobs;
    }
}

export class JobsAPI extends DataAPI_Base<Jobs, typeof JobsFieldIdMapping, Job> {
    get: GetAPI_Jobs;
    create: CreateAPI_Jobs;
    
    // job data is compressed, as it can get quite large
    constructor() { 
        const filterFieldIds = { 
            companyName: JobsFieldIdMapping['Client'],
            season: JobsFieldIdMapping['Season'],
        } as FilterFieldIds;
        super("jobs", JobsFieldIdMapping, filterFieldIds, true); 
        this.get = new GetAPI_Jobs(filterFieldIds);
        this.create = new CreateAPI_Jobs();
    }
}