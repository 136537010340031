import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { CircularProgress, Typography, Grid, Box } from '@mui/material';
import GoogleMapComponent from './jobsdashboard/GoogleMapComponent';
import { FeatureCollection, MultiPolygon, Point, Polygon } from 'geojson';
import { API, Job } from '../api';
import { Oops } from '../utilities/oops';
import { GeoJSON } from '../utilities/utils';

const JobDetails = () => {
    const { record_id } = useParams<string>();
    const [job, setJob] = useState<Job>();
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState<string>('');
    const [boundaries, setBoundaries] = useState<Record<string, FeatureCollection<Polygon> | FeatureCollection<MultiPolygon>>>();
    const [zones, setZones] = useState<Record<string, FeatureCollection<Polygon> | FeatureCollection<MultiPolygon>>>();
    const [points, setPoints] = useState<Record<string, FeatureCollection<Point>>>();

    useEffect(() => {
        // TODO: update fetch job for any job
        const fetchJob = async () => {
            try {
                const job = await API.Data.Jobs.get.byId(record_id || '');
                console.log("job", job);
                (!job.id) ? setJob(undefined) : setJob(job);

                if (job) {
                    const displayedBoundaries: Record<string, FeatureCollection<Polygon> | FeatureCollection<MultiPolygon>> = {};
                    const displayedPoints: Record<string, FeatureCollection<Point>> = {};
                    const displayedZones: Record<string, FeatureCollection<Polygon> | FeatureCollection<MultiPolygon>> = {};

                    const [boundary, points, zones] = await Promise.all([
                        GeoJSON.getBoundary(job),
                        GeoJSON.getPoints(job),
                        GeoJSON.getZones(job)
                    ]);
                    
                    if (boundary) { displayedBoundaries[job.id] = boundary; }
                    if (points) { displayedPoints[job.id] = points; }
                    if (zones) { displayedZones[job.id] = zones; }

                    setBoundaries(displayedBoundaries);
                    setPoints(displayedPoints);
                    setZones(displayedZones);
                }
            } catch (error) {
                Oops.handleError(error, "JobDetails.fetchJob");
            } finally {
                setLoading(false);
            }
        };

        fetchJob();
    }, [record_id]);

    if (loading) {
        return <CircularProgress />;
    }

    if (error || !job) {
        return <div>{error ? error :  "other error"}</div>;
    }

    return (
        <Box sx={{ flexGrow: 1, padding: 2 }}>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Typography variant="h6">Record ID: {record_id}</Typography>
                </Grid>
                <Grid item xs={12} md={6}>

                    <Typography variant="subtitle1" sx={{ marginBottom: 1 }}>
                        Company: <Typography component="span" variant="body1">{job.companyName}</Typography>
                    </Typography>

                    <Typography variant="subtitle1" sx={{ marginBottom: 1 }}>
                        Deal ID: <Typography component="span" variant="body1">{job.dealIds.at(0)}</Typography>
                    </Typography>

                    <Typography variant="subtitle1" sx={{ marginBottom: 1 }}>
                        Creation Date: <Typography component="span" variant="body1">{job.dateCreated.toISOString()}</Typography>
                    </Typography>
                    <Typography variant="subtitle1" sx={{ marginBottom: 1 }}>
                        Job Name: <Typography component="span" variant="body2">{job.name}</Typography>
                    </Typography>
                    <Typography variant="subtitle1" sx={{ marginBottom: 1 }}>
                        Farm: <Typography component="span" variant="body2">{job.farmName}</Typography>
                    </Typography>
                    <Typography variant="subtitle1" sx={{ marginBottom: 1 }}>
                        Field: <Typography component="span" variant="body2">{job.fieldName}</Typography>
                    </Typography>
                    <Typography variant="subtitle1" sx={{ marginBottom: 1 }}>
                        Grower: <Typography component="span" variant="body2">{job.growerName}</Typography>
                    </Typography>
                    <Typography variant="subtitle1" sx={{ marginBottom: 1 }}>
                        Acres: <Typography component="span" variant="body2">{job.boundaryAcres}</Typography>
                    </Typography>
                    <Typography variant="subtitle1" sx={{ marginBottom: 1 }}>
                        Edits: <Typography component="span" variant="body2">{job.edits}</Typography>
                    </Typography>
                    <Typography variant="subtitle1" sx={{ marginBottom: 1 }}>
                        File Last Edited: <Typography component="span" variant="body2">{job.dateFileLastEdited?.toISOString() ?? ""}</Typography>
                    </Typography>
                    <Typography variant="subtitle1" sx={{ marginBottom: 1 }}>
                        Submitter: <Typography component="span" variant="body2">{job.submitter}</Typography>
                    </Typography>
                    <Typography variant="subtitle1" sx={{ marginBottom: 1 }}>
                        Approved Billing: <Typography component="span" variant="body2">{job.isBillingApproved ? 'Yes' : 'No'}</Typography>
                    </Typography>
                    <Typography variant="subtitle1" sx={{ marginBottom: 1 }}>
                        Job Status: <Typography component="span" variant="body2">{job.status}</Typography>
                    </Typography>
                    <Typography variant="subtitle1" sx={{ marginBottom: 1 }}>
                        Date Sample: <Typography component="span" variant="body2">{job.dateSampled?.toISOString() ?? ""}</Typography>
                    </Typography>
                    <Typography variant="subtitle1" sx={{ marginBottom: 1 }}>
                        Drop/Ship Date: <Typography component="span" variant="body2">{job.dateShipped?.toISOString() ?? ""}</Typography>
                    </Typography>
                    <Typography variant="subtitle1" sx={{ marginBottom: 1 }}>
                        Test Package: <Typography component="span" variant="body2">{job.testPackage}</Typography>
                    </Typography>
                    <Typography variant="subtitle1" sx={{ marginBottom: 1 }}>
                        Lab Sent Results: <Typography component="span" variant="body2">{job.dateLabResultsSent?.toISOString() ?? ""}</Typography>
                    </Typography>
                </Grid>
                <Grid item xs={10} md={6}>
                    <GoogleMapComponent boundaries={boundaries} points={points} zones={zones} height={"60vh"}/>
                </Grid>
            </Grid>
        </Box>
    );
};

export default JobDetails;