import { Dialog, DialogTitle } from "@mui/material";
import { MarkJobReadyForm, submitJobReadyForm } from "../markjobsready/MarkJobReadyForm";
import { Props as MarkJobReadyFormProps } from "../markjobsready/MarkJobReadyForm";
import { toast } from "react-toastify";
import { Job } from "../../api";
import { Oops } from "../../utilities/oops";

interface Props extends Omit<MarkJobReadyFormProps, 'onSubmit' | 'onCancel'> {
    open: boolean;
    setOpen: (open: boolean) => void;
    setLoading: (loading: boolean) => void;
    onSuccess(jobs: Job[]): Promise<void>;
}

export function MarkJobReadyPopover(props: Props) {
    return (
        <Dialog
            fullWidth
            open={props.open}
            onClose={() => props.setOpen(false)}
        >
            <DialogTitle>Mark Jobs Ready</DialogTitle>
            <MarkJobReadyForm 
                {...props} 
                onSubmit={async (data) => {
                    try {
                        props.setLoading(true);

                        // create new JobReady object
                        await submitJobReadyForm(data.fieldPriority, data.submissionNotes, data.selectedJobs);
                        props.setOpen(false);
                        toast.success(`Marked ${data.selectedJobs.length} jobs as ready!`);
                        await props.onSuccess(data.selectedJobs);
                    } catch (error: any) {
                        Oops.handleError(error);
                    } finally {
                        props.setLoading(false);
                    }
                }}
                onCancel={() => {
                    props.setOpen(false);
                }}
            />
        </Dialog>
    );
}