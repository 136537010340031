import { 
    Audio,
    BallTriangle,
    Bars,
    Circles,
    CirclesWithBar,
    ColorRing,
    Comment,
    Discuss,
    DNA,
    FallingLines,
    FidgetSpinner,
    Grid,
    Hearts,
    Hourglass,
    InfinitySpin,
    LineWave,
    MagnifyingGlass,
    MutatingDots,
    Oval,
    ProgressBar,
    Puff,
    Radio,
    RevolvingDot,
    Rings,
    RotatingLines,
    RotatingSquare,
    RotatingTriangles,
    TailSpin,
    ThreeCircles,
    ThreeDots,
    Triangle,
    Vortex,
    Watch
} from "react-loader-spinner";
import RogoLogo from '../../assets/images/icon.svg';

const SpinnerOptions = [
    Audio,
    BallTriangle,
    Bars,
    Circles,
    CirclesWithBar,
    ColorRing,
    Comment,
    Discuss,
    DNA,
    FallingLines,
    FidgetSpinner,
    Grid,
    Hearts,
    Hourglass,
    InfinitySpin,
    LineWave,
    MagnifyingGlass,
    MutatingDots,
    Oval,
    ProgressBar,
    Puff,
    Radio,
    RevolvingDot,
    Rings,
    RotatingLines,
    RotatingSquare,
    RotatingTriangles,
    TailSpin,
    ThreeCircles,
    ThreeDots,
    Triangle,
    Vortex,
    Watch
] as const;

const random = false;

export const LoadingComponent = () => {
    
    // return random spinner
    if (random) {
        const Spinner = SpinnerOptions[Math.floor(Math.random() * SpinnerOptions.length)];
        return <Spinner />;
    }

    return (
        // load assets/images/icon.svg and make it spin
        <img
            id="rogoloader"
            src={RogoLogo}
            alt="Rogo Logo"
            style={{
                // grow and shrink animation
                animation: 'spin 2s linear infinite',
                // color: '#274052',
                // backgroundColor: '#274052',
                // fill: '#274052',
                // stroke: '#274052',
                // floodColor: '#274052',
            }}
            height={200}
            width={200}
            // color="#274052"
        />

        // <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
        // <FidgetSpinner />
        // </div>
    );
};