import {
  Box,
  lighten,
  Button,
  Autocomplete,
  TextField,
  Checkbox,
  Stack,
  Typography,
} from "@mui/material";
import {
  MRT_RowSelectionState,
  MRT_VisibilityState,
  MRT_GroupingState,
  MRT_ColumnOrderState,
  MRT_ColumnDef,
  useMaterialReactTable,
  MRT_GlobalFilterTextField,
  MRT_ToggleFiltersButton,
  MaterialReactTable,
  MRT_ExpandedState,
  MRT_ExpandAllButton,
  MRT_PaginationState,
  MRT_ToggleFullScreenButton,
} from "material-react-table";
import { useState, useMemo, useEffect } from "react";
import { GeoFileType, JobsPriorityOvrd } from "../../utilities/types";
import { toast } from "react-toastify";
import { allStringsMatchUniquely } from "../../utilities/utils";
import { API, Deal, Job } from "../../api";
import { Oops } from "../../utilities/oops";
import { AutoAwesome, Download, Publish } from "@mui/icons-material";
import { DownloadFile } from "../../utilities/downloadFile";
import JSZip from "jszip";

interface JobsImportTableProps {
  jobs: Job[];
  updateJobs: (jobs: Job[]) => void;
  deals: Deal[];
  setDrawerState: (open: boolean) => void;
  setLoading: (task: string | boolean) => void;
  resetForm: () => void;
  allDeals: Deal[];
  allOptions: Record<GeoFileType, File[]>;
  isProagrica: boolean;
}

export default function JobImportTable(props: JobsImportTableProps) {
  const [rowSelection, setRowSelection] = useState<MRT_RowSelectionState>({});
  const [expanded, setExpanded] = useState<MRT_ExpandedState>(true);
  const [jobs, setJobs] = useState<Job[]>(props.jobs);
  const [allShapefiles, setAllShapefiles] = useState<Record<GeoFileType, File[]>>({ ...props.allOptions });
  const [availableShapefiles, setAvailableShapefiles] = useState<Record<GeoFileType, File[]>>({ ...props.allOptions });
  const [readySelectionState, setReadySelectionState] = useState<Record<string, boolean>>({});
  const [recordBoundaryState, setRecordBoundaryState] = useState<Record<string, boolean>>({});
  const [validationErrors, setValidationErrors] = useState<Record<string, string | undefined>>({});
  const [pagination, setPagination] = useState<MRT_PaginationState>({
    pageIndex: 0,
    pageSize: 10,
  });
  const [columnVisibility, setColumnVisibility] = useState<MRT_VisibilityState>({
    'farm': false,
  });
  const [grouping, setGrouping] = useState<MRT_GroupingState>([
    'grower'
  ]);
  const [columnOrder, setColumnOrder] = useState<MRT_ColumnOrderState>([
    'grower',
    'field',
  ]);
  // these are the potential options for the autocomplete fields
  const [gridSizes] = useState<number[]>([... new Set([
    ...props.allDeals.flatMap((deal) => { return deal.density }),
    ...props.jobs.flatMap((job) => job.density ? [job.density] : [])])
  ]);
  const [depths] = useState<number[]>([... new Set([
    ...props.allDeals.flatMap((deal) => { return deal.depth }),
    ...props.jobs.flatMap((job) => job.depth ? [job.depth] : [])])
  ]);
  const [labPackages] = useState<string[]>([... new Set<string>([
    ...props.allDeals.flatMap((deal) => {
      const field = deal.testPackage;
      return field !== undefined && field !== '' ? field : [];
    }),
    ...props.jobs.flatMap((job) => job.testPackage ? [job.testPackage] : []),
    ...["No test package specified"]
  ])]);
  const [micros] = useState<number[]>([...new Set(
    props.allDeals.flatMap((deal) => { return deal.frequencyOfMicros ?? 0; })),
    ...props.jobs.flatMap((job) => job.frequencyOfMicros ? [job.frequencyOfMicros] : [])
  ]);

  const checkErrors = (jobs: Job[]) => {
    for (const job of jobs) {
        if (job.density == undefined || job.density == 0 || isNaN(parseFloat( job.density.toString()))) {
          setValidationErrors((prev) => ({ ...prev, [job.id]: "Density must be a number" }));
        } else if (job.depth == undefined || job.depth == 0 || isNaN(parseFloat(job.depth.toString()))) {
          setValidationErrors((prev) => ({ ...prev, [job.id]: "Depth must be a number" }));
        } else {
          setValidationErrors((prev) => {
            const newErrors = { ...prev };
            delete newErrors[job.id];
            return newErrors;
          });
        }
    }
  };


  const findDeal = (deals: Deal[], density: number, depth: number, labPackage: string, micros: number | string) => {
    return deals.find((deal: Deal) => {
      const dealLabPackage = deal.testPackage;
      micros = parseFloat(micros.toString())
      return (
        deal.density == density &&
        deal.depth == depth &&
        deal.frequencyOfMicros == micros &&
        (dealLabPackage == labPackage ||
          ((labPackage == '' || labPackage == 'No test package specified') && (dealLabPackage == '' || dealLabPackage == undefined))
        )
      )
    });
  }


  useEffect(() => {
    if (props.allOptions.Boundary) {
      props.allOptions.Boundary.sort((file1, file2) => file1.name.localeCompare(file2.name));
    }
    if (props.allOptions.Points) {
      props.allOptions.Points.sort((file1, file2) => file1.name.localeCompare(file2.name));
    }
    if (props.allOptions.Zones) {
      props.allOptions.Zones.sort((file1, file2) => file1.name.localeCompare(file2.name));
    }

    setAllShapefiles({ ...props.allOptions });
    setAvailableShapefiles({ ...props.allOptions });
  }, [props.allOptions]);


  useEffect(() => {
    // finds matching deals for jobs
    const updatedJobs = props.jobs.map(job => ({
      ...job,
      deal: (findDeal(props.allDeals, job.density ?? 0, job.depth, job.testPackage ?? '', job.frequencyOfMicros ?? 0)?.id || 'custom').toString()
    }));


    setJobs(updatedJobs);
  }, [props.jobs, props.deals]);

  //#region COLUMNS
  const columns = useMemo<MRT_ColumnDef<Job>[]>(
    () =>
      [
        column_Grower(),
        column_Farm(),
        column_FieldName(),
        column_IsReadyToSample(),
        column_Deal(),
        column_Density(),
        column_Depth(),
        column_TestPackage(),
        column_FrequencyOfMicros(),
        column_FieldPriority(),
        column_BoundarySHP(),
        column_PointsSHP(),
        column_ZonesSHP(),
        column_LabAccountNumber(),
        column_LabSubmissionCode(),
        column_GrowerPhoneNumber(),
        column_SubmissionNotes(),
        column_FieldId()
      ],
    [availableShapefiles, props.jobs, validationErrors, readySelectionState, recordBoundaryState],
  );

  function column_GrowerPhoneNumber(): MRT_ColumnDef<Job, unknown> {
    return {
      id: 'growerPhoneNumber',
      header: 'Grower Cell',
      accessorKey: 'growerPhoneNumber',
      enableEditing: true,
      muiEditTextFieldProps: ({ cell, row }) => ({
        type: 'text',
        error: !!validationErrors?.[cell.id],
        helperText: validationErrors?.[cell.id],
        onBlur: (event) => {
          const value = event.target.value;
          if (value != "" && !value.match(/^\+?(?=.*\d.*\d.*\d.*\d.*\d.*\d.*\d.*\d.*\d.*\d)(?=.*\d?.*\d?.*\d?.*\d?.*\d?.*\d?.*\d?.*\d?.*\d?.*\d?.*\d?).*$/)) {
              setValidationErrors((prev) => ({ ...prev, [cell.id]: "Invalid phone number" }));
              return;
          } else {
            setValidationErrors((prev) => {
              const newErrors = { ...prev };
              delete newErrors[cell.id];
              return newErrors;
            });
          }
        },
      }),
    };
  }

  function column_FieldPriority(): MRT_ColumnDef<Job, unknown> {
    return {
      id: 'fieldPriority',
      header: 'Priority',
      accessorKey: 'fieldPriority',
      minSize: 250,
      enableEditing: false,
      Cell: ({ cell, row }) => {
        return <Autocomplete
          size="small"
          style={{ width: '100%', padding: 0, }}
          defaultValue={row.original.fieldPriority}
          options={JobsPriorityOvrd}
          renderInput={(params) => (<TextField style={{ padding: 0 }} {...params} />)}
          onChange={(event, newValue) => {
            const newJobs = [...jobs];
            const newJob = newJobs.find((j) => {
              return j.id === row.original.id;
            });
            if (!newJob) return;
            const index = newJobs.indexOf(newJob);
            newJob.fieldPriority = newValue || "Regular Turn (default)";
            newJobs[index] = newJob;
            setJobs(newJobs);
          }}
          getOptionLabel={(event) => { return event; } } />;
      },
    };
  }

  function column_LabAccountNumber(): MRT_ColumnDef<Job, unknown> {
    return {
      id: 'labAccountNumber',
      header: 'Lab Account',
      accessorKey: 'labAccountNumber',
      enableEditing: true,
      size: 140,
      muiEditTextFieldProps: ({ cell, row }) => ({
        type: 'text',
        required: true,
        error: !!validationErrors?.[cell.id],
        helperText: validationErrors?.[cell.id],
        defaultValue: row.original.labAccountNumber,
        onChange: (event) => {
          const newJobs = [...jobs];
          const newJob = newJobs.find((j) => {
            return j.fieldId === row.original.fieldId;
          });
          if (!newJob) return;
          const index = newJobs.indexOf(newJob);
          newJob.labAccountNumber = event.target.value;
          newJobs[index] = newJob;
          setJobs(newJobs);
        }
      }),
    };
  }

  function column_IsReadyToSample(): MRT_ColumnDef<Job, unknown> {
    return {
      id: 'isReadytoSample',
      header: 'Ready?',
      minSize: 100,
      size: 100,
      accessorKey: 'isReadytoSample',
      enableEditing: false,
      Cell: ({ cell, row }) => {
        row.original.isReadyToSample = jobs.find(f => f.id === row.original.id)?.isReadyToSample || false;
        return <Checkbox
          checked={row.original.isReadyToSample}
          onChange={(event) => {
            const newJobs = [...jobs];
            const newJob = newJobs.find((j) => j.id === row.original.id);
            if (!newJob) return;
            const index = newJobs.indexOf(newJob);
            newJob.isReadyToSample = event.target.checked;
            newJobs[index] = newJob;
            setJobs(newJobs);

            const growerName = row.original.growerName;
            const allAreSelected = newJobs.filter(j => j.growerName === growerName).every(j => j.isReadyToSample);
            setReadySelectionState(prevState => ({
              ...prevState,
              [growerName]: allAreSelected,
            }));
          }} />;

      },
      AggregatedCell: ({ row }) => {
        const growerName = row.original.growerName;
        return <Checkbox
          checked={readySelectionState[growerName] || false}
          indeterminate={!(readySelectionState[growerName] || false) && jobs.some(job => job.growerName === growerName && job.isReadyToSample)}
          onChange={(event) => {
            const isChecked = event.target.checked;
            const newJobs = jobs.map((job) =>
              job.growerName === growerName ? { ...job, isReadyToSample: isChecked } : job
            );
            setJobs(newJobs);

            setReadySelectionState(prevState => ({
              ...prevState,
              [growerName]: isChecked,
            }));
          }} />;
      }
    };
  }

  function column_ZonesSHP(): MRT_ColumnDef<Job, unknown> {
    return {
      id: "zones",
      header: "Zone Files",
      accessorKey: "files.zonesSHP",
      enableEditing: false,
      minSize: 300,
      Cell: ({ cell, row }) => {
        return <Autocomplete
          size="small"
          style={{ width: '100%', padding: 0, }}
          options={availableShapefiles["Zones"] || []}
          renderInput={(params) => (<TextField style={{ padding: 0 }} {...params} />)}
          //@ts-ignore
          groupBy={(option) => { return option.geoType || ''; } }
          value={row.original.files.zonesSHP?.length ? row.original.files.zonesSHP[0] : null}
          onChange={(event, newValue) => {
            const newJobs = [...jobs];
            const newJob = newJobs.find((job) => {
              return job.id === row.original.id
            });
            if (!newJob) return;
            const index = newJobs.indexOf(newJob);
            const newAvailableShapefiles = { ...availableShapefiles };
            const availableZones = availableShapefiles["Zones"];

            const oldZonesFile = newJob.files.zonesSHP?.length ? newJob.files.zonesSHP[0] : null;
            if (oldZonesFile) {
              availableZones.push(oldZonesFile);
            }

            if (newValue) {
              const index = availableZones.indexOf(newValue);
              if (index >= 0) {
                availableZones.splice(index, 1);
              }
            }

            newJob.files.zonesSHP = newValue ? [newValue] : [];
            row._valuesCache.zones = newValue ? [newValue] : [];
            availableZones.sort((file1, file2) => file1.name.localeCompare(file2.name));

            setAvailableShapefiles(newAvailableShapefiles);
            newJobs[index] = newJob;
            setJobs(newJobs);
          }}
          getOptionLabel={(event) => {
            return event?.name || "";
          } } />;
      },
    };
  }

  function column_PointsSHP(): MRT_ColumnDef<Job, unknown> {
    return {
      id: "points",
      header: "Points Files",
      accessorKey: "files.pointsSHP",
      enableEditing: false,
      minSize: 300,
      Cell: ({ cell, row }) => {
        return <Autocomplete
          size="small"
          style={{ width: '100%', padding: 0, }}
          options={availableShapefiles["Points"] || []}
          renderInput={(params) => (<TextField style={{ padding: 0 }} {...params} />)}
          //@ts-ignore
          groupBy={(option) => { return option.geoType || ''; } }
          value={row.original.files.pointsSHP?.length ? row.original.files.pointsSHP[0] : null}
          onChange={(event, newValue) => {
            const newJobs = [...jobs];
            const newJob = newJobs.find((job) => {
              return job.id === row.original.id
            });
            if (!newJob) return;
            const index = newJobs.indexOf(newJob);
            const newAvailableShapefiles = { ...availableShapefiles };
            const availablePoints = availableShapefiles["Points"];

            const oldPointsFile = newJob.files.pointsSHP?.length ? newJob.files.pointsSHP[0] : null;
            if (oldPointsFile) {
              availablePoints.push(oldPointsFile);
            }

            if (newValue) {
              const index = availablePoints.indexOf(newValue);
              if (index >= 0) {
                availablePoints.splice(index, 1);
              }
            }

            newJob.files.pointsSHP = newValue ? [newValue] : [];
            row._valuesCache.points = newValue ? [newValue] : [];
            availablePoints.sort((file1, file2) => file1.name.localeCompare(file2.name));

            setAvailableShapefiles(newAvailableShapefiles);
            newJobs[index] = newJob;
            setJobs(newJobs);
          }}
          getOptionLabel={(event) => { return event?.name || ""; } } />;
      },
    };
  }

  function column_BoundarySHP(): MRT_ColumnDef<Job, unknown> {
    return {
      id: "boundary",
      header: "Boundary Files",
      accessorKey: "files.boundarySHP",
      enableEditing: false,
      minSize: 300,
      Cell: ({ cell, row }) => {
        return <Autocomplete
          size="small"
          style={{ width: '100%', padding: 0, }}
          options={availableShapefiles["Boundary"] || []}
          renderInput={(params) => (<TextField style={{ padding: 0 }} {...params} />)}
          value={row.original.files.boundarySHP?.length ? row.original.files.boundarySHP[0] : null}
          onChange={(event, newValue) => {
            const newJobs = [...jobs];
            const newJob = newJobs.find((job) => {
              return job.id === row.original.id
            });
            if (!newJob) return;
            const index = newJobs.indexOf(newJob);
            const newAvailableShapefiles = { ...availableShapefiles };
            const availableOptions = newAvailableShapefiles["Boundary"];

            const oldBoundaryFile = newJob.files.boundarySHP?.length ? newJob.files.boundarySHP[0] : null;
            if (oldBoundaryFile) {
              availableOptions.push(oldBoundaryFile);
            }

            if (newValue) {
              const index = availableOptions.indexOf(newValue);
              if (index >= 0) {
                availableOptions.splice(index, 1);
              }
            }

            //newJob.boundary = newValue ? [newValue] : [];
            newJob.files.boundarySHP = newValue ? [newValue] : [];
            // row._valuesCache.boundary = newValue ? [newValue] : [];
            row.toggleSelected();

            availableOptions.sort((file1, file2) => file1.name.localeCompare(file2.name));

            setAvailableShapefiles(newAvailableShapefiles);
            newJobs[index] = newJob;
            setJobs(newJobs);
          }}
          getOptionLabel={(file) => { return file?.name || ""; } } />;
      },
    };
  }

  function column_SubmissionNotes(): MRT_ColumnDef<Job, unknown> {
    return {
      id: 'submissionNotes',
      header: 'Submission Notes',
      accessorKey: 'submissionNotes',
      enableEditing: true,
      muiEditTextFieldProps: ({ cell, row }) => ({
        type: 'text',
        required: true,
        disabled: false,
        error: !!validationErrors?.[cell.id],
        helperText: validationErrors?.[cell.id],
        value: row.getValue('submissionNotes'),
        onChange: (event) => {
          const newJobs = [...jobs];
          const newJob = newJobs.find((job) => {
            return job.fieldId === row.original.fieldId;
          });
          if (!newJob) return;
          const index = newJobs.indexOf(newJob);
          newJob.submissionNotes = event.target.value;
          row._valuesCache.submissionNotes = newJob.submissionNotes;
          // newJob.dealIds = [];
          newJobs[index] = newJob;
          setJobs(newJobs);
        }
      }),
    };
  }

  function column_FrequencyOfMicros(): MRT_ColumnDef<Job, unknown> {
    return {
      id: 'frequencyOfMicros',
      header: 'Micros',
      accessorKey: 'frequencyOfMicros',
      enableEditing: false,
      size: 100,
      Cell: ({ cell, row }) => {
        return <Autocomplete
          size="small"
          freeSolo
          style={{ width: '100%' }}
          id="grid-size-autocomplete"
          value={jobs.find((job) => job.id === row.original.id)?.frequencyOfMicros || ""}
          options={[...new Set(props.allDeals.flatMap((deal) => { return deal.frequencyOfMicros ?? 0; }))]}
          getOptionLabel={(frequency) => {
            return frequency.toString();
          } }
          renderInput={(params) => (
            <TextField
              label="Frequency of Micros"
              variant="outlined"
              error={!!validationErrors?.[cell.id]}
              helperText={validationErrors?.[cell.id]}
              required
              {...params} />
          )}

          onBlur={(event) => {
            const newValue = (event.target as HTMLInputElement).value;
            // console.log(newValue, "newValue")
            // console.log(newValue != "")
            if ((newValue != "") && isNaN(parseFloat(newValue.toString()))) {
              setValidationErrors((prev) => ({ ...prev, [cell.id]: "Frequency of Micros must be a number" }));
              // console.log("validationErrors", validationErrors);
              return;
            } else {
              setValidationErrors((prev) => {
                const newErrors = { ...prev };
                delete newErrors[cell.id];
                return newErrors;
              });
            }
            const newJobs = [...jobs];
            const newJob = newJobs.find((job) => {
              return job.id === row.original.id;
            });
            if (!newJob) return;
            const index = newJobs.indexOf(newJob);
            newJob.frequencyOfMicros = parseFloat((event.target as HTMLInputElement).value);
            row._valuesCache.frequencyOfMicros = newJob.frequencyOfMicros;
            newJob.dealIds = [(findDeal(props.allDeals, newJob.density ?? 0, newJob.depth, newJob.testPackage ?? '', newJob.frequencyOfMicros ?? 0)?.id || 'custom').toString()];
            row._valuesCache.dealIds = newJob.dealIds;
            newJobs[index] = newJob;
            setJobs(newJobs);
          } } />;
      },
      AggregatedCell: ({ row }) => {
        return <Autocomplete
          freeSolo
          style={{ width: '100%' }}
          id="grid-size-autocomplete"
          value={null}
          options={micros}
          getOptionLabel={(frequency) => {
            return frequency.toString();
          } }
          renderInput={(params) => (
            <TextField
              label="Frequency of Micros"
              variant="outlined"
              {...params} />
          )}
          onBlur={(event) => {
            const newValue = (event.target as HTMLInputElement).value;
            if ((newValue != "") && isNaN(parseFloat(newValue.toString()))) {
              return;
            }
            const newJobs = [...jobs];
            for (const job of newJobs) {
              if (job.growerName === row.original.growerName) {
                if (newValue !== null) {
                  job.frequencyOfMicros = parseFloat(newValue.toString());
                }
                job.dealIds = [(findDeal(props.allDeals, job.density ?? 0, job.depth, job.testPackage ?? '', job.frequencyOfMicros ?? 0)?.id || 'custom').toString()];
              }
            }

            if (row.subRows) {
              for (const subRow of row.subRows) {
                const job = subRow._valuesCache;
                if (subRow.original.growerName === row.original.growerName) {
                  job.frequencyOfMicros = parseFloat(newValue.toString());
                  job.dealIds = (findDeal(props.allDeals, job.density ?? 0, job.depth, job.labPackage ?? '', job.frequencyOfMicros ?? 0)?.id || 'custom').toString();
                }

              }
            }
            // console.log(newJobs);
            setJobs(newJobs);
          } } />;
      }
    };
  }

  function column_TestPackage(): MRT_ColumnDef<Job, unknown> {
    return {
      id: 'testPackage',
      header: 'Lab Package',
      accessorKey: 'testPackage',
      size: 220,
      enableEditing: false,
      Cell: ({ cell, row }) => {
        return <Autocomplete
          size="small"
          freeSolo
          style={{ width: '100%' }}
          id="grid-size-autocomplete"
          value={jobs.find((job) => job.id === row.original.id)?.testPackage || ""}
          options={labPackages}
          renderInput={(params) => (
            <TextField
              label="Lab Package"
              variant="outlined"
              {...params} />
          )}
          onBlur={(event) => {
            const newValue = (event.target as HTMLInputElement).value;

            const newJobs = [...jobs];
            const newJob = newJobs.find((job) => {
              return job.id === row.original.id;
            });
            if (!newJob) return;
            const index = newJobs.indexOf(newJob);
            newJob.testPackage = (event.target as HTMLInputElement).value;
            row._valuesCache.labPackage = newJob.testPackage;
            newJob.dealIds = [(findDeal(props.allDeals, newJob.density ?? 0, newJob.depth, newJob.testPackage ?? '', newJob.frequencyOfMicros ?? 0)?.id || 'custom').toString()];
            row._valuesCache.dealIds = newJob.dealIds;
            newJobs[index] = newJob;
            setJobs(newJobs);
          } } />;
      },
      AggregatedCell: ({ row }) => {
        return <Autocomplete
          freeSolo
          style={{ width: '100%' }}
          id="lab-package-autocomplete"
          value={null}
          options={labPackages}
          renderInput={(params) => (
            <TextField
              label="Lab Package"
              variant="outlined"
              {...params} />
          )}
          onChange={(event, newValue) => {
            const newJobs = [...jobs];
            // console.log("newValue", newValue);
            for (const job of newJobs) {
              if (job.growerName === row.original.growerName) {
                job.testPackage = Array.isArray(newValue) ? newValue.join('') : (newValue ?? 'No test package specified'); // Add null check here
                job.dealIds = [(findDeal(props.allDeals, job.density ?? 0, job.depth, job.testPackage ?? '', job.frequencyOfMicros ?? 0)?.id || 'custom').toString()];
              }
            }

            if (row.subRows) {
              for (const subRow of row.subRows) {
                const job = subRow._valuesCache;
                if (subRow.original.growerName === row.original.growerName) {
                  subRow._valuesCache.labPackage = Array.isArray(newValue) ? newValue.join('') : (newValue ?? 'No test package specified');
                  subRow._valuesCache.dealIds = [(findDeal(props.allDeals, job.density ?? 0, job.depth, job.labPackage ?? '', job.frequencyOfMicros ?? 0)?.id || 'custom').toString()];
                }
              }
            }
            // console.log(newJobs);
            setJobs(newJobs);
          } } />;
      }
    };
  }

  function column_Depth(): MRT_ColumnDef<Job, unknown> {
    return {
      id: 'depth',
      header: 'Depth',
      accessorKey: 'depth',
      size: 100,
      enableEditing: false,
      Cell: ({ cell, row }) => {
        return <Autocomplete
          size="small"
          freeSolo
          style={{ width: '100%' }}
          id="grid-size-autocomplete"
          value={jobs.find((job) => job.id === row.original.id)?.depth.toString() || ""}
          options={depths}
          renderInput={(params) => (
            <TextField
              label="Depth"
              variant="outlined"
              error={!!validationErrors?.[cell.id]}
              helperText={validationErrors?.[cell.id]}
              required
              {...params} />
          )}
          onBlur={(event) => {
            const newValue = (event.target as HTMLInputElement).value;
            if (newValue == null || newValue == "" || isNaN(parseFloat(newValue.toString()))) {
              setValidationErrors((prev) => ({ ...prev, [cell.id]: "Depth must be a number" }));
              // console.log("validationErrors", validationErrors);
              return;
            } else {
              setValidationErrors((prev) => {
                const newErrors = { ...prev };
                delete newErrors[cell.id];
                return newErrors;
              });
            }
            const newJobs = [...jobs];
            const newJob = newJobs.find((job) => {
              return job.id === row.original.id;
            });
            if (!newJob) return;
            const index = newJobs.indexOf(newJob);
            newJob.depth = parseFloat((event.target as HTMLInputElement).value);
            row._valuesCache.depth = newJob.depth;
            newJob.dealIds = [(findDeal(props.allDeals, newJob.density ?? 0, newJob.depth, newJob.testPackage ?? '', newJob.frequencyOfMicros ?? 0)?.id || '').toString()];
            row._valuesCache.dealIds = newJob.dealIds;
            newJobs[index] = newJob;
            setJobs(newJobs);

            checkErrors(newJobs);

          } }
          getOptionLabel={(depth) => {
            return depth.toString();
          } } />;
      },
      AggregatedCell: ({ row }) => {
        return <Autocomplete
          freeSolo
          style={{ width: '100%' }}
          id="grid-size-autocomplete-aggregate"
          value={null}
          options={depths}
          renderInput={(params) => (
            <TextField
              label="Depth"
              variant="outlined"
              {...params} />
          )}
          onBlur={(event) => {
            const newValue = parseFloat((event.target as HTMLInputElement).value);
            //console.log("newValue aggergate depth", newValue);
            if (isNaN(newValue)) {
              return;
            }
            const newJobs = [...jobs];
            for (const job of newJobs) {
              if (job.growerName === row.original.growerName) {
                job.depth = parseFloat(newValue.toString());
                job.dealIds = [(findDeal(props.allDeals, job.density ?? 0, job.depth, job.testPackage ?? '', job.frequencyOfMicros ?? 0)?.id || 'custom').toString()];
              }
            }

            if (row.subRows) {
              for (const subRow of row.subRows) {
                const job = subRow._valuesCache;
                if (subRow.original.growerName === row.original.growerName) {
                  subRow._valuesCache.depth = newValue;
                  subRow._valuesCache.dealIds = [(findDeal(props.allDeals, job.density ?? 0, job.depth, job.labPackage ?? '', job.frequencyOfMicros ?? 0)?.id || 'custom').toString()];
                }
              }
            }
            //console.log(newJobs);
            setJobs(newJobs);
            checkErrors(newJobs);
          } } />;
      }
    };
  }

  function column_Density(): MRT_ColumnDef<Job, unknown> {
    return {
      id: "density",
      header: "Density",
      accessorKey: 'density',
      enableEditing: false,
      size: 160,
      Cell: ({ cell, row }) => {
        return <Autocomplete
          size="small"
          freeSolo
          style={{ width: '100%' }}
          id="density-autocomplete"
          value={jobs.find((job) => job.id === row.original.id)?.density || ""}
          options={gridSizes}
          renderInput={(params) => (
            <TextField
              label="Acre density"
              variant="outlined"
              error={!!validationErrors?.[cell.id]}
              helperText={validationErrors?.[cell.id]}
              required
              {...params} />
          )}
          onBlur={(event) => {
            const newValue = (event.target as HTMLInputElement).value;

            const newJobs = [...jobs];
            const newJob = newJobs.find((job) => {
              return (
                job.id === row.original.id
              );
            });
            if (!newJob) return;
            const index = newJobs.indexOf(newJob);
            newJob.density = newValue ? parseFloat(newValue.toString()) : undefined;
            row._valuesCache.density = newJob.density;
            newJob.dealIds = [(findDeal(props.allDeals, newJob.density ?? 0, newJob.depth, newJob.testPackage ?? '', newJob.frequencyOfMicros ?? 0)?.id || 'custom').toString()];
            row._valuesCache.dealIds = newJob.dealIds;
            newJobs[index] = newJob;
            setJobs(newJobs);

            checkErrors(newJobs);
          } }
          getOptionLabel={(gridSize) => {
            return gridSize.toString();
          } } />;
      },
      AggregatedCell: ({ row }) => {
        return <Autocomplete
          freeSolo
          style={{ width: '100%' }}
          id="grid-size-autocomplete"
          value={undefined}
          options={gridSizes}
          renderInput={(params) => (
            <TextField
              label="Acre density" variant="outlined"
              {...params} />
          )}
          onChange={(event, newValue) => {
            const newJobs = [...jobs];
            for (const job of newJobs) {
              if (job.growerName === row.original.growerName) {
                job.density = newValue ? parseFloat(newValue.toString()) : undefined;
                job.dealIds = [(findDeal(props.allDeals, job.density ?? 0, job.depth, job.testPackage ?? '', job.frequencyOfMicros ?? 0)?.id || 'custom').toString()];
              }
            }

            if (row.subRows) {
              for (const subRow of row.subRows) {
                const job = subRow._valuesCache;
                if (subRow.original.growerName === row.original.growerName) {
                  subRow._valuesCache.density = newValue;
                  subRow._valuesCache.dealIds = [(findDeal(props.allDeals, job.density ?? 0, job.depth, job.labPackage ?? '', job.frequencyOfMicros ?? 0)?.id || 'custom').toString()];
                }
              }
            }
            // console.log(newJobs);
            setJobs(newJobs);
            checkErrors(newJobs);
          } }
          getOptionLabel={(gridSize) => {
            return gridSize.toString();
          } } />;
      }
    };
  }

  function column_Deal(): MRT_ColumnDef<Job, unknown> {
    return {
      id: "deal",
      header: "Configuration",
      accessorKey: 'deal',
      enableEditing: false,
      size: 400,
      Cell: ({ cell, row }) => {
        return <Autocomplete
          fullWidth={true}
          size="small"
          value={props.allDeals.find((deal) => deal.id === jobs.find((job) => job.id === row.original.id)?.dealIds[0]) || { id: 'custom', label: 'No Linked Configuration' }}
          options={[...props.allDeals, { id: 'custom', label: 'No Linked Configuration' }]}
          isOptionEqualToValue={(option, value) => option.id === value.id}
          renderInput={(params) => (
            <TextField
              error={!!validationErrors?.[row.original.id]}
              helperText={validationErrors?.[row.original.id]}
              {...params} />
          )}
          onChange={(event, newDeal) => {
            const newJobs = [...jobs];
            const newJob = newJobs.find((job) => {
              return job.id === row.original.id;
            });
            if (!newJob) return;
            const index = newJobs.indexOf(newJob);
            newJob.dealIds = [newDeal?.id || 'custom'];
            row._valuesCache.dealIds = newJob.dealIds;
            if (newJob.dealIds.at(0) !== 'custom') {
              const d = newDeal as Deal;
              newJob.density = d.density;
              row._valuesCache.density = newJob.density;
              newJob.depth = d.depth;
              row._valuesCache.depth = newJob.depth;
              const testPackage = d.testPackage || 'No test package specified';
              newJob.testPackage = Array.isArray(testPackage) ? testPackage.join('') : testPackage;
              row._valuesCache.labPackage = newJob.testPackage;
              newJob.frequencyOfMicros = d.frequencyOfMicros ?? 0;
              row._valuesCache.frequencyOfMicros = newJob.frequencyOfMicros;
            }
            newJobs[index] = newJob;
            setJobs(newJobs);
          }}
          getOptionLabel={(deal) => {
            if (deal && deal.id === 'custom') {
              return 'No linked Configuration';
            } else {
              var d = deal as Deal;
              const dealLabelFields = [d.density, d.type, d.depth, (d.testPackage || 'No test package specified')];
              if (d.specialTag) dealLabelFields.push(d.specialTag);
              dealLabelFields.push(d.season);
              return `${d.density} Acre ${dealLabelFields.join(" - ")}`;
            }
          } } />;
      },
      AggregatedCell: ({ row }) => {
        return <><a style={{ fontWeight: 'bold', fontSize: '1.2em' }}>Update Group: &nbsp;</a><Autocomplete
          fullWidth={true}
          value={undefined}
          options={[...props.allDeals, { id: 'custom', label: 'Unlink All Configurations for Group' }]}
          renderInput={(params) => (
            <TextField
              {...params} />
          )}
          onChange={(event, newDeal) => {
            const dealId = newDeal?.id || 'custom';
            const newJobs = [...jobs];
            const d = newDeal as Deal;

            for (const job of newJobs) {
              if (job.growerName === row.original.growerName) {
                job.dealIds = [dealId];
                if (dealId != 'custom') {
                  job.density = d.density;
                  job.depth = d.depth;
                  job.testPackage = d.testPackage || 'No test package specified';
                  job.frequencyOfMicros = d.frequencyOfMicros ?? 0;
                }
              }
            }

            if (row.subRows) {
              for (const subRow of row.subRows) {
                if (subRow.original.growerName === row.original.growerName) {
                  subRow._valuesCache.dealIds = [dealId];
                  subRow._valuesCache.density = d.density;
                  subRow._valuesCache.depth = d.depth;
                  subRow._valuesCache.labPackage = d.testPackage || 'No test package specified';
                  subRow._valuesCache.frequencyOfMicros = d.frequencyOfMicros ?? 0;
                }
              }
            }
            setJobs(newJobs);
          } }
          getOptionLabel={(option) => {
            if (option && option.id === 'custom') {
              return 'Unlink All Configurations for Group';
            } else {
              const d = option as Deal;
              const dealLabelFields = [d.density, d.type, d.depth, (d.testPackage || 'No test package specified')];
              if (d.specialTag) dealLabelFields.push(d.specialTag);
              dealLabelFields.push(d.season);
              return `${d.density} Acre ${dealLabelFields.join(" - ")}`;
            }
          } } /></>;
      }
    };
  }

  function column_LabSubmissionCode(): MRT_ColumnDef<Job, unknown> {
    return {
      id: 'labSubmissionCode',
      header: 'Lab Submission Code',
      accessorKey: "labSubmissionCode",
      enableEditing: true,
    };
  }
  
  function column_FieldId(): MRT_ColumnDef<Job, unknown> {
    return {
      id: 'fieldId',
      header: 'Field ID (Field Key)',
      accessorKey: "fieldId",
    };
  }
  
  function column_FieldName(): MRT_ColumnDef<Job, unknown> {
    return {
      accessorKey: 'fieldName', //accessorKey used to define `data` column. `id` gets set to accessorKey automatically
      enableEditing: false,
      id: 'field',
      size: 150,
      filterVariant: 'autocomplete',
      header: 'Field',
      aggregationFn: 'count',
      AggregatedCell: ({ cell }) => {
        return <Box sx={{ backgroundColor: '#d4d5d6', fontWeight: 'bold', padding: '4px', borderRadius: '4px' }}>
          # of Fields: {cell.getValue() as string}
        </Box>;
      }
    };
  }
  
  function column_Farm(): MRT_ColumnDef<Job, unknown> {
    return {
      accessorKey: 'farmName', //accessorKey used to define `data` column. `id` gets set to accessorKey automatically
      enableEditing: false,
      id: 'farm',
      filterVariant: 'autocomplete',
      header: 'Farm',
    };
  }
  
  function column_Grower(): MRT_ColumnDef<Job, unknown> {
    return {
      accessorKey: 'growerName', //accessorKey used to define `data` column. `id` gets set to accessorKey automatically
      enableEditing: false,
      id: 'grower',
      filterVariant: 'autocomplete',
      header: 'Grower',
    };
  }
  //#endregion

  //#region TABLE
  function allJobsHaveNoFiles(jobs: Job[]) {
    for (let job of jobs) {
      if (job.files.boundarySHP && job.files.boundarySHP.length > 0) return false;
      if (job.files.zonesSHP && job.files.zonesSHP.length > 0) return false;
      if (job.files.pointsSHP && job.files.pointsSHP.length > 0) return false;
    }
    return true;
  }

  const table = useMaterialReactTable({
    columns,
    data: props.jobs, //data must be memoized or stable (useState, useMemo, defined outside of this component, etc.)
    enableColumnFilterModes: true,
    enableColumnOrdering: true,
    enableGrouping: true,
    enableColumnPinning: true,
    enableColumnResizing: true,
    enableColumnActions: true,
    enableColumnDragging: false,
    enableCellActions: true,
    enableEditing: true,
    editDisplayMode: 'table', // ('modal', 'row', 'cell', 'table', and 'custom' are also
    onCreatingRowCancel: () => setValidationErrors({}),
    onEditingRowCancel: () => setValidationErrors({}),
    groupedColumnMode: 'remove',
    muiTableProps: {
      sx: { maxHeight: '90vh' }
    },
    onExpandedChange: (isExpanded) => {
      setExpanded(isExpanded);
    },
    enableStickyFooter: true,
    state: {
      rowSelection,
      columnVisibility,
      grouping,
      expanded: expanded,
      density: 'compact',
      pagination,
    },
    onPaginationChange: (pagination) => {
      setPagination(pagination);
    },
    onRowSelectionChange: (rows) => {
      const newRowSelection = typeof rows === 'function' ? rows(rowSelection) : rows;
      props.setDrawerState(Object.keys(newRowSelection).length > 0);
      setRowSelection(newRowSelection);
    },
    displayColumnDefOptions: {
      'mrt-row-expand': {
        Header: () => (
          <Stack direction="row" alignItems="center" color={"secondary"}>
            <MRT_ExpandAllButton table={table} />
            <Box>Groups</Box>
          </Stack>
        ),
        GroupedCell: ({ row, table }) => {
          const { grouping } = table.getState();
          return row.getValue(grouping[grouping.length - 1]);
        },
        enableResizing: true,
        size: 200,
      },
    },
    onColumnVisibilityChange: (column) => {
      setColumnVisibility(column);
    },
    onColumnOrderChange: (columns) => {
      setColumnOrder(columns);
    },
    onGroupingChange: (grouping) => {
      setGrouping(grouping);
    },
    muiTableBodyCellProps: ({ row }) => ({
      sx: {
        backgroundColor: row.depth === 0
          ? '#d4d5d6' 
            : 'inherit', 
          
      },
    }),
    layoutMode: "grid",
    initialState: {
      showGlobalFilter: true,
      grouping,
      pagination: {
        pageIndex: 0,
        pageSize: 20
      },
      columnPinning: {
        left: ['grower', 'field']
      },
      columnVisibility: { 'farm': false },
    },
    paginationDisplayMode: 'pages',
    positionToolbarAlertBanner: 'bottom',
    muiSearchTextFieldProps: {
      size: 'small',
      variant: 'outlined',
    },
    muiPaginationProps: {
      color: 'secondary',
      rowsPerPageOptions: [10, 15, 20, 30, 50],
      shape: 'rounded',
      variant: 'outlined',
    },
    renderTopToolbar: ({ table }) => {
      return (
        <Box
          sx={(theme) => ({
            backgroundColor: lighten(theme.palette.background.default, 0.05),
            display: 'flex',
            gap: '0.5rem',
            // p: '8px',
            justifyContent: 'space-between',
          })}
        >
          <Box sx={{ display: 'flex', gap: '0.5rem', alignItems: 'center' }}>
            {/* import MRT sub-components */}
            <MRT_GlobalFilterTextField table={table} />
            <MRT_ToggleFiltersButton table={table} />
          </Box>
          <Box>
            <Box sx={{ display: 'flex', gap: '0.5rem' }}>
              {Object.values(validationErrors).some((error) => !!error) && (
                <Typography color="error">Fix errors before submitting</Typography>
              )}
              <Button color="primary" onClick={downloadBulkImport}><Download className="icon-in-button" />Download Bulk Import</Button>
              {!props.isProagrica &&
                <Button color="primary" variant="contained" onClick={autoMatchFiles}><AutoAwesome className="icon-in-button" />Auto Match Files</Button>
              }
              <Button color="primary" variant="contained" onClick={createJobs}><Publish className="icon-in-button" />Create Jobs</Button>
              <MRT_ToggleFullScreenButton table={table}/>
            </Box>
          </Box>
        </Box>
      );
    }
  });
  //#endregion

  //#region FUNCTIONS
  async function createJobs() {
    props.setLoading('submit');
    if (validationErrors && Object.keys(validationErrors).length > 0) {
      toast.error("Please correct all validation errors before submitting");
      props.setLoading(false);
      return;
    }

    if (allJobsHaveNoFiles(jobs)) {
      if (!window.confirm('No shapefiles attached. Proceed?')) {
        return;
      }
    }

    const creating = toast.loading(`Creating ${jobs.length} Jobs...`);

    jobs.forEach((job) => {
      if (!job.jobFlags.includes("Bulk Upload")) job.jobFlags.push("Bulk Upload");
      job.dealIds = (job.dealIds[0] === 'custom') ? [] : job.dealIds;
    });

    try {
      await API.Data.Jobs.create.many(jobs, props.isProagrica ? "Created Portal API Proagrica" : "Created Portal Bulk");
      toast.success(`Created ${jobs.length} Jobs! Please give 1-2 business days for jobs to show up in the dashboard.`);
      props.resetForm();
    } catch (error: any) {
      Oops.handleError(error, "SubmitJobs.JobImportTable.createJobs");
    } finally {
      toast.dismiss(creating);
      props.setLoading(false);
    }
  }

  async function downloadBulkImport() {
    const csv = generateBulkUploadCSV(jobs);
    
    const zip = new JSZip();
    zip.file('jobs.csv', csv);
    
    for (const job of jobs) {
        const files: File[] = [];
        if (job.files.boundarySHP) files.push(...Array.from(job.files.boundarySHP));
        if (job.files.zonesSHP) files.push(...Array.from(job.files.zonesSHP));
        if (job.files.pointsSHP) files.push(...Array.from(job.files.pointsSHP));
        if (job.files.fieldNotes) files.push(...Array.from(job.files.fieldNotes));

        const jobZip = zip.folder((job.externalId ?? job.fieldName) || job.id);
        for (const file of files) {
            jobZip?.file(file.name, file);
        }
    }

    return await DownloadFile.fromZip(zip, 'bulk_upload');

    function generateBulkUploadCSV(jobs: Job[]) {
        const csvRows = ['3rd Party Reference ID,3rd Party Lab Test Package ID,Boundary Change Type,Branch / Location #form,Depth from Form,Deal,Event ID,Farm from Form,Field from Form,Field ID,Field Notes,Field Ready at Submission?,Grower Cell Phone Number,Grower from Form,Grower Link,Lab from Form,Lab Instructions,Priority OVRD,Regular Lab Submission Code,Retest? (Pts Submitted by Customer),Submission Notes,Submitter Email,Submitter,Job Flags'];
        for (const job of jobs) {
          const row = [
            job.externalId,
            job.testPackage,
            job.boundaryChangeType ? 'Major' : '',
            job.branchIds[0],
            job.depth,
            job.dealIds[0],
            job.eventId,
            job.farmName,
            job.fieldName,
            job.fieldId,
            job.isReadyToSample,
            job.growerPhoneNumber,
            job.growerName,
            job.growerIds[0],
            job.labName,
            job.labInstructions,
            job.fieldPriority,
            job.labSubmissionCode,
            job.isPointsAttached,
            job.submissionNotes,
            job.submitterEmail,
            job.submitterName,
            job.jobFlags,
          ].join(',');
    
          csvRows.push(row);
        }
    
        const csv = csvRows.join('\n');
        return csv;
    }
  }

  function autoMatchFiles() {
    let startingFileCount = {
      boundarySHP: allShapefiles.Boundary.length,
      pointsSHP: allShapefiles.Points.length,
      zonesSHP: allShapefiles.Zones.length
    }

    let identified = {
      boundarySHP: 0,
      pointsSHP: 0,
      zonesSHP: 0
    }

    const allFieldNamesUnique = getAllFieldNamesUnique();
    const allFieldIdsUnique = getAllFieldIdsUnique();

    for (const job of jobs) {
      job.files.boundarySHP = [];
      job.files.pointsSHP = [];
      job.files.zonesSHP = [];
    }

    let unmatchedFiles = allShapefiles;
    for (let i = 0; i < 5; i++) {
      unmatchedFiles = matchFiles(unmatchedFiles, i);
    }
    
    notifyUser();

    for (const job of jobs) {
      removeFilesFromAvailable(job.files.boundarySHP!, "Boundary");
      removeFilesFromAvailable(job.files.pointsSHP!, "Points");
      removeFilesFromAvailable(job.files.zonesSHP!, "Zones");
    }


    setAvailableShapefiles({ ...availableShapefiles });
    props.updateJobs([...jobs]);

    function matchFiles(files: { Boundary: File[]; Points: File[]; Zones: File[]; }, i: number = 0) {
      let matchedFiles = {
        Boundary: [] as File[],
        Points: [] as File[],
        Zones: [] as File[],
        Unknown: [] as File[]
      };
  
      if (thereAreStillFilesToMatch()) {
        for (const job of jobs) {
          if (files.Boundary.length > 0 && job.files.boundarySHP?.length === 0) {
            matchedFiles.Boundary.push(...matchFilesForJob(job, "boundarySHP", files.Boundary));
          }
          if (files.Points.length > 0 && job.files.pointsSHP?.length === 0) {
            matchedFiles.Points.push(...matchFilesForJob(job, "pointsSHP", files.Points));
          }
          if (files.Zones.length > 0 && job.files.zonesSHP?.length === 0) {
            matchedFiles.Zones.push(...matchFilesForJob(job, "zonesSHP", files.Zones));
          }
        }
      }
  
      let unmatchedFiles = {
        Boundary: files.Boundary.filter((file) => !matchedFiles.Boundary.includes(file)),
        Points: files.Points.filter((file) => !matchedFiles.Points.includes(file)),
        Zones: files.Zones.filter((file) => !matchedFiles.Zones.includes(file)),
        Unknown: [] as File[]
      };
      return unmatchedFiles;
      
      function thereAreStillFilesToMatch() {
        return files.Boundary.length > 0 && (files.Boundary.length + jobs.length > startingFileCount.boundarySHP)
          || files.Points.length > 0 && (files.Points.length + jobs.length > startingFileCount.pointsSHP)
          || files.Zones.length > 0 && (files.Zones.length + jobs.length > startingFileCount.zonesSHP);
      }

      function matchFilesForJob(job: Job, type: "boundarySHP" | "pointsSHP" | "zonesSHP", files: File[]): File[] {
        let matchedFileCount = 0;
        for (const file of files) {
          matchedFileCount += (matchFile(file) ? 1 : 0);
        }
        
        let matchedFiles: File[] = [];
        
        if (matchedFileCount == 1) {
          for (const file of files) {
            let fileMatches = matchFile(file);
            
            if (fileMatches) {
              matchedFiles.push(file);
              identified[type]++;
              job.files[type] = [file];
            }
          }
        }
  
        return matchedFiles;
  
        function matchFile(file: File) {
          const filename = cleanString(file.name);
  
          const grower = cleanString(job.growerName);
          const farm = cleanString(job.farmName || "");
          const field = cleanString(job.fieldName);
    
          let fieldIdMatches = job.fieldId ? filename.includes(job.fieldId) : false;
          let fieldNameMatches = filename.includes(field);
          let growerMatches = filename.includes(grower);
          let farmMatches = filename.includes(farm);
    
          let fileMatches = false;
          
          if (allFieldIdsUnique) {
            fileMatches = fieldIdMatches;
          } else if (allFieldNamesUnique) {
            fileMatches = fieldNameMatches;
          } else {
            fileMatches = fieldNameMatches && growerMatches && (!farm || farmMatches);
          }
  
          return fileMatches;
        }
      }
    }

    function notifyUser() {
      const addFieldKey = "Please try adding the Field Key/ID to the file names.";
      const identifiedZoneFiles = `Identified ${identified.pointsSHP} zones files for ${jobs.length} jobs.`;
      if (allShapefiles.Zones.length > 0) {
        toast[identified.pointsSHP === 0 ? 'error' : 'info'](`${identifiedZoneFiles} ${identified.pointsSHP === 0 ? addFieldKey : ''}`);
      }

      const identifiedPointFiles = `Identified ${identified.pointsSHP} points files for ${jobs.length} jobs.`;
      if (allShapefiles.Points.length > 0) {
        toast[identified.pointsSHP === 0 ? 'error' : 'info'](`${identifiedPointFiles} ${identified.pointsSHP === 0 ? addFieldKey : ''}`);
      }

      const identifiedBoundaryFiles = `Identified ${identified.boundarySHP} boundary files for ${jobs.length} jobs.`;
      if (allShapefiles.Boundary.length > 0) {
        toast[identified.boundarySHP === 0 ? 'error' : 'info'](`${identifiedBoundaryFiles} ${identified.boundarySHP === 0 ? addFieldKey : ''}`);
      }
    }

    function removeFilesFromAvailable(files: File[], type: "Zones" | "Points" | "Boundary") {
      for (const file of files) {
        const index = availableShapefiles[type].findIndex((f) => f.name === file.name);
        if (index >= 0) {
          availableShapefiles[type].splice(index, 1);
        }
      }
    }

    function cleanString(str: string) {
      return str.toLowerCase()
        .replace(/ /g, "")
        .replace(/,/g, "")
        .replace(/#/g, "");
    };

    function getAllFieldNamesUnique() {
      const fieldNames = jobs.map((job) => job.fieldName);
      const uniqueFieldNames = new Set(fieldNames);
      const _allFieldNamesUnique = fieldNames.length === uniqueFieldNames.size;
      let allBoundariesMatchUniquely = false;
      if (_allFieldNamesUnique) {
        allBoundariesMatchUniquely = allStringsMatchUniquely(allShapefiles["Boundary"]?.map(file => file.name), fieldNames);
      }
      return _allFieldNamesUnique;
    }

    function getAllFieldIdsUnique() {
      const fieldIds = jobs.map((job) => job.fieldId ?? "");
      const uniqueFieldIds = new Set(fieldIds);
      const _allFieldIdsUnique = fieldIds.length === uniqueFieldIds.size;
      let allBoundariesMatchUniquely = false;
      if (_allFieldIdsUnique) {
        allBoundariesMatchUniquely = allStringsMatchUniquely(allShapefiles["Boundary"]?.map(file => file.name), fieldIds);
      }
      return _allFieldIdsUnique;
    }

  };

  return (
    <div id="bulk-import-table">
      <MaterialReactTable table={table} />
    </div>
  );

};