import { Button, Grid } from "@mui/material";
import { useState } from "react";
import { GeoFileType, KeyedShapefileMap } from "../../utilities/types";
import { FileTaggingAndSplitting } from "../submitjobs/CSV/FileTaggingAndSplitting";
import { DownloadFile } from "../../utilities/downloadFile";

export function SortShapefiles() {
    const [loading, setLoading] = useState(false);
    const [selectableFiles, setSelectableFiles] = useState<Record<string, Record<GeoFileType, File[]>>>({});
    const [originalShapefiles, setOriginalShapefiles] = useState<File[]>([]);
    const [keyedShapefileMap, setKeyedShapefileMap] = useState<KeyedShapefileMap>(new Map());

    return <>
        <Grid alignItems="stretch" alignContent="stretch" container maxWidth="md" margin="auto" spacing={1}>
            <FileTaggingAndSplitting 
                keyedShapefileMap={keyedShapefileMap} 
                setKeyedShapefileMap={setKeyedShapefileMap}
                selectableFiles={selectableFiles}
                setSelectableFiles={setSelectableFiles} 
                originalShapefiles={originalShapefiles}
                setOriginalShapefiles={setOriginalShapefiles}
                loading={loading}
                setLoading={setLoading}
            />
            { !!originalShapefiles.length && 
                <Button 
                    style={{ margin: '10px auto', display: 'block' }} 
                    variant='contained' 
                    onClick={() => {
                    const boundaryFiles = Object.values(selectableFiles).map(fileGroup => fileGroup.Boundary).flat();
                    const pointFiles = Object.values(selectableFiles).map(fileGroup => fileGroup.Points).flat();
                    const zoneFiles = Object.values(selectableFiles).map(fileGroup => fileGroup.Zones).flat();
                    const unknownFiles = Object.values(selectableFiles).map(fileGroup => fileGroup.Unknown).flat();

                    const files = []
                    if (boundaryFiles.length > 0) files.push({ key: 'Boundary', files: boundaryFiles });
                    if (pointFiles.length > 0) files.push({ key: 'Points', files: pointFiles });
                    if (zoneFiles.length > 0) files.push({ key: 'Zones', files: zoneFiles });
                    if (unknownFiles.length > 0) files.push({ key: 'Unknown', files: unknownFiles });

                    DownloadFile.fromGroupsOfFiles(files, `files-${Date.now()}.zip`);
                }}>Download All Files As Zip</Button>
            }
        </Grid>
        <br />
        <br />
        <br />
        <br />
    </>;
}