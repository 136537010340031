import { MRT_ColumnDef, MRT_FilterFn } from "material-react-table";
import { Accessor } from "../../../utilities/types";
import { FieldSet } from 'airtable';
import { Tooltip, Typography } from '@mui/material';
import { BaseDataModel } from "../../../api";

interface TextColumnOptions<T extends FieldSet, U extends BaseDataModel<T>> {
  accessorKeyOrFn?: Accessor<string, T, U>;
  size?: number;
  filterVariant?: string;
  filterSelectOptions?: string[];
  filterFn?: MRT_FilterFn<any>;
}
export const textColumn = <T extends FieldSet, U extends BaseDataModel<T>>(
  header: string,
  {
    accessorKeyOrFn = undefined,
    size = 100,
    filterVariant = 'text',
    filterSelectOptions = [],
    filterFn = 'fuzzy'
  }: TextColumnOptions<T, U> = {}
) => ({
  id: header.toLowerCase(),
  accessorFn: (row) => {
    const value = (typeof accessorKeyOrFn === 'function') ? accessorKeyOrFn(row) : (accessorKeyOrFn ? row[accessorKeyOrFn]?.toString() ?? "" : "");
    return Array.isArray(value) ? value.join(', ') : value;
  },
  header,
  minSize: size,
  maxSize: size,
  // enableGlobalFilter: true,
  filterVariant: filterVariant,
  filterSelectOptions: filterSelectOptions,
  filterFn: filterFn,
  muiFilterTextFieldProps: (props) => ({
    placeholder: "Filter",
    ...props,
  }),
  GroupedCell: ({ cell }) => {
    const value = cell.getValue<string>();
    return (
      <Tooltip title={value}>
        <Typography noWrap>B. {value}</Typography>
      </Tooltip>
    );
  }
}) as MRT_ColumnDef<U>;
