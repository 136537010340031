import { Button, Dialog, DialogTitle, DialogContent, DialogContentText, TextField, DialogActions, Radio, RadioGroup, Box, Typography } from "@mui/material";
import React from "react";
import { ViewGroup } from "../../utilities/types";

export interface Props {
  open: boolean;
  submit: (result?: [string, ViewGroup]) => void;
  title: string;
  content: string;
  submitText: string;
  viewName: string;
};

export default function ViewSaveDialog(props: Props) {
  const [viewName, setViewName] = React.useState<string>(props.viewName);
  const [viewType, setViewType] = React.useState<ViewGroup>(ViewGroup.User);

  const handleClose = () => {
    props.submit();
  };

  return (
    <React.Fragment>
      <Dialog
        open={props.open}
        onClose={handleClose}
        PaperProps={{
          component: 'form',
          onSubmit: (event: React.FormEvent<HTMLFormElement>) => {
            event.preventDefault();
            // Get text and radio button selection
            // console.log(viewName, viewType);

            // Submit the text and view type to the parent component
            props.submit([viewName, viewType]);
            handleClose();
          },
        }}
      >
        <DialogTitle>{props.title}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            {props.content}
          </DialogContentText>
          <TextField
            autoFocus
            required
            value={viewName}
            onChange={(e) => setViewName(e.target.value)}
            margin="dense"
            id="viewName"
            name="viewName"
            label={'View Name'}
            type="text"
            fullWidth
            variant="standard"
          />
          {/* Select between personal view and company view */}
          <RadioGroup aria-label="view" id="viewType" defaultValue="personal" onChange={
            (e) => setViewType(e.target.value ==='personal' ? ViewGroup.User : ViewGroup.Company)
          }>
            <Box component="span" sx={{ 
              display: 'flex', 
              flexDirection: 'row',
              alignContent: 'center',
              alignItems: 'center'
            }}>
              <Radio value="personal" />
              <Typography variant="body1">Personal</Typography>
            </Box>
            <Box component="span" sx={{
              display: 'flex',
              flexDirection: 'row',
              alignContent: 'center',
              alignItems: 'center'
            }}>
              <Radio value="company" />
              <Typography variant="body1">Company</Typography>
            </Box>
          </RadioGroup>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button type="submit">{props.submitText}</Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}