import { Autocomplete, Box, Button, Grid, TextField } from "@mui/material";
import { UserContext } from "../../hooks/UserContext";
import { DownloadOptions, ZipOptions, zip } from "@mapbox/shp-write";
import React, { useState } from "react";
import { toast } from "react-toastify";
import { WKT } from "../../geojson_converters/wkt";
import { SamplingEvent } from "../../utilities/types";
import { toSamplingEventDateString } from "../../utilities/utils";
import { v4 as uuidv4 } from 'uuid';
// import ProAgricaImportTable from "./ProAgricaImportTable";
import { FeatureCollection, Geometry } from "geojson";
import { LoadingComponent } from "../helpers/LoadingComponent";
import { API, Branch, Deal, Contact, ProagricaTypes, Job } from "../../api";
import { Oops } from "../../utilities/oops";
import { wktToGeoJSON } from "@terraformer/wkt";
import JobImportTable from "./JobImportTable";
import { AuthUser } from "../../api/common";


interface ProagricaImportFormProps {
    currentUser?: AuthUser;
    selectedDeals: Deal[];
    selectedBranches: Branch[];
    deals: Deal[];
}

const EventImportOptions = [
    "Use Latest Historical Points",
    "Use Latest Open Event",
    "ROGO Creates All Points"
] as const;
type EventImportOption = typeof EventImportOptions[number];

export function ProagricaImportForm(props: ProagricaImportFormProps) {
    const [jobs, setJobs] = useState<Job[]>([]);
    const [growers, setGrowers] = useState<ProagricaTypes.Grower[]>([]);
    const [selectedGrowers, setSelectedGrowers] = useState<ProagricaTypes.Grower[]>([]);
    const [farms, setFarms] = useState<ProagricaTypes.GrowerFarm[]>([]);
    const [fields, setFields] = useState<ProagricaTypes.Field[]>([]);
    const [selectedFields, setSelectedFields] = useState<ProagricaTypes.Field[]>([]);
    const [loading, setLoading] = useState<string | boolean>(false);
    const userContext = React.useContext(UserContext);
    const proagricaToken = userContext?.proagrica_auth?.access_token ?? "";
    const [user, setUser] = useState<Contact | undefined>(undefined);
    const [eventImportStategy, setEventImportStrategy] = useState<EventImportOption | null>(null);
    const [samplingItems, setSamplingItems] = useState<any[]>([]);
    
    //#region COMPONENT
    return <>
        <Grid
            alignItems="stretch"
            alignContent="stretch"
            container
            maxWidth="md"
            margin="auto"
            spacing={1}
        >
            {/* Step 1: Log in if auth isn't available */}
            {!userContext?.proagrica_auth && 
                <Grid item xs={12}>
                    <Button
                        fullWidth
                        disabled={!!loading}
                        variant="contained"
                        color="primary"
                        onClick={login}>
                        ProAgrica Login
                    </Button>
                </Grid>
            }

            {userContext?.proagrica_auth && !!props.selectedDeals.length &&
                <Grid item xs={12}>
                    <Button
                        fullWidth
                        disabled={!!loading}
                        style={{ marginTop: 20 }}
                        variant="contained"
                        color="primary"
                        onClick={getGrowers}>
                        Load Growers
                    </Button>
                </Grid>
            }

            {(growers.length > 0) && (
                <Grid item xs={12}>
                    <Box sx={{ display: 'flex' }}>
                        <Autocomplete
                            fullWidth
                            className="proacrica-select-dropdown"
                            multiple
                            disabled={!!loading}
                            options={growers}
                            value={selectedGrowers}
                            getOptionKey={(option) => option.ID}
                            getOptionLabel={(option) => option.Name}
                            onChange={handleGrowerChange}
                            renderInput={(params) =>
                                <TextField {...params} label="Choose your grower(s)" variant="outlined" />}
                        />
                        <Button
                            disabled={!!loading}
                            variant='contained'
                            className="proacrica-select-selectall"
                            onClick={() => setSelectedGrowers(growers)} >
                            Select All
                        </Button>
                    </Box>
                </Grid>
            )}

            {(selectedGrowers.length > 0) &&
                <Grid item xs={12}>
                    <Button
                        fullWidth
                        style={{ marginTop: 20 }}
                        disabled={!!loading}
                        variant="contained"
                        color="primary"
                        onClick={getFields}>
                        Load Fields
                    </Button>
                </Grid>
            }
            
            {(fields.length > 0) &&
                <Grid item xs={12}>
                    <Box sx={{ display: 'flex' }}>
                        <Autocomplete
                            fullWidth
                            className="proacrica-select-dropdown"
                            multiple
                            disabled={!!loading}
                            options={fields}
                            value={selectedFields}
                            getOptionKey={(field) => field.ID}
                            // @ts-ignore
                            getOptionLabel={(field) => `${!!field.GrowerName ? `${field.GrowerName} - ` : ""}${field.Name}`}
                            onChange={(event, value) => setSelectedFields(value)}
                            renderInput={(params) =>
                                <TextField {...params} label="Choose your field(s)" variant="outlined" />}
                        />
                        <Button
                            disabled={!!loading}
                            variant='contained'
                            className="proacrica-select-selectall"
                            onClick={() => setSelectedFields(fields)} >
                            Select All
                        </Button>
                    </Box>
                </Grid>
            }

            {(selectedFields.length > 0) &&
                <Grid item xs={12}>
                    <Button
                        fullWidth
                        style={{ marginTop: 20 }}
                        disabled={!!loading}
                        variant="contained"
                        color="primary"
                        onClick={getSamplingEvents}>
                        Load past & open sampling events
                    </Button>
                </Grid>
            }
            {(loading === 'submit') && (
                <Box display="flex" justifyContent="center" alignItems="center">
                    <LoadingComponent />
                </Box>
            )}
        </Grid>

        {(jobs.length > 0) &&
            <>
                {/* <ProAgricaImportTable
                    jobs={jobs}
                    setDrawerState={() => { }}
                    setLoading={setLoading}
                    resetForm={resetForm}
                    updateJobs={(jobs) => { }}
                    deals={[]}
                /> */}
                <JobImportTable
                    jobs={jobs}
                    updateJobs={(j) => { }}
                    deals={props.selectedDeals}
                    allDeals={props.deals}
                    setDrawerState={() => { }}
                    setLoading={(task: string | boolean) => { setLoading(!!task) }}
                    allOptions={{ Boundary: [], Points: [], Zones: [], Unknown: [] }}
                    resetForm={() => { }}
                    isProagrica={true}
                />
            </>
        }
    </>
    //#endregion

    //#region FUNCTIONS
    function resetForm() {
        setJobs([]);
        setFields([]);
        setSelectedFields([]);
        setFarms([]);
        setSelectedGrowers([]);
        setGrowers([]);
    }

    async function getSamplingEvents() {
        setLoading(true);
        let loadingEvents = toast.loading("Loading Sampling Events...", { progress: 0 });

        // TODO this is a temporary hack to get the first deal to all growers
        // TODO this will be replaced by selection up above IF there is more than one selection
        const _growerDealMap: Record<string, Deal> = {};
        const _growerBranchMap: Record<string, Branch> = {};
        for (const grower of selectedGrowers) {
            _growerDealMap[grower.ID] = props.selectedDeals[0];
            if (props.selectedBranches.length > 0) {
                _growerBranchMap[grower.ID] = props.selectedBranches[0];
            }
        }

        try {
            setJobs([]);
            await API.Integrations.Proagrica.refreshToken();

            const newJobs: Job[] = [];
            const total = fields.length;
            let loaded = 0;
            for (const grower of selectedGrowers) {
                const deal = _growerDealMap[grower.ID];
                const growerFarms = farms.filter((farm) => farm.ParentID === grower.ID);
                
                for (const growerFarm of growerFarms) {
                    const farm = await API.Integrations.Proagrica.getFarm(growerFarm.ID, grower.SyncID);
                    const growerFields = fields.filter((field) => field.FarmID === growerFarm.ID);
                    
                    for (const field of growerFields) {
                        if (!selectedFields.includes(field)) { continue; }
                        if (loadingEvents) toast.update(loadingEvents, { render: `Loading Sampling Events for ${grower.Name} - ${field.Name ?? ""}...`, progress: loaded / total });
                        
                        const s = await API.Integrations.Proagrica.getSamplingItems(field.ID, field.SyncID);
                        samplingItems.push(...s);

                        const fieldSamplingItems = samplingItems.filter((item) => item.FieldID === field.ID).sort((a, b) => {
                            return (b["ModifiedOn"] as string).localeCompare(a["ModifiedOn"]);
                        });

                        const boundaryGeoJSON = convertBoundaryRecordsToGeoJson(field.CurrentBoundary.Records);
                        const boundaryShp = await zip<"blob">(boundaryGeoJSON, getFileOptions(field));

                        const zonesShp: File[] = [];
                        const ptsShp: File[] = [];
                        if (fieldSamplingItems && fieldSamplingItems.length > 0 && fieldSamplingItems[0].SoilSamplingRecords && fieldSamplingItems[0].SoilSamplingRecords.length > 0) {
                            const isZone = fieldSamplingItems[0].SoilSamplingRecords[0].WKT.toLowerCase().includes("polygon")
                            const file = await getZoneOrPointsFile(fieldSamplingItems, field);
                            if (isZone) {
                                zonesShp.push(file);
                            } else {
                                ptsShp.push(file);
                            }
                        }
    
                        const samplingEvents = await getEventsToSelect(fieldSamplingItems);
                        const samplingEventOptions = getEventOptions(samplingEvents);
    
                        const newJob: Job = {
                            files: {
                                pointsSHP: ptsShp,
                                boundarySHP: [new File([boundaryShp], `${field.Name}_${field.ID}_${(new Date()).valueOf()}_bnd.zip`)],
                                boundaryGeoJSON: boundaryGeoJSON,
                                zonesSHP: zonesShp,
                                executedPointsSHP: [],
                                executedBoundarySHP: [],
                                executedPointsBoundarySHP: [],
                                labPDF: [],
                                labCSV: [],
                                fieldNotes: []
                            },
                            depth: deal.depth,
                            dealIds: deal.id ? [deal.id] : [],
                            density: deal.density,
                            labAccountNumber: getLabAccountNumber(deal, grower),
                            testPackage: deal.testPackage,
                            farmName: farm.Name,
                            fieldId: field.ID,
                            fieldName: field.Name,
                            fieldPriority: "Regular Turn (default)",
                            growerName: grower.Name,
                            submitterEmail: user?.email,
                            submitterName: user?.name,
                            
                            // default values
                            eventId: uuidv4(),
                            id: Math.random().toString(36).substring(7),
                            growerPhoneNumber: "",
                            isPointsAttached: false,
                            isReadyToSample: false,
                            labInstructions: "",
                            branchIds: [],
                            jobFlags: [],
                            growerIds: [],
                            name: "",
                            edits: "",
                            submitter: "",
                            season: "",
                            sampleChanges: "",
                            labSubmittalId: "",
                            companyName: "",
                            labName: "",
                            boundaryAcres: 0,
                            status: "Pending",
                            isBillingApproved: false,
                            isBoundaryApproved: false,
                            isBoundaryChanged: false,
                            isMarkedReady: false,
                            isPointsChanged: false,
                            isTestJob: false,
                            dateCreated: new Date(),
                            attachments: {
                                pointsSHP: [],
                                boundarySHP: [],
                                boundaryGeoJSON: [],
                                zonesSHP: [],
                                executedPointsSHP: [],
                                executedBoundarySHP: [],
                                executedPointsBoundarySHP: [],
                                labPDF: [],
                                labCSV: [],
                                fieldNotes: []
                            }
                        };
    
                        loaded++;
                        if (loadingEvents) toast.update(loadingEvents, { render: `Loading Sampling Events for ${grower.Name} - ${field.Name ?? ""}...`, progress: loaded / total });
                        newJobs.push(newJob);
                    }
                }
            }
            setJobs(newJobs);
            if (samplingItems.length) toast.success(`Found ${samplingItems.length} sampling events!`);
        } catch (error: any) {
            Oops.handleError(error, "SubmitJobs.ProagricaImportForm.getSamplingEvents");
        } finally {
            setLoading(false);
            if (loadingEvents && toast.isActive(loadingEvents)) toast.dismiss(loadingEvents);
        }

        async function getZoneOrPointsFile(fieldSamplingItems: ProagricaTypes.SamplingActivity[], field: ProagricaTypes.Field) {
            const geoJSONObjects = await Promise.all(fieldSamplingItems[0].SoilSamplingRecords.map(async (record, index) => {
                let geoJSON = await WKT.toGeoJSON(record.WKT);
                if (geoJSON.features.length > 0) {
                    geoJSON.features[0].properties = {
                        "SampleID": record.SoilSamplingData?.SampleID?.Value ?? 0,
                        "ApplicationDate": fieldSamplingItems[0].ApplicationDate,
                        "Depth": record.SoilSamplingData?.TopsoilSamplingDepth?.Depth?.Value ?? 0,
                        "Units": (record.SoilSamplingData?.TopsoilSamplingDepth?.UnitID?.Value ?? 0) === 52 ? "in" : ""
                    };
                }
                return geoJSON;
            }));
            const featureCollection: FeatureCollection<Geometry, any> = {
                type: 'FeatureCollection',
                features: geoJSONObjects.map((geojson, index) => {
                    return geojson.features;
                }).flat()
            };

            return new File(
                [
                    await zip<"blob">(
                        featureCollection,
                        getFileOptions(field)
                    )
                ],
                `${field.Name}_${field.ID}_${(new Date()).valueOf()}_sampling.zip`
            );
        }

        function getFileOptions(field: ProagricaTypes.Field): DownloadOptions & ZipOptions {
            return {
                types: {
                    polygon: `${field.ID}_bnd`,
                    point: `${field.ID}_pts`,
                },
                compression: 'DEFLATE',
                outputType: 'blob'
            };
        }

        function convertBoundaryRecordsToGeoJson(records: ProagricaTypes.BoundaryRecord[]) {
            return {
                type: "FeatureCollection",
                features: records.map(record => {
                    const geoJSON = wktToGeoJSON(record.WKT);
                    return {
                        geometry: geoJSON,
                        type: "Feature",
                        properties: {
                            measure: record.Measure.Value
                        }
                    }
                })
            } as GeoJSON.FeatureCollection;
        }

        function getEventOptions(events: SamplingEvent[]) {
            events.sort((a, b) => {
                return (a.type && b.type && a.type !== b.type) ? a.type.localeCompare(b.type) : 0;
            });
            const samplingEventOptions: SamplingEvent[] = [
                {
                    id: "rogocreates",
                    label: "ROGO Creates Points",
                    startDate: new Date(),
                    type: null,
                },
                ...events
            ];
            return samplingEventOptions;
        }

        function getNewestEvent(events: SamplingEvent[]) {
            const timeSortedEvents = events.sort((a, b) => {
                return (!a.startDate || !b.startDate) ? 0 : (b.startDate.valueOf() - a.startDate.valueOf());
            });
            const newestEvent = timeSortedEvents.length > 0 ? timeSortedEvents[0] : null;
            return newestEvent;
        }

        async function getEventsToSelect(fieldSamplingItems: ProagricaTypes.SamplingActivity[]): Promise<SamplingEvent[]> {
            return await Promise.all(fieldSamplingItems.map(async (item) => {
                let label = '';
                let startDate: Date | undefined;
                let endDate = '';
                let type: string | null = null;

                if ('ApplicationDate' in item) {
                    const date = new Date(Date.parse(item.ApplicationDate ?? ""));
                    label = `${toSamplingEventDateString(date)}`;
                    startDate = date;
                    endDate = item.ApplicationDate ?? "";
                    type = 'Copy Past Events';
                } else if ('RecommendationDate' in item) {
                    const date = new Date(Date.parse(item.CreatedOn));
                    startDate = date;
                    label = `${toSamplingEventDateString(date)}`;
                    type = 'Use Open Events';
                } else {
                    label = "Unknown";
                }

                return {
                    id: item.ID,
                    label: label,
                    points: [],
                    startDate,
                    type,
                };
            }));
        }

        function getLabAccountNumber(deal: Deal, grower: ProagricaTypes.Grower) {
            const labAccountNumber_Deal = deal.labAccountNumber;
            let labAccountNumber = labAccountNumber_Deal;
            if (labAccountNumber_Deal?.toLowerCase().includes('branch')) {
                const branch = _growerBranchMap[grower.ID];
                if (branch) {
                    const labAccountNumber_Branch = branch.labAccountNumber;
                    if (labAccountNumber_Branch) {
                        labAccountNumber = labAccountNumber_Branch;
                    }
                }
            }
            return labAccountNumber;
        }
    }

    async function getFields() {
        setLoading(true);
        setFields([]);
        setSelectedFields([]);
        setJobs([]);
        await API.Integrations.Proagrica.refreshToken();

        const total = selectedGrowers.length;
        let loaded = 0;
        let loadingFarmsAndFields = toast.loading("Loading Farms and Fields...", { progress: 0 });

        try {
            const retrievedFarms: ProagricaTypes.GrowerFarm[] = [];
            const _fields: ProagricaTypes.Field[] = [];

            for(const grower of selectedGrowers) {
                if (loadingFarmsAndFields) toast.update(loadingFarmsAndFields, { render: `Loading Farms and Fields for ${grower.Name}...`, progress: loaded / total });

                const farms = await API.Integrations.Proagrica.getFarmsForGrower(grower.ID, grower.SyncID);
                retrievedFarms.push(...farms);

                const flds = (await Promise.all(farms.map(async (farm) => {
                    if (farm.IsDeleted) {
                        return [];
                    } else {
                        const growerFarmFields = await API.Integrations.Proagrica.getFieldsForGrowerAndFarm(grower.ID, farm.ID, grower.SyncID);

                        const fields = await Promise.all(growerFarmFields.map(async (field) => {
                            if (field.IsDeleted) {
                                return undefined;
                            } else {
                                const f = await API.Integrations.Proagrica.getField(field.ID, grower.SyncID);
                                f.GrowerName = grower.Name;
                                return f;
                            }
                        }));

                        return fields.filter((field) => field !== undefined) as ProagricaTypes.Field[];
                    }
                }))).flat(1).sort((a, b) => a.Name.localeCompare(b.Name));

                _fields.push(...flds);
                loaded++;
                if (loadingFarmsAndFields) toast.update(loadingFarmsAndFields, { render: `Loading Farms and Fields for ${grower.Name}...`, progress: loaded / total });
            };

            setFarms(retrievedFarms);
            setFields(_fields);
            if (fields.length) toast.success(`Found ${fields.length} total fields!`);
        } catch (error: any) {
            Oops.handleError(error, "SubmitJobs.ProagricaImportForm.getFields");
        } finally {
            setLoading(false);
            // if (loadingFarmsAndFields && toast.isActive(loadingFarmsAndFields)) toast.dismiss(loadingFarmsAndFields);
        }
    }

    function handleGrowerChange(event: any, growers: ProagricaTypes.Grower[]) {
        setFields([]);
        setSelectedFields([]);
        setJobs([]);
        setSamplingItems([]);
        setSelectedGrowers(growers);
    }

    async function getGrowers() {
        setLoading(true);
        await API.Integrations.Proagrica.refreshToken();
        const loadingGrowersToast = toast.loading('Loading Growers...');
        try {
            setFields([]);
            setSelectedFields([]);
            setJobs([]);
            setSamplingItems([]);
            setSelectedGrowers([]);
            
            const growers = await API.Integrations.Proagrica.getGrowers_Init();
            setGrowers(growers);
            if (growers.length) toast.success(`Found ${growers.length} growers!`);
        } catch (error: any) {
            Oops.handleError(error, "SubmitJobs.ProagricaImportForm.getGrowers");
        } finally {
            setLoading(false);
            if (loadingGrowersToast && toast.isActive(loadingGrowersToast)) toast.dismiss(loadingGrowersToast);
        }
    }

    async function login() {
        try {
            const authUrl = await API.Integrations.Proagrica.login(window.location.href);
            setLoading(true);
            window.location.href = authUrl;
        } catch (error: any) {
            Oops.handleError(error, "SubmitJobs.ProagricaImportForm.login");
        }
    }
    //#endregion
}