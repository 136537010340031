import { Grid } from "@mui/material";
import { Deal } from "../../api";
import CustomAutocomplete from "./CustomAutocomplete";

interface Props {
    deals: Deal[];
    selectedDeals: Deal[];
    loading: boolean;
    setSelectedDeals: (deals: Deal[]) => void;
}

function DealLabel(deal: Deal, generateTooltip = false) {
    const testPackage = deal.testPackage || 'No test package specified';
    const testPrefix = !!deal.testCompanyName ? `_TEST for ${deal.testCompanyName} - ` : "";
    let label = `${testPrefix}${deal.density} Acre ${deal.type} - ${deal.depth}" - ${testPackage}`;
    let tooltip = '';
    if (generateTooltip) {
        tooltip = `Details:
        Density:       ${deal.density} Acre
        Sampling Type: ${deal.type}
        Depth:         ${deal.depth}"
        Test Package:  ${testPackage}
        Season:        ${deal.season}`;
    }
    if (deal.specialTag) {
        label += ` ${deal.specialTag}`;
        if (generateTooltip) {
            tooltip += `
            Special:       ${deal.specialTag}`;
        }
    }

    if (deal.customizer) {
        label += ` - ${deal.customizer}`;
        if (generateTooltip) {
            tooltip += `
            Customizer:    ${deal.customizer}`;
        }
    }
    
    if (!generateTooltip) {
        return [label, tooltip] as const;
    }
    return [label, tooltip] as const;
}

export default function DealSelectionAutocomplete(props: Props) {
    return <Grid item xs={12} >
        <CustomAutocomplete
            loading={props.loading}
            options={props.deals.sort((a, b) => {
                const [aLabel] = DealLabel(a);
                const [bLabel] = DealLabel(b);
                return aLabel.localeCompare(bLabel);
            })}
            optionFormatter={(option) => DealLabel(option)[0] ?? ""}
            selectedOption={props.selectedDeals[0] ?? null}
            onChange={(value) => {
                props.setSelectedDeals(value ? [value] : [])
            }} // TODO this is selecting single but we are planning for multiple...
            label="Select the most common sampling configuration you need right now"
            tooltip="Select your configuration. Contact us for more configurations"
        />
    </Grid>
}