import { HashRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import Login from './components/authentication/Login';

import './App.css';
import JobSubmissionForm from './components/submitjobs/JobSubmissionForm';
import { Fade, ThemeProvider, createTheme, styled } from '@mui/material';
import React, { useEffect } from 'react';
import { DrawerNavigation, NavLink } from './components/DrawerNavigation';
import MuiAppBar, { AppBarProps as MuiAppBarProps } from '@mui/material/AppBar';
import CustomToolbar from './components/Toolbar';
import { Logout } from './components/authentication/Logout';
import { UserContext } from './hooks/UserContext';
import { getJwtToken, setJwtToken, setRefreshToken } from './utilities/utils';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Register from './components/authentication/Register';
import PasswordReset from './components/authentication/PasswordReset';
import TitleLocation from './components/TitleLocation';
import JobsDashboardWithLocalizationProvider from './components/jobsdashboard/JobsDashboard';
import { MarkJobReadyPage } from './components/markjobsready/MarkJobReadyPage';
import JobDetails from './components/JobDetails';
import * as Sentry from '@sentry/react';
import { LoadingComponent } from './components/helpers/LoadingComponent';
import { RegistrationContactNotice } from './components/authentication/RegistrationContactNotice';
import LandingPage from './components/LandingPage';
import { API } from './api';
import { DEFAULT_PAGE } from './utilities/constants';
import { AuthUser } from './api/common';
import { SortShapefiles } from './components/tools/SortShapefiles';


const theme = createTheme({
    palette: {
        primary: {
            main: '#274052',
        },
        secondary: {
            main: '#dc004e',
        },
    },
});

interface ProtectedRouteProps {
    redirectPath?: string,
}
const ProtectedRoute = ({ children, redirectPath }: React.PropsWithChildren<ProtectedRouteProps>) => {
    const jwtToken = getJwtToken();
    if (!jwtToken) {
        return <Navigate to={redirectPath ?? "/login"} replace />;
    }

    return children;
};


const drawerWidth = 240;

const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })<{
    open?: boolean;
}>(({ theme, open }) => ({
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: `-${drawerWidth}px`,
    ...(open && {
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        }),
        marginLeft: 0,
    }),
}));

interface AppBarProps extends MuiAppBarProps {
    open?: boolean;
}

const AppBar = styled(MuiAppBar, {
    shouldForwardProp: (prop) => prop !== 'open',
})<AppBarProps>(({ theme, open }) => ({
    transition: theme.transitions.create(['margin', 'width'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    ...(open && {
        width: `calc(100% - ${drawerWidth}px)`,
        marginLeft: `${drawerWidth}px`,
        transition: theme.transitions.create(['margin', 'width'], {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        }),
    }),
}));

const DrawerHeader = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: 'flex-end',
}));

function App() {
    const [drawerOpen, setDrawerOpen] = React.useState(false);
    // force this to be non-null because we won't use this until our loading screen is done
    // therefore any passed down will certainly be truthy
    const [currentUser, setCurrentUser] = React.useState<AuthUser | undefined>();
    const [loading, setLoading] = React.useState(true);
    const [currentLink, setCurrentLink] = React.useState<NavLink>({ to: '/login', text: 'Login' });
    
    useEffect(() => {
        if (!token) {
            setLoading(false);
        } else if (!currentUser?.id) {
            const fetchUser = async () => {
                try {
                    const user = await API.Authentication.getCurrentUser();
                    setCurrentUser(user);
                } catch (error) {
                    // the user is not logged in, no big deal
                } finally {
                    setLoading(false);
                }
            };
            fetchUser();
        }
    }, []);

    useEffect(() => {
        if (!currentUser) {
            return;
        }
        Sentry.setUser({ 
            id: currentUser.id, 
            email: currentUser.email,
            username: currentUser.full_name,
        });
    }, [currentUser])
    const token = getJwtToken();

    const handleLogin = (user: AuthUser) => {
        setCurrentUser(user);
        setDrawerOpen(false); // Close the drawer after login
    };

    return (
        loading ? (
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
                <LoadingComponent />
            </div>
        ) : (
            <Fade in={!loading}>
                <div>
                    <UserContext.Provider value={currentUser}>
                        <ThemeProvider theme={theme}>
                            <Router>
                                <TitleLocation setCurrentLink={setCurrentLink} />
                                <div>
                                    {/* Drawer navigation */}
                                    <CustomToolbar
                                        drawerOpen={drawerOpen}
                                        setDrawerVisibility={(open: boolean) => setDrawerOpen(open)}
                                        title={currentLink.text}
                                    />
                                    <DrawerNavigation
                                        open={drawerOpen}
                                        onClose={() => setDrawerOpen(false)}
                                        setNavigationDrawerState={(open: boolean) => setDrawerOpen(open)}
                                        onNavigate={(link: NavLink) => {
                                            setCurrentLink(link);
                                            setDrawerOpen(false)
                                        }}
                                    />

                                    {/* <div style={{ marginLeft: drawerOpen ? '250px' : '0', transition: 'margin-left 0.3s' }}> */}
                                    <div>
                                        {/* Drawer toggle button */}
                                        <Routes>
                                            <Route path="/home" element={<LandingPage setLoggedIn={handleLogin} />} />
                                            <Route path="/login" element={<Login setLoggedIn={handleLogin} />} />
                                            <Route path="/register" element={<Register setLoggedIn={handleLogin} />} />
                                            <Route path="/" element={currentUser ? <Navigate to={DEFAULT_PAGE} /> : <Login setLoggedIn={handleLogin} />} />
                                            <Route path="/forgot-password" element={<PasswordReset setLoggedIn={handleLogin} />} />
                                            <Route path="/reset-password" element={<PasswordReset setLoggedIn={handleLogin} />} />
                                            <Route path="/jobs">
                                                <Route index path="dashboard" element={
                                                    <ProtectedRoute><JobsDashboardWithLocalizationProvider setLoading={setLoading} /></ProtectedRoute>
                                                } />
                                                <Route path="submit" element={
                                                    <ProtectedRoute><JobSubmissionForm /></ProtectedRoute>
                                                } />
                                                <Route path="ready" element={
                                                    <ProtectedRoute><MarkJobReadyPage setLoading={setLoading} /></ProtectedRoute>
                                                } />
                                                <Route path=":record_id" element={
                                                    <ProtectedRoute><JobDetails /></ProtectedRoute>
                                                } />
                                            </Route>
                                            <Route path="/logout" element={<Logout />} />
                                            <Route path="/registration-notice" element={<RegistrationContactNotice />} />
                                            <Route path="/tools">
                                                <Route index path="sortshp" element={
                                                    <ProtectedRoute><SortShapefiles /></ProtectedRoute>
                                                } />
                                            </Route>
                                        </Routes>
                                    </div>
                                </div>
                            </Router>
                        </ThemeProvider>
                    </UserContext.Provider>
                    <ToastContainer position='bottom-left' />
                </div>
            </Fade>
        )
    );
}

export default Sentry.withProfiler(App);