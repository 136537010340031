import axios from 'axios';
import { getLoginToken, REQUEST_CONFIG_AUTH, } from '../common';
import { v4 as uuidv4 } from 'uuid';
import { Farm, Field, Grower, GrowerFarm, GrowerFarmField, SamplingActivity, SoilSamplingListItem, SoilSamplingPWORecord, SoilSamplingRecord } from "../types/proagrica";
import { API } from '..';
import { Geometry as WKXGeometry } from "wkx";

const RESPONSE_TYPE = "code"

const SCOPES = [
    "sync",
    "openid",
    "profile",
    "referencedata",
    "offline_access"
] as const;

interface APIParameters {
    endpoint: string;
    sync_id: number;
    query_params?: URLSearchParams;
}

export class ProagricaAPI {
    private baseUrl: string;

    constructor() {
        this.baseUrl = `${import.meta.env.VITE_ROGO_API_URL}/proagrica`;
    }

    private async _setProagricaState(nonce: string) {
        await axios.put(`${this.baseUrl}/state?nonce=${nonce}`, undefined, (REQUEST_CONFIG_AUTH()));
    }

    public async login(url: string) {
        const nonce = _generateNonce();
        const authUrl = _getAuthUrl(url, nonce);
        await this._setProagricaState(nonce);

        return authUrl;
    }

    public async refreshToken() {
        const remainingSeconds = _getDecodedAccessToken().payload.exp - Math.floor(Date.now() / 1000);
        if (remainingSeconds > 180) {
            return;
        }
    
        const response = await axios.post(`${this.baseUrl}/refresh-token`, null, {
            headers: {
                Authorization: `Bearer ${getLoginToken()}`,
                'proagrica-refresh-token': _getRefreshToken(),
            }
        });
    
        // call get user to force the token to be stored in local storage
        await API.Authentication.getCurrentUser();
    }

    public getSyncIds() {
        const jwtSyncIds = JSON.parse(_getDecodedAccessToken().payload.syncids) as { id: number, p: string}[]
        const ids = jwtSyncIds.map(syncId => syncId.id);
        return ids;
    }


    public samplingRecordsToGeoJson(records: SoilSamplingRecord[] | SoilSamplingPWORecord[]) {
        // @ts-ignore
        return {
            type: "FeatureCollection",
            features: records.map(record => {
                const geoJSONGeometry = WKXGeometry.parse(
                    "WKB" in record
                        ? Buffer.from(record.WKB)
                        : record.WKT
                ).toGeoJSON();
                return {
                    geometry: geoJSONGeometry,
                    type: "Feature",
                    properties: {
                        //measure: record.Measure.Value
                    }
                }
            })
        } as GeoJSON.FeatureCollection;
    }

    private async _callProxyAPI({ endpoint, query_params, sync_id }: APIParameters) {
        let url = `${this.baseUrl}/proxy/${sync_id}/${endpoint}`;
        if (query_params) {
            url += `?${new URLSearchParams(query_params).toString()}`;
        }
        const response = await axios.get(url, {
            headers: {
                'Authorization': `Bearer ${getLoginToken()}`,
                'Proagrica-Token': _getAccessToken(),
                'Proagrica-Refresh-Token': _getRefreshToken(),
            }
        });

        return response.data;
    }

    public async getGrower(grower_uuid: string | null = null, sync_id: number) {
        return (await this._callProxyAPI({
            endpoint: `Grower/${grower_uuid}`,
            sync_id,
        }) || []) as Grower;
    }
    
    public async getGrowers(sync_id: number) {
        return (await this._callProxyAPI({
            endpoint: `Grower/All`,
            sync_id
        }) || []) as Grower[];
    }

    public async getGrowers_Init(){
        const syncIds = this.getSyncIds();
        const growers = (await Promise.all(syncIds.map(syncId => this.getGrowers(syncId)))).flat(1);
        return growers;
    }
    
    public async getFarm(farm_uuid: string, sync_id: number) {
        return (await this._callProxyAPI({
            endpoint: `Farm/${farm_uuid}`,
            sync_id
        }) || []) as Farm;
    }
    
    public async getFarmsForGrower(grower_uuid: string, sync_id: number) {
        return (await this._callProxyAPI({
            endpoint: `Grower/${grower_uuid}/Farm`,
            sync_id
        }) || []) as GrowerFarm[];
    }
    
    public async getManagementZones(field_uuid: string, sync_id: number) {
        return (await this._callProxyAPI({
            endpoint: `Field/${field_uuid}/ManagementZone`,
            sync_id
        }) || []) as any[];
    }
    
    public async getManagementZone(zone_id: string, sync_id: number) {
        return (await this._callProxyAPI({
            endpoint: `ManagementZone/${zone_id}`,
            sync_id
        }) || []) as any;
    }
    
    public async getFieldsForGrowerAndFarm(grower_uuid: string, farm_uuid: string, sync_id: number) {
        return (await this._callProxyAPI({
            endpoint: `Grower/${grower_uuid}/Farm/${farm_uuid}/Field`,
            sync_id
        }) || []) as GrowerFarmField[];
    }
    
    public async getFields(access_token: string | null = null, sync_id: number) {
        return (await this._callProxyAPI({
            endpoint: `Field?startTime=2024-01-01T15:49:28Z`,
            sync_id
        }) || []) as Field[];
    }
    
    public async getField(field_uuid: string, sync_id: number) {
        return await this._callProxyAPI({
            endpoint: `Field/${field_uuid}`,
            sync_id
        }) as Field;
    }

    public async getSamplingItems(field_uuid: string, sync_id: number) {
        const samplingTypes = [
            // 'SoilSamplingPlan',
            'SoilSamplingRecommendation',
            // 'SoilSamplingWorkOrder',
            'SoilSamplingApplication',
        ]
        const samplingItems = await Promise.all(samplingTypes.map(async samplingType => {
            const samplingActivities = (await this._callProxyAPI({
                endpoint: `Field/${field_uuid}/${samplingType}`,
                sync_id
            }) || []) as SoilSamplingListItem[];
            const undeletedSamplingItems = samplingActivities.filter(activity => !activity.IsDeleted);
            return await Promise.all(undeletedSamplingItems.map(async activity => {
                return await this._callProxyAPI({
                    endpoint: `${samplingType}/${activity["ID"]}`,
                    sync_id
                });
            }));
        }));
        return samplingItems.flat(2) as SamplingActivity[];
    }
}

interface JwtHeader {
    alg: string;
    kid: string;
    typ: string;
    x5t: string;
}

interface JwtPayload {
    nbf: number;
    exp: number;
    iss: string;
    aud: string[];
    nonce: string;
    iat: number;
    at_hash: string;
    s_hash: string;
    sid: string;
    sub: string;
    auth_time: number;
    idp: string;
    family_name: string;
    given_name: string;
    amr: string[];
    syncids: string;
    scope: string[];
}

interface DecodedJWT {
    header: JwtHeader;
    payload: JwtPayload;
}

function _getRefreshToken() {
    let proagrica_refresh_token = localStorage.getItem('proagrica_refresh_token');
    if (!proagrica_refresh_token) {
        throw new Error('No Proagrica refresh token provided');
    }
    return proagrica_refresh_token;
}

function _getAccessToken() {
    const proagrica_access_token = localStorage.getItem('proagrica_access_token');
    if (!proagrica_access_token) {
        throw new Error('No Proagrica token provided');
    }
    return proagrica_access_token;
}

function _getDecodedAccessToken(): DecodedJWT {
    const token = _getAccessToken();

    const [header, payload] = token.split(".");

    // Decode base64 URL-safe encoded header and payload
    const decodedHeader = atob(header).toString();
    const decodedPayload = atob(payload).toString();

    // Convert to JSON
    const headerJson = JSON.parse(decodedHeader) as JwtHeader;
    const payloadJson = JSON.parse(decodedPayload) as JwtPayload;

    return {
        header: headerJson,
        payload: payloadJson
    };
}

function _getAuthUrl(url: string, nonce: string) {
    let url_encoded = encodeURIComponent(url);
    let state = `${url_encoded},${nonce}`;

    let authUrl: string =  `${import.meta.env.VITE_AGX_AUTH_BASE_URL}`;
    authUrl += `/${import.meta.env.VITE_AGX_AUTH_ENDPOINT}`;
    authUrl += `?response_type=${RESPONSE_TYPE}`;
    authUrl += `&client_id=${import.meta.env.VITE_AGX_CLIENT_ID}`;
    authUrl += `&redirect_uri=${import.meta.env.VITE_AGX_ROGO_REDIRECT_URI}`;
    authUrl += `&scope=${SCOPES.join('%20')}`;
    authUrl += `&state=${state}`;

    return authUrl;
}

function _generateNonce() {
    return `${uuidv4()}.${Date.now().valueOf().toString()}`;
}