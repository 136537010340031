import { Controller, Control, Path, FieldValues } from "react-hook-form";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";

interface RHFAutocompleteFieldProps<O, TField extends FieldValues> {
  control: Control<TField>;
  name: Path<TField>;
  options: O[];
  placeholder?: string;
  getId: (option: O) => string;
  getLabel: (option: O) => string;
  multiple?: boolean;
  isOptionEqualToValue?: (option: O, value: O) => boolean;
  freeSolo?: boolean;
  groupBy?: (option: O) => string;
}

export const RHFAutocompleteField = <O, TField extends FieldValues>(
  props: RHFAutocompleteFieldProps<O, TField>
) => {
  const { 
    control, 
    options, 
    name, 
    multiple, 
    getLabel, 
    getId,
    groupBy,
  } = props;
  return (
    <Controller
      name={name}
      control={control}
      rules={{
        required: "This field is required"
      }}
      render={({ field, fieldState: { error } }) => {
        const { onChange, value, ref } = field;
        return (
          <>
            <Autocomplete
              multiple={multiple}
              value={value}
              getOptionKey={getId}
              getOptionLabel={getLabel}
              onChange={(event: any, newValue) => onChange(newValue) }
              groupBy={groupBy}
              id="controllable-states-demo"
              options={options}
              isOptionEqualToValue={props.isOptionEqualToValue}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label={props.placeholder}
                  inputRef={ref}
                />
              )}
            />
            {error ? (
              <span style={{ color: "red" }}>{error.message}</span>
            ) : null}
          </>
        );
      }}
    />
  );
};
