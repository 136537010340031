import { MRT_ColumnDef } from "material-react-table";
import { Accessor } from "../../../utilities/types";
import { FieldSet } from 'airtable';
import { Checkbox, FormControlLabel } from '@mui/material';
import { BaseDataModel } from "../../../api";

interface CheckboxColumnOptions<T extends FieldSet, U extends BaseDataModel<T>> {
  accessorKeyOrFn?: Accessor<boolean, T, U>;
}

export const checkboxColumn = <T extends FieldSet, U extends BaseDataModel<T>>(header: string, {
  accessorKeyOrFn = undefined
}: CheckboxColumnOptions<T, U> = {}) => ({
  id: header.toLowerCase(),
  accessorFn: (row) => typeof accessorKeyOrFn === 'function' ? accessorKeyOrFn(row) : (accessorKeyOrFn ? (row[accessorKeyOrFn] as boolean | undefined) ?? false : false),
  header,
  size: 100,
  // filterVariant: 'checkbox',
  Filter: ({ column }) => (
    <FormControlLabel
        control={
          <Checkbox
            checked={column.getFilterValue() === 'true'}
            color={
              column.getFilterValue() === undefined ? 'default' : 'primary'
            }
            indeterminate={column.getFilterValue() === undefined}
            size='small'
            onChange={(e, checked) => {
              column.setFilterValue(
                column.getFilterValue() === undefined
                  ? 'true'
                  : column.getFilterValue() === 'true'
                    ? 'false'
                    : undefined,
              );
            }}
            sx={(theme) => ({
              height: '2.5rem',
              width: '2.5rem',
            })}
          />
        }
        disableTypography
        label={
          column.getFilterValue() === undefined
                  ? 'Yes & No'
                  : column.getFilterValue() === 'true'
                    ? 'Yes'
                    : "No"
        }
        sx={{ color: 'text.secondary', fontWeight: 'normal', mt: '-4px' }}
        title={undefined}
      />
  ),
  Cell: ({ cell }) => {
    const value = cell.getValue<boolean>();
    return <Checkbox style={{ margin: 0, padding: 0 }} checked={value} readOnly={true} />;
  }
}) as MRT_ColumnDef<U>;
