import { Autocomplete, AutocompleteRenderInputParams, Box, Button, Card, Checkbox, TextField, Tooltip, Typography } from "@mui/material";
import { CategorizedFiles, DisplayFileWithMetadata, GeoFileType, GeoFileTypes, KeyedShapefile, RogoFile } from "../../../utilities/types";
import { ReactNode, useEffect, useState } from "react";
import { ListOfFiles } from "./ListOfFiles";
import { SHP } from "../../../geojson_converters/shp";
import { FOLDER_TOKEN_PREFIX } from "../../../utilities/constants";
import { GeoTypes } from "../../../geojson_converters/converter";
import { FilterType, FilterTypes, KeyGenerationFileFilter, MatchType } from "./KeyGenerationFileFilter";
import { match } from "assert";
import PortalTooltip from "../../helpers/PortalTooltip";
import { MultiPolygon, Point, Polygon } from "geojson";

function matchExists(filter: FilterParameters, file: File, feature: GeoJSON.Feature) {
    // special case: We will count the condition as "True" if we didn't enter any other values
    // kind of an absolute true?
    if (!filter.filterKeys.length && !filter.matchType && !filter.value) {
        return true;
    }

    const matchFound = filter.filterKeys.some(key => {
        let token = '';
        if (key.startsWith(FOLDER_TOKEN_PREFIX)) {
            const folderLevel = parseInt(key.split(' ')[2]);
            const tokens = file.name.split('/');
            token = tokens[folderLevel - 1];
        } else if (feature.properties && key in feature.properties) {
            token = feature.properties[key];
        }

        // console.log(key, token, filter.filterType, filter.matchType, filter.value);

        if (!token) {
            return false;
        }

        if (filter.matchType === "Exists") {
            return true;
        }

        // if not doing an "Exists" filter, and we don't have a value, then we will match this feature
        if (!filter.filterKeys.length) {
            return true;
        }

        // if we have keys but no filter, then we don't want to match
        if (!filter.value) {
            return false;
        }

        const result = token.toLowerCase().includes(filter.value.toLowerCase());

        if (filter.matchType === "Contains") {
            return result;
        } else if (filter.matchType === "Doesn't Contain") {
            return !result;
        }
    });
    return matchFound;
}

const FilterTypeToGeoFileType: Record<FilterType, GeoFileType> = {
    "Exclude File": "Unknown",
    "Boundary": "Boundary",
    "Points": "Points",
    "Zones": "Zones"
}

function getFeatureCategory(file: File, feature: GeoJSON.Feature, categoryFilters: FilterParameters[]): GeoFileType {
    // // auto determine
    // if (feature.geometry.type === "Point") {
    //     return "Points";
    // }
    
    if (categoryFilters.length === 0) {
        return "Unknown";
    }

    for (const categoryFilter of categoryFilters) {
        if (!categoryFilter.filterType) {
            continue;
        }
        const matchFound = matchExists(categoryFilter, file, feature);
        if (matchFound) {
            return FilterTypeToGeoFileType[categoryFilter.filterType];
        }
        //return FilterTypeToGeoFileType[categoryFilter.filterType];
    }

    return "Unknown";
}

function isFeatureExcluded(excludeFilter: FilterParameters, file: File, feature: GeoJSON.Feature): boolean {
    return matchExists(excludeFilter, file, feature);
}

function countFeatures(geojson: GeoJSON.FeatureCollection) {
    const featureCounts: Record<string, number> = {};
    geojson.features.forEach(feature => {
        if (!featureCounts[feature.geometry.type]) {
            featureCounts[feature.geometry.type] = 0;
        }
        featureCounts[feature.geometry.type]++;
    })

    return featureCounts;
}


async function investigateShapefiles(files: File[], uniqueKeyProperties: string[], filterMap: Record<number, FilterParameters> = {}) {
    // console.log(uniqueKeyProperties);
    // iterate through the map and get all collections of shp files
    const results: Record<GeoFileType, Record<string, GeoJSON.FeatureCollection>> = {
        "Boundary": {},
        "Points": {},
        "Zones": {},
        "Unknown": {}
    };
    await Promise.all(files.map(async file => {
        const buffer = Buffer.from(await file.arrayBuffer());
        const geojson = await SHP.toGeoJSON(buffer);
        // count all features grouped by feature type

        const filters = Object.values(filterMap);
        const excludeFilters = filters.filter(filter => filter.filterType === "Exclude File");

        const categoryFilters = filters.filter(filter => filter.filterType !== "Exclude File");

        geojson.features = geojson.features.filter(feature => {
            return excludeFilters.every(filter => !isFeatureExcluded(filter, file, feature));
        });

        geojson.features.forEach(feature => {
            //console.log(`investigateShapefiles feature debug`, feature);
            if (excludeFilters.some(filter => isFeatureExcluded(filter, file, feature))) {
                return;
            }

            const featureType: GeoFileType = getFeatureCategory(file, feature, categoryFilters);

            const filenameTokens = uniqueKeyProperties.map(property => {
                if (property.startsWith(FOLDER_TOKEN_PREFIX)) {
                    const folderLevel = parseInt(property.split(' ')[2]);
                    const tokens = file.name.split('/');
                    const token = tokens[folderLevel - 1];
                    if (token.endsWith('.zip')) {
                        return token.substring(0, token.length - 4);
                    }
                    return token;
                }
                else if (!feature.properties || !(property in feature.properties)) {
                    return;
                }
                return feature.properties[property];
            });
            filenameTokens.push(featureType);
            if (!results[featureType]) {
                results[featureType] = {};
            }
            let filename = `${filenameTokens.filter(token => token).join('_')}.zip`;
            if (!results[featureType][filename]) {
                results[featureType][filename] = {
                    type: "FeatureCollection",
                    features: []
                };
            }
            results[featureType][filename].features.push(feature);
        });
    }));

    // console.log(`investigateShapefiles results`, results);

    const categorizedFiles: CategorizedFiles = {
        "Boundary": [],
        "Points": [],
        "Zones": [],
        "Unknown": []
    };

    // @ts-ignore TODO why can't I type object.keys?
    await Promise.all(Object.keys(results).map(async (type: GeoFileType) => {
        const files = await Promise.all(Object.keys(results[type]).map(async (zipName) => {
            const featureCollection = results[type][zipName];
            const featureCounts = countFeatures(featureCollection);
            return await SHP.fromGeoJSON(featureCollection).then(async (zipBuffer) => {
                const file: RogoFile = new File([zipBuffer], `${zipName}`);
                file.shapeCounts = featureCounts;
                return file;
            });
        }));
        categorizedFiles[type] = files;
    }));
    return categorizedFiles;
}

interface FilterParameters {
    filterType: FilterType | null;
    filterKeys: string[];
    matchType: MatchType | null;
    value: string | null;
}

interface ExcludeFilter extends FilterParameters {
    filterType: "Exclude File";
}

interface KeyGenerationSelectorProps {
    index: number;
    inputFiles: KeyedShapefile[];
    options: string[];
    setOutputFiles(files: File[], type: GeoFileType): void;
    optionTooltips: string[][];
}

export function KeyGenerationSelector(props: KeyGenerationSelectorProps) {
    const [selectedKeys, setSelectedKeys] = useState<string[]>([]);
    const [displayFiles, setDisplayFiles] = useState<File[]>([]);
    const [filterElements, setFilterElements] = useState<JSX.Element[]>([]);
    const [filterParameters, setFilterParameters] = useState<Record<number, FilterParameters>>({});

    useEffect(() => {
        const processFiles = async () => {
            const files = await investigateShapefiles(props.inputFiles, selectedKeys, filterParameters);
            setDisplayFiles(Object.values(files).flat());
            for (const type of GeoFileTypes) {
                // console.log("Set output files for type", type, files[type]);
                props.setOutputFiles(files[type], type);
            }
        }
        if (selectedKeys.length) {
            processFiles();
        }
    }, [filterParameters])

    const generateTooltips = (option: string) => {
        const tooltips = props.optionTooltips[props.options.indexOf(option)].slice(0, 3).map(tooltipString => {
            return <div>{tooltipString.toString()}<br /></div>;
        });

        if (props.optionTooltips[props.options.indexOf(option)].length > 3) {
            tooltips.push(<div>{props.optionTooltips[props.options.indexOf(option)].length - 3} more...</div>);
        }
        return tooltips;
    };
    return <>
        <Typography variant="h5" paddingBottom={2}>{`File Metadata Group ${props.index}`}</Typography>
        <div style={{ display: 'flex', alignItems: 'flex-start' }}>
            <Autocomplete
                style={{
                    width: '65%',
                    marginRight: 25,
                    color: selectedKeys.length === 0 ? 'red' : '',
                    borderColor: selectedKeys.length === 0 ? 'red' : '',
                    borderWidth: selectedKeys.length === 0 ? 10 : 1
                }}
                multiple
                value={selectedKeys}
                options={props.options}
                disableCloseOnSelect
                onChange={async (event, value) => {
                    setSelectedKeys(value);
                    if (!value.length) {
                        setDisplayFiles([]);
                        props.setOutputFiles([], "Unknown");
                        return;
                    }

                    const files = await investigateShapefiles(props.inputFiles, value, filterParameters);
                    // console.log(files);
                    setDisplayFiles(Object.values(files).flat());
                    for (const type of GeoFileTypes) {
                        props.setOutputFiles(files[type], type);
                    }
                }}
                renderOption={(_props, option, { selected }) => (
                    <li {..._props}>
                        <Checkbox checked={selected} />
                        <PortalTooltip placement='right-start' title={generateTooltips(option)}>
                            <Typography>{option}</Typography>
                        </PortalTooltip>
                    </li>
                )}
                renderInput={(params) => <PortalTooltip title={"We need you to select unique filenames or unique labels in your geo data so we know how to separate the points and polygons correctly"}>
                    <TextField {...params} label="Select data to identify features by job" variant="outlined" />
                </PortalTooltip>
                }
            />

            <Autocomplete
                style={{ width: '30%' }}
                onChange={(event, value) => {
                    // console.log('value', value);
                    if (value !== null) {
                        setFilterParameters(filters => {
                            const newFilters = { ...filters };
                            newFilters[Number.MAX_SAFE_INTEGER] = {
                                filterType: value,
                                filterKeys: [],
                                matchType: null,
                                value: ""
                            };
                            // console.log('first newFilters', newFilters);
                            return newFilters;
                        });
                    } else {
                        // console.log('delete');
                        setFilterElements(filters => {
                            const newFilters = [...filters];
                            newFilters[Number.MAX_SAFE_INTEGER] = <></>;
                            return newFilters;
                        });
                        setFilterParameters(filters => {
                            const newFilters = { ...filters };
                            delete newFilters[Number.MAX_SAFE_INTEGER];
                            return newFilters;
                        });
                    }

                }}
                renderInput={(params) =>
                    <PortalTooltip placement="top-start" title={"Select the type of filter you want to use. This can be to identify your geo data as boundarys, zones, or points, or to exclude certain files that aren't needed"}>
                        <TextField {...params} label="Select File Types" variant="outlined" />
                    </PortalTooltip>}
                options={FilterTypes}
            />
        </div>



        {/* Make filterCount instances of KeyGenerationFileFilter */}
        {...filterElements}

        {/* Button to add KeyGenerationFilter instances */}
        <PortalTooltip title="Add tags to sort your geo data into boundaries, points, and zones">
            <Button onClick={() => setFilterElements(filters => {
                const myIndex = filters.length;
                const newFilters = [...filters];
                newFilters.push(<KeyGenerationFileFilter
                    options={props.options}
                    optionTooltips={props.optionTooltips}
                    updateFilter={(filterType, filterKey, matchType, value) => {
                        // console.log(filterType, filterKey, matchType, value)
                        setFilterParameters(filters => {
                            const newFilters = { ...filters };
                            newFilters[myIndex] = {
                                filterType,
                                filterKeys: filterKey,
                                matchType,
                                value
                            };
                            // console.log('newFilters', newFilters);
                            return newFilters;
                        });
                        // console.log('filterParameters', filterParameters);
                    }}
                    removeSelf={() => {
                        setFilterElements(filters => {
                            const newFilters = [...filters];
                            newFilters[myIndex] = <></>;
                            return newFilters;
                        });
                        setFilterParameters(filters => {
                            const newFilters = { ...filters };
                            delete newFilters[myIndex];
                            return newFilters;
                        });
                    }}
                />);
                // console.log(newFilters.length);
                return newFilters;
            })}>Add Advanced Filter
            </Button>
        </PortalTooltip>
        <ListOfFiles files={displayFiles.length ? displayFiles : props.inputFiles} truncate={3} />
    </>
}