import { Box, Drawer, DrawerProps, List, ListItem, ListItemButton, ListItemText } from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";
import { UserContext } from "../hooks/UserContext";
import { AppVersion } from "./helpers/AppVersion";
import { API } from "../api";
import { AuthUser } from "../api/common";
import { setJwtToken, setRefreshToken } from "../utilities/utils";

interface ItemBreakProps {
    style?: React.CSSProperties;
}

const ItemBreak = React.memo(function ItemBreak(props: ItemBreakProps) {
    return (
        <ListItem style={{ borderBottomStyle: 'solid', borderBottomColor: '#d3d3d3', borderBottomWidth: '1px', ...props.style }} />
    )
})

function logout() {
    setJwtToken('');
    setRefreshToken('');
    localStorage.clear();
    sessionStorage.clear();
    window.location.href = "/login";
}

export type NavLink = {
    to: string;
    text: string;
}
interface DrawerNavigationProps extends DrawerProps {
    onNavigate: (link: NavLink) => void;
    setNavigationDrawerState: (open: boolean) => void;
}

export const DrawerNavigation = (props: DrawerNavigationProps) => {
    const navigate = useNavigate();
    const userContext = React.useContext(UserContext) as AuthUser | undefined;
    const links: NavLink[] = []
    if (!userContext?.id) {
        links.push({ to: '/login', text: 'Login' });
    } else {
        links.push({ to: '/home', text: 'Home' });
        links.push({ to: '/jobs/dashboard', text: 'Jobs' });
        links.push({ to: '/jobs/submit', text: 'Submit Jobs' });
        links.push({ to: '/jobs/ready', text: 'Mark Jobs Ready' });
        if (userContext.isRogoEmployee) links.push({ to: '/tools/sortshp', text: 'Reorganize Shapefiles' });
    }

    const { setNavigationDrawerState, onNavigate, ...drawerProps } = props;
    return (
        <Drawer style={{ zIndex: 1000 }} anchor="left" variant="temporary" {...drawerProps} >
            <Box //style={{ marginTop: 80 }} 
                sx={{ width: 250 }}
                role="presentation"
                flexDirection={'column'}
            >
                <List>
                    {links.map((link) => (
                        <ListItem key={link.text} disablePadding>
                            <ListItemButton onClick={() => {
                                props.onNavigate(link);
                                navigate(link.to, {
                                    state: { title: link.text }
                                })
                            }
                            }>
                                <ListItemText primary={link.text} />
                            </ListItemButton>
                        </ListItem>
                    ))}
                    { userContext?.id &&
                        <ListItem key={'logout'} disablePadding>
                            <ListItemButton onClick={logout}>
                                <ListItemText primary={"Logout"} />
                            </ListItemButton>
                        </ListItem>
                    }
                    <ItemBreak />
                    <ListItem key={'version'}>
                        <ListItemText primary={<AppVersion />} />
                    </ListItem>
                    { userContext?.isAdmin &&
                        <ListItem key={'clear-cache'} disablePadding>
                            <ListItemButton onClick={API.Data.clearCache}>
                                <ListItemText primary={"CLEAR CACHE"} />
                            </ListItemButton>
                        </ListItem>
                    }
                </List>
            </Box>
        </Drawer>
    );
};
