import {
    Box,
    Button,
    Typography,
    Grid,
    Card,
    Accordion
} from "@mui/material";
import AccordianSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import JSZip from "jszip";
import { useCallback, useContext, useState } from "react";
import { Importer, ImporterField } from "react-csv-importer";
import { toast } from "react-toastify";
import { BoundaryChangeType, BoundaryChangeTypes, CSVImportRows, GeoFileType, JobsPriority, JobsPriorityOvrd, KeyedShapefile, KeyedShapefileMap, RogoFeatures } from "../../../utilities/types";
import { ListOfFiles } from "./ListOfFiles";
import { UserContext } from "../../../hooks/UserContext";
import { SHP } from "../../../geojson_converters/shp";
import { KeyGenerationSelector } from "./KeyGenerationSelector";
import { flattenArrayObjects } from "../../../utilities/utils";
import JobImportTable from "../JobImportTable";
import { useDropzone } from 'react-dropzone'
import { styled } from '@mui/material/styles';
import { read, utils, WorkSheet } from 'xlsx';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { FeatureCollection } from "geojson";
import { Branch, Company, Deal, Job } from "../../../api";
import { Oops } from '../../../utilities/oops';
import { Add, Download, Launch, NotInterested, Remove } from "@mui/icons-material";
import { DownloadFile } from "../../../utilities/downloadFile";
import { FileTaggingAndSplitting } from "./FileTaggingAndSplitting";

const fgColor = '#000';
const fillColor = '#f0f0f0';
const borderRadius = '0.4em';
const textColor = '#202020';

function darken(color: string, percentage: number) {
    const num = parseInt(color.replace('#', ''), 16),
        amt = Math.round(2.55 * percentage),
        R = (num >> 16) - amt,
        B = ((num >> 8) & 0x00FF) - amt,
        G = (num & 0x0000FF) - amt;
    return "#" + (0x1000000 + (R < 255 ? R < 1 ? 0 : R : 255) * 0x10000 + (B < 255 ? B < 1 ? 0 : B : 255) * 0x100 + (G < 255 ? G < 1 ? 0 : G : 255)).toString(16).slice(1);
}

const CSVImporter_FileSelector = styled('div')(({ theme }) => ({
    border: `0.25em dashed ${fgColor}`,
    padding: '4em',
    borderRadius: borderRadius,
    background: fillColor,
    textAlign: 'center',
    color: textColor,
    cursor: 'pointer',
    '&[data-active="true"]': {
        background: darken(fillColor, 10),
        transition: 'background 0.1s ease-out',
    },
}));

// async function processShapeFiles(files: File[]) {
//     const fileMap: Record<string, File[]> = {};
//     async function processDir(dir: JSZip.JSZipObject, path: string) {
//         // console.log('processDir', path, dir.name);
//     }
//     // recursively search all directories for relevant shapes, folders, or files
//     async function processZip(file: File, path: string) {
//         const zip = await JSZip.loadAsync(file);
//         await Promise.all(Object.keys(zip.files).map(async (key) => {
//             const zipEntry = zip.file(key);
//             if (!zipEntry) return;

//             const entryName = zipEntry.name;

//             if (entryName.includes("__MACOSX")) return;

//             if (zipEntry.dir) {
//                 processDir(zipEntry, path);
//             } else if (
//                 entryName.endsWith('.shp') ||
//                 entryName.endsWith('.shx') ||
//                 entryName.endsWith('.dbf') ||
//                 entryName.endsWith('.prj')
//             ) {
//                 // strip extension from path
//                 const lastPeriod = entryName.lastIndexOf('.');
//                 const filenameWithoutExtension = entryName.substring(0, lastPeriod);
//                 // console.log('filenameWithoutExtension', filenameWithoutExtension);
//                 const fullPath = `${path}/${filenameWithoutExtension}`;
//                 if (!fileMap[fullPath]) {
//                     fileMap[fullPath] = [];
//                 }
//                 fileMap[fullPath].push(new File([await zipEntry.async('blob')], entryName));
//             } else if (entryName.endsWith('.zip')) {
//                 await processZip(new File([await zipEntry.async('blob')], entryName), `${path}/${entryName}`);
//             }
//         }));
//     }
//     for (const file of files) {
//         // console.log(`Processing ${file.name}`, fileMap);
//         if (file.name.endsWith(".zip")) {
//             await processZip(file, file.name);
//         } // TODO other formats?
//     }

//     // console.log('fileMap', fileMap);

//     return fileMap;

//     // if (event.target.files) {
//     //     // @ts-ignore

//     //     //setBoundaryFiles([...event.target.files]);
//     // }
// }


interface CSVImportFormProps {
    selectedDeals: Deal[];
    selectedBranches: Branch[];
    selectedCompany: Company | null;
    setSelectableFiles: (groupKey: string, selectableFiles: File[]) => void;
    deals: Deal[];
}

export function CSVImportForm(csvProps: CSVImportFormProps) {
    // console.log('CSVImportForm render');
    const userContext = useContext(UserContext);
    const [transformedKeyedShapefileMap, setTransformedKeyedShapefileMap] = useState<KeyedShapefileMap>(new Map());
    const [loading, setLoading] = useState(false);
    const [jobData, setJobData] = useState<Job[]>([]);
    const [selectableFiles, setSelectableFiles] = useState<Record<string, Record<GeoFileType, File[]>>>({});
    const [originalShapefiles, setOriginalShapefiles] = useState<File[]>([]);
    const [keyedShapefileMap, setKeyedShapefileMap] = useState<KeyedShapefileMap>(new Map());
    // TODO currently set to 3rd party to use the importer, would like to go back and add auto import ability
    const [csvImportType, setCsvImportType] = useState<'ROGO' | '3rdParty'>('3rdParty');



    const importCSV = async (rows: CSVImportRows[]) => {
        const results: Job[] = [];

        const deal = csvProps.selectedDeals[0];
        let ignoredRows = 0;

        for (const row of rows) {
            if (rows.length === 1) {
                const row = rows[0];
                if ((row['growerName'] === undefined) || row['growerName'].includes('\n') || row['growerName'] === '') {
                    ignoredRows++;
                    break;
                }
                if ((row['fieldName'] === undefined) || row['fieldName'].includes('\n') || row['fieldName'] === '') {
                    ignoredRows++;
                    break;
                }
            }

            // console.log('row', row);
            if (!row.growerName && !row.fieldName) {
                ignoredRows++;
                continue;
            }


            // 2.5grid_RO+Zn_8in
            let dealId = row.deal ? undefined : deal.id;
            let density = row.gridSize ? parseFloat(row.gridSize) : deal.density;
            const testPackage = row.testPackage || deal.testPackage;
            const depth = row.depth ? parseFloat(row.depth) : deal.depth;
            const micros = row.freqOfMicros ? parseFloat(row.freqOfMicros) : deal.frequencyOfMicros;
            // console.log(micros, "micros")

            if (row.fieldPriority && !JobsPriorityOvrd.includes(row.fieldPriority as JobsPriority)) {
                toast.error(`Invalid priority: ${row.fieldPriority}`, { autoClose: 10000 });
                throw new Error(`Invalid priority: ${row.fieldPriority}`);
            }
            if (row.boundaryChange && !BoundaryChangeTypes.includes(row.boundaryChange as BoundaryChangeType)) {
                toast.error(`Invalid boundary change type: ${row.boundaryChange}`, { autoClose: 10000 });
                throw new Error(`Invalid boundary change type: ${row.boundaryChange}`);
            }

            const newJob: Job = {
                ...row,
                branchIds: csvProps.selectedBranches.length ? [csvProps.selectedBranches[0].id] : [],
                boundaryChangeType: !row.boundaryChange ? undefined : row.boundaryChange as BoundaryChangeType | undefined,
                dealIds: dealId ? [dealId] : [],
                depth: depth || 0,
                externalId: '',
                fieldId: row.fieldID || '',
                fieldPriority: (row.fieldPriority as JobsPriority) || 'Regular Turn (default)',
                frequencyOfMicros: micros || undefined,
                density: density || 0,
                growerPhoneNumber: row.growerCell || '',
                jobFlags: [row.jobFlags || 'Bulk Upload'],
                testPackage: testPackage,
                labSubmissionCode: row.regularLabSubmissionCode || '',
                isPointsAttached: ["y", "yes", "true"].includes(row.pointsAttached?.toLowerCase() || "No"),
                isReadyToSample: ["y", "yes", "true"].includes(row.ready?.toLowerCase() || "No"),
                labInstructions: row.specialLabInstructions || '',
                submitterEmail: row.submitterEmail ?? userContext?.email ?? "",
                submitterName: row.submitterName ?? userContext?.full_name ?? "",
                eventId: row.syncCode,
                id: Math.random().toString(36).substring(7),

                // default values
                growerIds: [],
                name: "",
                edits: "",
                submitter: "",
                season: "",
                sampleChanges: "",
                labSubmittalId: "",
                companyName: "",
                labName: "",
                boundaryAcres: 0,
                status: "Pending",
                isBillingApproved: false,
                isBoundaryApproved: false,
                isBoundaryChanged: false,
                isMarkedReady: false,
                isPointsChanged: false,
                isTestJob: false,
                dateCreated: new Date(),
                attachments: {
                    pointsSHP: [],
                    boundarySHP: [],
                    boundaryGeoJSON: [],
                    zonesSHP: [],
                    executedPointsSHP: [],
                    executedBoundarySHP: [],
                    executedPointsBoundarySHP: [],
                    labPDF: [],
                    labCSV: [],
                    fieldNotes: []
                },
                files: {
                    pointsSHP: undefined,
                    boundarySHP: undefined,
                    boundaryGeoJSON: undefined,
                    zonesSHP: undefined,
                    executedPointsSHP: undefined,
                    executedBoundarySHP: undefined,
                    executedPointsBoundarySHP: undefined,
                    labPDF: undefined,
                    labCSV: undefined,
                    fieldNotes: undefined
                }
            };
            results.push(newJob);
        }

        // console.log("results", results);

        // checks if data should be added to jobData
        if (results.length != 0) {
            setJobData(prevData => [...prevData, ...results]);
            // console.log('after setJbData', jobData)

            toast.success(`Parsed ${results.length} rows`);
        }

        if (ignoredRows > 0) {
            toast.warn(`Ignored ${ignoredRows} rows with no grower or field name`);
        }
    };

    function CSVDropzone() {
        const onDrop = useCallback(async (acceptedFiles: File[]) => {
            // Do something with the files
            // iterate through all files
            for (const file of acceptedFiles) {
                const wb = read(await file.arrayBuffer());

                let selectedSheet: WorkSheet | null = null;
                let seletedSheetName: string | null = null;
                for (const sheetName of wb.SheetNames) {
                    if (wb.SheetNames.length === 1 || sheetName.toLowerCase().includes('csv download tab')) {
                        selectedSheet = wb.Sheets[sheetName];
                        seletedSheetName = sheetName;
                        break;
                    }
                }

                if (!selectedSheet) {
                    toast.error('Unable to determine correct sheet');
                    return;
                }

                const rows = utils.sheet_to_json(selectedSheet);

                // console.log(seletedSheetName, selectedSheet, rows);
            }
        }, [])
        const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop })

        return (
            <CSVImporter_FileSelector {...getRootProps()}>
                <input {...getInputProps()} />
                {
                    isDragActive ?
                        <p>Drop the files here ...</p> :
                        <p>Drag 'n' drop some files here, or click to select files</p>
                }
            </CSVImporter_FileSelector>
        )
    }

    async function downloadBulkUploadTemplate(){
        await DownloadFile.fromURL("https://airtableuploads.s3.us-east-2.amazonaws.com/bulk_upload_template.csv", "Bulk Upload Template.csv");
    }

    return <>
        <Grid alignItems="stretch" alignContent="stretch" container maxWidth="md" margin="auto" spacing={1}>
            {loading && <Grid item xs={12}><Typography>Loading...</Typography></Grid>}
            <Grid item xs={12} width={'100%'}>
                {csvImportType === '3rdParty' && 
                    <Importer<CSVImportRows>
                        skipEmptyLines={false}
                        dataHandler={importCSV}
                        defaultNoHeader={false}
                        restartable={true}
                        chunkSize={10} >
                        <ImporterField name="growerName" label="Grower from Form" />
                        <ImporterField name="growerEmail" label="Grower Email" optional />
                        <ImporterField name="growerCell" label="Grower Cell Phone Number" optional />
                        <ImporterField name="fieldID" label="Field ID" optional />
                        <ImporterField name="fieldName" label="Field from Form" />
                        <ImporterField name="farmName" label="Farm from Form" optional />
                        <ImporterField name="syncCode" label="Event ID" optional />
                        <ImporterField name="locationFromForm" label="Location from Form" optional />
                        <ImporterField name="depth" label="Depth from Form" optional />
                        <ImporterField name="labName" label="Lab from Form" optional />
                        <ImporterField name="gridSize" label="Grid from Form" optional />
                        <ImporterField name="testPackage" label="3rd Party Lab Test Package ID" optional />
                        <ImporterField name="ready" label="Field Ready at Submission?" optional />
                        <ImporterField name="fieldPriority" label="Priority OVRD" optional />
                        <ImporterField name="submissionNotes" label="Submission Notes" optional />
                        <ImporterField name="labAccountNumber" label="Lab Account Number" optional />
                        <ImporterField name="freqOfMicros" label="Frequency of Micros" optional />
                        <ImporterField name="submitterName" label="Submitter" optional />
                        <ImporterField name="submitterEmail" label="Submitter Email" optional />
                        <ImporterField name="pointsAttached" label="Retest? (Pts Submitted by Customer)" optional />
                        <ImporterField name="boundaryChange" label="Boundary Change Type" optional />
                        <ImporterField name="jobFlags" label="Job Flags" optional />
                        <ImporterField name="specialLabInstructions" label="Lab Instructions" optional />
                        <ImporterField name="nextCrop" label="Next Crop" optional />
                        <ImporterField name="lastCrop" label="Last Crop" optional />
                        <ImporterField name="specsFromForm" label="Specs from Form" optional />
                        <ImporterField name="regularLabSubmissionCode" label="Regular Lab Submission Code" optional />
                    </Importer>
                }

                {csvImportType === 'ROGO' && <CSVDropzone />}
            </Grid>
            { (jobData.length === 0) &&
                <Grid item width={'100%'}>
                    <Grid container spacing={1} justifyContent="center">
                        <Grid item>
                            <Button variant="outlined" target="_blank" href="https://docs.google.com/spreadsheets/d/1O5G7LBH_g67CSwDsDtZugKsIYYOzEIjUnOVzqgPQ6OQ/edit?usp=sharing">
                                <Launch className="icon-in-button" />Open Template (Google Sheets)
                            </Button>
                        </Grid>
                        <Grid item>
                            <Button variant="outlined" onClick={downloadBulkUploadTemplate}>
                                <Download className="icon-in-button" />Download Template (CSV)
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>
            }
            {jobData.length > 0 && <>
                <Button style={{ width: '100%', marginTop: 10, marginLeft: 8, marginBottom: 10 }} variant='contained' onClick={() => {
                    setTransformedKeyedShapefileMap(new Map());
                    setLoading(false);
                    setJobData([]);
                    setSelectableFiles({});
                    setOriginalShapefiles([]);
                    setKeyedShapefileMap(new Map());
                    setCsvImportType('3rdParty');
                }}>
                    <NotInterested className="icon-in-button" />Remove CSV file(s)
                </Button>
                <FileTaggingAndSplitting 
                    keyedShapefileMap={keyedShapefileMap} 
                    setKeyedShapefileMap={setKeyedShapefileMap}
                    selectableFiles={selectableFiles}
                    setSelectableFiles={setSelectableFiles} 
                    originalShapefiles={originalShapefiles}
                    setOriginalShapefiles={setOriginalShapefiles}
                    loading={loading}
                    setLoading={setLoading}
                />
            </> }
        </Grid>
        {
            (jobData.length > 0) &&
            <JobImportTable
                jobs={jobData}
                updateJobs={(jobs) => { setJobData(jobs) }}
                deals={csvProps.selectedDeals}
                allDeals={csvProps.deals}
                setDrawerState={() => { }}
                setLoading={(task: string | boolean) => { setLoading(!!task) }}
                allOptions={flattenArrayObjects(Object.values(selectableFiles))}
                resetForm={() => {
                    setJobData([]);
                    setOriginalShapefiles([]);
                    setSelectableFiles({});
                }}
                isProagrica={false}
            />
        }
    </>;
}

// export const MemoizedCSVImportForm = React.memo(CSVImportForm);