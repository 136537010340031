import { FieldSet } from 'airtable';
import { FieldIdMapping, FilterFieldIds, getBaseDataURL } from '../../../common';
import { BaseDataModel } from '../../../types/data_models';
import { CreateAPI_Base } from './create';
import { GetAPI_Base } from './get';
import { UpdateAPI_Base } from './update';

export class DataAPI_Base<T extends FieldSet, U extends FieldIdMapping, V extends BaseDataModel<T>> {
    private router: string;
    protected baseUrl: string;
    public create: CreateAPI_Base<T, V>;
    public get: GetAPI_Base<T, V>;
    public update: UpdateAPI_Base<T, U, V>;

    constructor(router: string, fieldMapping: any, fieldIds: FilterFieldIds | undefined = undefined, isCompressed: boolean = false) {
        this.router = router;
        this.baseUrl = getBaseDataURL(router);
        this.create = new CreateAPI_Base<T, V>(this.router);
        this.get = new GetAPI_Base<T, V>(this.router, fieldMapping, fieldIds, isCompressed);
        this.update = new UpdateAPI_Base<T, U, V>(this.router, fieldMapping);
    }
}

export * from './create';
export * from './get';
export * from './update';