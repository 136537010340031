import shpwrite, { DownloadOptions, ZipOptions } from '@mapbox/shp-write';
import { Converter } from "./converter";
import shp from 'shpjs';
import { Feature } from 'geojson';
import { Oops } from '../utilities/oops';

export const SHP: Readonly<Converter<Buffer | ArrayBuffer>> = {
    async toGeoJSON(data: Buffer | ArrayBuffer): Promise<GeoJSON.FeatureCollection> {
        try {
            const shpGeoJSONData = await shp(data);
            if (Array.isArray(shpGeoJSONData)) {
                // combine all the feature collections into one
                const featureCollection: GeoJSON.FeatureCollection = {
                    type: 'FeatureCollection',
                    features: []
                };
                shpGeoJSONData.forEach((fc: GeoJSON.FeatureCollection) => {
                    featureCollection.features = featureCollection.features.concat(fc.features);
                });
                return featureCollection;
            } else {
                return {
                    type: 'FeatureCollection',
                    features: shpGeoJSONData.features
                };
            }
        } catch (error: any) {
            Oops.handleError(error);
            return {
                type: 'FeatureCollection',
                features: []
            };
        }
    },

    async fromGeoJSON(geojson: GeoJSON.FeatureCollection | GeoJSON.Feature, id?: string): Promise<Buffer> {
        // console.log(geojson);
        const options: DownloadOptions & ZipOptions = {
            types: {
                polygon: id ? `${id}_bnd` : 'poly',
                point: id ? `${id}_pts` : 'pts',
            },
            compression: 'DEFLATE',
            outputType: 'nodebuffer'
        };
        let features = 'type' in geojson && geojson.type === 'Feature' ? [geojson] : geojson.features;
        const splitFeatures = features.map(feature => {
            if (feature.geometry.type === 'Polygon') {
                // return as multipolygon
                return {
                    type: 'Feature',
                    properties: feature.properties,
                    geometry: {
                        type: 'MultiPolygon',
                        coordinates: [feature.geometry.coordinates]
                    }
                } as Feature;
            }
            return feature;
        });
        const newGeoJSON: GeoJSON.FeatureCollection = {
            type: 'FeatureCollection',
            features: splitFeatures
        };
        return await shpwrite.zip<"nodebuffer">(newGeoJSON, options);
    }
}