import axios from 'axios';
import { FieldSet } from 'airtable';
import { REQUEST_CONFIG_AUTH, FieldIdMapping, getCompressed, Filter, FilterFieldIds, FilterField, getBaseDataURL, parseDates } from '../../../common';
import { BaseDataModel } from '../../../types/data_models';

export class GetAPI_Base<T extends FieldSet, U extends BaseDataModel<T>> {
    protected baseUrl: string;
    protected fieldMapping: FieldIdMapping;
    private isCompressed: boolean = false;
    private filterFieldIds: FilterFieldIds | undefined;

    constructor(router: string, fieldMapping: FieldIdMapping, filterFieldIds: FilterFieldIds | undefined = undefined, isCompressed: boolean = false) {
        this.baseUrl = getBaseDataURL(router);
        this.fieldMapping = fieldMapping;
        this.filterFieldIds = filterFieldIds;
        this.isCompressed = isCompressed;
    }

    private async _all(airtableFormula?: string, viewId?: string, isCompressed: boolean = this.isCompressed): Promise<U[]> {
        let queryParams: string[] = [];
        if (airtableFormula) queryParams.push(`airtable_formula=${encodeURIComponent(airtableFormula)}`);
        if (viewId) queryParams.push(`view_id=${encodeURIComponent(viewId)}`);
        if (isCompressed) queryParams.push(`compressed=true`);
        let queryString = queryParams.length ? `?${queryParams.join('&')}` : "";

        let url = `${this.baseUrl}${queryString}`;

        if (isCompressed) {
            var records = await getCompressed(url) as U[];
            parseDates<T, U>(records);
            return records;
        } else {
            const response = await axios.get(url, REQUEST_CONFIG_AUTH());
            var records = response.data as U[];
            parseDates<T, U>(records);
            return records;
        }
    }

    private async _byFormula(airtableFormula: string, isCompressed: boolean = this.isCompressed): Promise<U[]> {
        return this._all(airtableFormula, undefined, isCompressed);
    }
    
    public async byFilter(filter: Filter) {
        if (!filter) return [];
        const fieldIds = this.filterFieldIds;
        const airtableFormula = convertFilterToAirtableFormula(filter);
        return this._byFormula(airtableFormula);

        function convertFilterToAirtableFormula(filter: Filter): string {
            if ('fields' in filter && Array.isArray(filter.fields)) {
                let airtableFormula = filter.type ? `${filter.type}(` : ''; // AND( or OR(
                let filters = filter.fields.map((field) => {
                    if ('type' in field) {
                        return convertFilterToAirtableFormula(field as Filter);
                    } else {
                        const f = field as FilterField;
                        if (f.id) 
                            return `RECORD_ID()='${f.id}'`; // e.g. RECORD_ID()='rec1jHciQjoe1N8PY'
                        if (fieldIds) {
                            if (f.companyName && fieldIds.companyName) 
                                return stringEquals(fieldIds.companyName, f.companyName);
                            if (f.season && fieldIds.season) 
                                return stringEquals(fieldIds.season, f.season);
                            if (f.configOnly != undefined && fieldIds.configOnly) 
                                return booleanEquals(fieldIds.configOnly, f.configOnly);
                            if (f.active != undefined && fieldIds.active) 
                                return booleanEquals(fieldIds.active, f.active);
                        }
                        return '';
                    }
                }).filter(Boolean);
                airtableFormula += filters.join(',');
                airtableFormula += filter.type ? ')' : '';
                return airtableFormula;
            }
            return '';
    
            function stringEquals(fieldId: string, value: string | number | boolean) {
                return `{${fieldId}}='${value}'`; // e.g. {fldKLv7VnIeQF02vq}='Rogo AG'
            }
            function booleanEquals(fieldId: string, value: boolean) {
                return `{${fieldId}}=${value ? "TRUE()" : "FALSE()"}`; // e.g. {fldKLv7VnIeQF02vq}='TRUE()'
            }
        }
    }

    public async byViewId(viewId: string, isCompressed?: boolean): Promise<U[]> {
        return this._all(undefined, viewId, isCompressed);
    }

    public async byIds(ids: string[]): Promise<U[]> {
        const filter = {
            type: 'OR',
            fields: ids.map((id) => ({ id: id } as FilterField)
        )} as Filter;

        var records = await this.byFilter(filter);
        parseDates<T, U>(records);
        return records;
    }

    public async byId(id: string, isCompressed: boolean = this.isCompressed): Promise<U> {
        let queryParams: string[] = [];
        if (isCompressed) queryParams.push(`compressed=true`);
        let queryString = queryParams.length ? `?${queryParams.join('&')}` : "";

        let url = `${this.baseUrl}/${id}${queryString}`;

        if (isCompressed) {
            const record = await getCompressed(url);
            if (Array.isArray(record)) {
                var r = record[0] as U;
                parseDates<T, U>([r]);
                return r;
            } else {
                const r = record as U;
                parseDates<T, U>([r]);
                return r;
            }
        } else {
            const response = await axios.get(url, REQUEST_CONFIG_AUTH());
            const record = response.data as U;
            parseDates<T, U>([record]);
            return record;
        }
    }
}
