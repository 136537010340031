import { Grid, Autocomplete, TextField } from "@mui/material";
import { FieldSet } from "airtable";
import PortalTooltip, { MUITooltipPlacement } from "./PortalTooltip";
import { BaseDataModel } from "../../api";

interface Props<T extends FieldSet, U extends BaseDataModel<T>> {
    onChange: (value: U | null) => void | Promise<void>;
    loading?: boolean;
    options: U[];
    selectedOption: U | null;
    label: string;
    optionFormatter?: (option: U) => string;
    tooltip?: React.ReactNode;
    size?: "small" | "medium" | undefined;
    style?: React.CSSProperties;
    placement?: MUITooltipPlacement;
    id?: string;
    className?: string;
    optionLabelField?: keyof U;
}

export default function F<T extends FieldSet, U extends BaseDataModel<T>>(
    {
        onChange,
        loading,
        options,
        selectedOption,
        label,
        optionFormatter,
        tooltip,
        size,
        style,
        placement,
        id,
        className,
        optionLabelField = "name" as keyof U
    }: Props<T, U>) {
    return <Grid item xs={12}>
        <Autocomplete
            id={id}
            className={className}
            style={style}
            disabled={!!loading}
            options={options}
            value={selectedOption}
            getOptionKey={(option) => option.id}
            getOptionLabel={(option) => optionFormatter ? optionFormatter(option) : option[optionLabelField]?.toString() ?? ""}
            size={size ?? "medium"}
            isOptionEqualToValue={(option, value) => option.id === value.id}
            onChange={async (event, value) => {
                event.stopPropagation();
                await onChange(value)
            }}
            renderInput={(params) => {
                return tooltip ?
                    <PortalTooltip title={tooltip} placement={placement}>
                        <TextField {...params} label={label} variant="outlined" />
                    </PortalTooltip> :
                    <TextField {...params} label={label} variant="outlined" />
            }}
        />
    </Grid >
}