import { Button, MenuItem, Paper, Select, TextField } from "@mui/material";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { RHFAutocompleteField } from "./RHFAutocompleteField";
import { CustomerReadyPriority, CustomerReadyPriorityOptions } from "../../utilities/types";
import { API, Job, JobReady } from "../../api";
import * as Sentry from '@sentry/react';
import { toast } from "react-toastify";

export interface Props {
    jobs: Job[];
    intialSelectedJobs: Job[];
    onSubmit: (data: Inputs) => void;
    onCancel: () => void;
    showCancel?: boolean;
}

interface Inputs {
    selectedJobs: Job[];
    submissionNotes: string;
    fieldPriority: CustomerReadyPriority;
}

export function MarkJobReadyForm(props: Props) {
    const [jobs, setJobs] = useState(props.jobs);

    useEffect(() => {
        setJobs(props.jobs);
    }, [props.jobs]);

    const {
        register,
        watch,
        handleSubmit,
        control,
        reset,
        formState: { errors },
    } = useForm<Inputs>({
        defaultValues: {
            selectedJobs: props.intialSelectedJobs,
            submissionNotes: "",
            fieldPriority: "Regular Turn"
        }
    })

    const jobsSelected = watch("selectedJobs")?.length || 0;
    return (
        <Paper style={{ padding: 10, marginTop: 10 }}>
            <form>
                <RHFAutocompleteField
                    name="selectedJobs"
                    multiple
                    options={jobs.sort((a, b) => {
                        return a.growerName.localeCompare(b.growerName);
                    })}
                    groupBy={(job) => {
                        return job?.growerName ?? "No Grower";
                    }}
                    placeholder="Select Jobs"
                    isOptionEqualToValue={(option, value) => option?.id === value?.id}
                    getId={(job) => job.id}
                    control={control}
                    getLabel={(job) => {
                        if (job) {
                            let label = job.growerName ?? "No Grower";
                            const farm = job.farmName;
                            if (farm) {
                                label += ` - ${farm}`;
                            }
                            const field = job.fieldName;
                            if (field) {
                                label += ` - ${field}`;
                            }
    
                            return label;
                        } else {
                            return "No Grower";
                        }
                    }}
                />
                {errors.selectedJobs && <span>This field is required</span>}
                <br />
                <TextField
                    multiline
                    title={"Submission Notes"}
                    label="Submission Notes"
                    {...register("submissionNotes")}
                />
                <br />
                <Select
                    fullWidth
                    value={watch("fieldPriority")}
                    {
                    ...register("fieldPriority", {
                        required: "Configuration is required",
                    })
                    }>
                    {(CustomerReadyPriorityOptions).map((priority, index) => {
                        return (<MenuItem key={index} value={priority}>{priority}</MenuItem>);
                    })}
                </Select>
                <br />


                {props.showCancel && (
                    <>
                        <Button onClick={props.onCancel}>
                            Cancel
                        </Button>
                        <br />
                    </>
                )}
                <Button
                    variant='contained'
                    onClick={handleSubmit((data) => {
                        reset();
                        props.onSubmit(data);
                    })}
                >
                    {`Mark ${jobsSelected} jobs as ready`}
                </Button>
            </form>
        </Paper>
    );
}

export async function submitJobReadyForm(fieldPriority: CustomerReadyPriority, submissionNotes: string, selectedJobs: Job[]) {
    if (selectedJobs) {
        if (selectedJobs.length > 0) {
            const jobsIds = selectedJobs.map(job => job.id);
            // @ts-ignore
            const jobReadyForm: JobReady = {
                name: "",
                jobIds: jobsIds,
                priority: fieldPriority,
                submissionNotes: submissionNotes,
                submitter: "",
            } as const;
        
            for (let i = 0; i < selectedJobs.length; i++) { // need to test this
                const job = selectedJobs[i];
                if (job.isMarkedReady) {
                    Sentry.captureMessage(`Job ${job.id} already marked as ready, but an additional Job Ready form is being attached`, "info");
                }
            }
        
            await API.Data.JobsReady.create.one(jobReadyForm);
        } else {
            toast.error('No jobs selected to mark as ready');
            throw new Error(`selectedJobs.length == 0`);
        }
    } else {
        toast.error('No jobs selected to mark as ready');
        throw new Error(`selectedJobs == undefined`);
    }
}