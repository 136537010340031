// #region IMPORTS
import { useContext, useEffect, useMemo, useState } from 'react';

//MRT Imports
import {
  MaterialReactTable,
  useMaterialReactTable,
  type MRT_ColumnDef,
  MRT_GlobalFilterTextField,
  MRT_ToggleFiltersButton,
  MRT_RowSelectionState,
  MRT_VisibilityState,
  MRT_ColumnOrderState,
  MRT_GroupingState,
  MRT_SortingState,
  MRT_ExpandAllButton,
  MRT_ExpandButton,
  MRT_ColumnFiltersState,
  MRT_ColumnFilterFnsState,
  MRT_ShowHideColumnsButton,
  MRT_ToggleDensePaddingButton,
  MRT_ToggleFullScreenButton,
  MRT_FilterFn,
  MRT_Row
} from 'material-react-table';

//Material UI Imports
import {
  Autocomplete,
  Box,
  Button,
  IconButton,
  Menu,
  MenuItem,
  Stack,
  TextField,
  Tooltip,
  lighten,
} from '@mui/material';

//Icons Imports
import {
  Delete,
  ContentCopy,
  Save,
  KeyboardDoubleArrowRight,
  RefreshOutlined,
  Download,
  Map,
  Check,
  MoreHoriz,
  HourglassBottom
} from '@mui/icons-material';
import { useSearchParams } from "react-router-dom";

import { ViewCollection, ViewDefinition, ViewGroup } from '../../utilities/types';
import { FeatureCollection, MultiPolygon, Point, Polygon } from 'geojson';
import { UserContext } from '../../hooks/UserContext';
import ViewSaveDialog from './ViewSaveDialog';
import CustomAutocomplete from '../helpers/CustomAutocomplete';
import { toast } from 'react-toastify';
//import useUrlState from '@ahooksjs/use-url-state';
import { MarkJobReadyPopover } from './MarkJobReadyPopover';
import { LoadingComponent } from '../helpers/LoadingComponent';
import { Props as ViewSaveDialogProps } from './ViewSaveDialog';

import * as Sentry from '@sentry/react';
import React from 'react';
import { API, Branch, Company, Job, Contact } from '../../api';
import { INVALID_USERCONTEXT } from '../../utilities/constants';
import { Oops } from '../../utilities/oops';
import { checkboxMultiselectColumn } from '../helpers/MRT/MultiSelectCheckboxColumn';
import { textColumn } from '../helpers/MRT/TextColumn';
import { multiSelectColumn } from '../helpers/MRT/MultiSelectTextColumn';
import { numericColumn } from '../helpers/MRT/NumericColumn';
import { dateColumn } from '../helpers/MRT/DateColumn';
import { checkboxColumn } from '../helpers/MRT/CheckboxColumn';
import { GeoJSON } from '../../utilities/utils';
import { IAirtableAttachment } from '@rogoag/airtable';
import { DownloadFile } from '../../utilities/downloadFile';
// #endregion

// #region FILTERS / VIEWS
const DEFAULT_FILTER_FNS: MRT_ColumnFilterFnsState = {
  "boundary Acres": 'between',
  "job Status": 'arrIncludes',
  "field": 'fuzzy',
  "farm": 'fuzzy',
  "test Package": 'arrIncludes',
  'edits': 'fuzzy',
  'files last edited?': 'greaterThanOrEqualTo',
  "submitter": 'fuzzy',
  "branch": 'fuzzy',
  'map issue': 'equals',
  "boundary Approved?": 'equals',
  "boundary Change?": 'equals',
  "points Change?": 'equals',
  "season": 'arrIncludes',
} as const;

const DEFAULT_FILTERS: MRT_ColumnFiltersState = [];
const DEFAULT_GROUPING: MRT_GroupingState = ["grower"];
const DEFAULT_COLUMN_SORT: MRT_SortingState = [];
const DEFAULT_VIS_STATE: MRT_VisibilityState = {};
const DEFAULT_COLUMN_ORDER: MRT_ColumnOrderState = [
  "mrt-row-select",
  "mrt-row-expand",
  "grower",
  "field",
  "farm",
  "job status",
  "boundary acres",
  "field ready date",
  "sample date",
  "creation date",
  "drop/ship date",
  "test package",
  "edits",
  "files last edited?",
  "submitter",
  "approved billing?",
  "lab sent results",
  "branch",
  "job flags",
]

const PARAM_SEPARATOR = '|';

const dashboardStateToURLParams = (state: DashboardURLState): URLSearchParams => {
  const params = new URLSearchParams()
  const {
    columnVisibility,
    sorting,
    grouping,
    columnOrder,
    columnFilterFunctions,
    columnFilterValues
  } = state;
  const urlSorting = sorting.map(sort => `${sort.id}:${sort.desc ? 'desc' : 'asc'}`).join(PARAM_SEPARATOR);
  const urlGrouping = grouping.join(PARAM_SEPARATOR);
  const urlColumnOrder = columnOrder.join(PARAM_SEPARATOR);
  const urlColumnVisibility = Object.keys(columnVisibility).map(key => `${key}=${columnVisibility[key]}`).join(PARAM_SEPARATOR);
  const urlFunctions = Object.keys(columnFilterFunctions).map(key => `${key}=${columnFilterFunctions[key]}`).join(PARAM_SEPARATOR);
  const urlFilterValues = columnFilterValues.map(filter => `${filter.id}:${filter.value}`).join(PARAM_SEPARATOR);

  params.set('sort', urlSorting);
  params.set('group', urlGrouping);
  params.set('vis', urlColumnVisibility);
  params.set('order', urlColumnOrder);
  params.set('fns', urlFunctions);
  params.set('filter', urlFilterValues);

  params.sort();
  return params;
}

const DEFAULT_VIEW_NAME = 'Group by Season, Branch, Grower';

const DEFAULT_VIEWS: ViewCollection = [
  {
    name: DEFAULT_VIEW_NAME,
    group: ViewGroup.Default,
    url: dashboardStateToURLParams({
      columnFilterFunctions: DEFAULT_FILTER_FNS,
      columnFilterValues: DEFAULT_FILTERS,
      columnOrder: DEFAULT_COLUMN_ORDER,
      columnVisibility: {
        "grower": false,
        "branch": false,
        "season": false,
      },
      grouping: ["season", "branch", "grower"],
      sorting: DEFAULT_COLUMN_SORT,
    }).toString()
  },
  {
    name: 'Group by Status',
    group: ViewGroup.Default,
    url: dashboardStateToURLParams({
      columnFilterFunctions: DEFAULT_FILTER_FNS,
      columnFilterValues: DEFAULT_FILTERS,
      columnOrder: DEFAULT_COLUMN_ORDER,
      columnVisibility: {
        "job status": false,
      },
      grouping: ['job status'],
      sorting: DEFAULT_COLUMN_SORT,
    }).toString()
  },
  {
    name: 'Unready Jobs',
    group: ViewGroup.Default,
    url: dashboardStateToURLParams({
      columnFilterFunctions: DEFAULT_FILTER_FNS,
      columnFilterValues: [
        { id: "job status", value: '0. Unready' },
      ],
      columnOrder: DEFAULT_COLUMN_ORDER,
      columnVisibility: {
        "grower": true,
        "field": true,
        "farm": true,
        "job status": true,
        "test package": false,
        "field ready date": false,
        "sample date": false,
        "creation date": false,
        "drop/ship date": false,
        "edits": false,
        "files last edited?": false,
        "submitter": false,
        "approved billing?": false,
        "boundary": false,
        "lab sent results": false,
        "branch": false,
        "job flags": false,
        "points change?": true,
        "boundary change?": true,
        "boundary approved?": true,
      },
      grouping: ["grower"],
      sorting: DEFAULT_COLUMN_SORT,
    }).toString()
  },
  {
    name: 'Boundary Changes',
    group: ViewGroup.Default,
    url: dashboardStateToURLParams({
      columnFilterFunctions: DEFAULT_FILTER_FNS,
      columnFilterValues: [
        { id: "boundary change?", value: true },
        { id: "boundary approved?", value: true },
      ],
      columnOrder: DEFAULT_COLUMN_ORDER,
      columnVisibility: {
        "grower": true,
        "field": true,
        "farm": true,
        "job status": true,
        "boundary acres": false,
        "field ready date": false,
        "sample date": false,
        "creation date": false,
        "drop/ship date": false,
        "test package": false,
        "edits": false,
        "files last edited?": false,
        "submitter": false,
        "approved billing?": false,
        "boundary": false,
        "lab sent results": false,
        "branch": false,
        "job flags": false,
        "points change?": true,
        "boundary change?": true,
        "boundary approved?": true,
      },
      grouping: ["grower"],
      sorting: DEFAULT_COLUMN_SORT,
    }).toString()
  },
  {
    name: 'Points Changes',
    group: ViewGroup.Default,
    url: dashboardStateToURLParams({
      columnFilterFunctions: DEFAULT_FILTER_FNS,
      columnFilterValues: [
        { id: "points change?", value: true },
      ],
      columnOrder: DEFAULT_COLUMN_ORDER,
      columnVisibility: {
        "grower": true,
        "field": true,
        "farm": true,
        "job status": true,
        "boundary acres": false,
        "field ready date": false,
        "sample date": false,
        "creation date": false,
        "drop/ship date": false,
        "test package": false,
        "edits": false,
        "files last edited?": false,
        "submitter": false,
        "approved billing?": false,
        "boundary": false,
        "lab sent results": false,
        "branch": false,
        "job flags": false,
        "points change?": true,
        "boundary change?": true,
        "boundary approved?": true,
      },
      grouping: ["grower"],
      sorting: DEFAULT_COLUMN_SORT,
    }).toString()
  }
];

interface DashboardURLState {
  sorting: MRT_SortingState;
  grouping: MRT_GroupingState;
  columnOrder: MRT_ColumnOrderState;
  columnVisibility: MRT_VisibilityState;
  columnFilterFunctions: MRT_ColumnFilterFnsState;
  columnFilterValues: MRT_ColumnFiltersState;
}


const urlParamsToDashboardState = (urlParams: URLSearchParams): DashboardURLState => {
  const rawSortParam = urlParams.get('sort');
  const rawGroupParam = urlParams.get('group');
  const rawOrderParam = urlParams.get('order');
  const rawVisParam = urlParams.get('vis');
  const rawFnsParam = urlParams.get('fns');
  const rawFilterParam = urlParams.get('filter');
  const columnFilterValues = rawFilterParam ? rawFilterParam.split(PARAM_SEPARATOR).map(filter => ({ id: filter.split(':')[0], value: filter.split(':')[1] })) : [];
  const viewState: DashboardURLState = {
    sorting: rawSortParam ? rawSortParam.split(PARAM_SEPARATOR).map(sort => ({ id: sort.split(':')[0], desc: sort.split(':')[1] === 'desc' })) : [],
    grouping: rawGroupParam ? rawGroupParam.split(PARAM_SEPARATOR) : [],
    columnOrder: rawOrderParam ? rawOrderParam.split(PARAM_SEPARATOR) : [],
    columnVisibility: rawVisParam ? rawVisParam.split(PARAM_SEPARATOR).reduce((acc: MRT_VisibilityState, entry) => {
      const [key, value] = entry.split('=');
      acc[key] = value === 'true';
      return acc;
    }, {}) : {},
    columnFilterFunctions: rawFnsParam ? rawFnsParam.split(PARAM_SEPARATOR).reduce((acc: MRT_ColumnFilterFnsState, entry) => {
      const [key, value] = entry.split('=');
      acc[key] = value;
      return acc;
    }, {}) : {},
    columnFilterValues
  };
  return viewState;
}
// #endregion

// #region COMPONENT
interface Props {
  setDrawerOpenState: (open: boolean) => void;
  setDisplayBoundaries: (boundaries: Record<string, FeatureCollection<Polygon> | FeatureCollection<MultiPolygon>>) => void;
  setDisplayedZones: (zones: Record<string, FeatureCollection<Polygon> | FeatureCollection<MultiPolygon>>) => void;
  setDisplayedPoints: (points: Record<string, FeatureCollection<Point>>) => void;
  setLoading: (loading: boolean) => void;
  drawerOpen: boolean;
}

const isBetaPortal = window.location.hostname.toLowerCase().includes('portalbeta');

export function JobsDashboardTable(props: Props) {
  const userContext = useContext(UserContext);

  const [inputDialogState, setInputDialogState] = useState<ViewSaveDialogProps>({
    open: false,
    title: '',
    content: '',
    submitText: '',
    submit: () => { },
    viewName: '',
  });
  // use react router search params to filter the data
  const [searchParams, setSearchParams] = useSearchParams();

  // #region STATE
  const updateSearchStateParams = () => {
    const urlParams = dashboardStateToURLParams({
      sorting,
      grouping,
      columnOrder,
      columnVisibility,
      columnFilterFunctions: columnFilterFns,
      columnFilterValues: columnFilters
    });
    urlParams.sort()
    setSearchParams(urlParams);
  }

  const dashboardState = urlParamsToDashboardState(searchParams);
  const [sorting, setSorting] = useState<MRT_SortingState>(dashboardState.sorting ?? DEFAULT_COLUMN_SORT);
  const [columnVisibility, setColumnVisibility] = useState<MRT_VisibilityState>(dashboardState.columnVisibility ?? DEFAULT_VIS_STATE);
  const [grouping, setGrouping] = useState<MRT_GroupingState>(dashboardState.grouping ?? DEFAULT_GROUPING);
  const [columnOrder, setColumnOrder] = useState<MRT_ColumnOrderState>(dashboardState.columnOrder ?? DEFAULT_COLUMN_ORDER);
  const [columnFilters, setColumnFilters] = useState<MRT_ColumnFiltersState>(DEFAULT_FILTERS);
  const [columnFilterFns, setColumnFilterFns] = useState<MRT_ColumnFilterFnsState>(DEFAULT_FILTER_FNS);

  const [isLoading, setIsLoading] = useState(false);
  const [updatingBoundaries, setUpdatingBoundaries] = useState(false);
  const [companyOptions, setCompanyOptions] = useState<Company[]>([]);
  const [selectedCompany, setSelectedCompany] = useState<Company | null>(null);
  const [views, setViews] = useState<ViewCollection>(DEFAULT_VIEWS);
  const [selectedViewDirty, setSelectedViewDirty] = useState<boolean>(false);
  const [selectedView, setSelectedView] = useState<ViewDefinition | null>(null);
  const [branches, setBranches] = useState<Branch[]>([]);

  const [jobs, setJobs] = useState<Job[]>([]);
  const [user, setUser] = useState<Contact>();
  const [rowSelection, setRowSelection] = useState<MRT_RowSelectionState>({});
  const [selectionDisplayedOnMap, setSelectionDisplayedOnMap] = useState<MRT_RowSelectionState>({});

  const [markJobsReadyVisible, setMarkJobsReadyVisible] = useState(false);

  const [mobileMenuElement, setMobileMenuElement] = React.useState<null | HTMLElement>(null);
  const [actionMenuElement, setActionMenuElement] = React.useState<null | HTMLElement>(null);
  const [actionMenuRow, setActionMenuRow] = React.useState<MRT_Row<Job> | null>(null);

  const hasSelections = Object.keys(rowSelection).length > 0;
  const hasGrouping = grouping.length > 0;
  const tableHeight = hasSelections && hasGrouping ? `calc(100vh - 240px)` : `calc(100vh - 190px)`;

  const selectedJobs = Object.keys(rowSelection).map((key) => {
    const index = parseInt(key);
    if (!isNaN(index) && rowSelection[key]) {
      return jobs[index];
    }
  }) as Job[];

  const unreadySelectedJobs = selectedJobs?.filter(job => !job?.dateFieldReady);

  useEffect(() => {
    // TODO Might not be necessary, might be ROGO user only setting
    const paramsCopy = new URLSearchParams(searchParams.toString());
    paramsCopy.sort();

    const selectedParams = new URLSearchParams(selectedView?.url ?? '');
    selectedParams.sort();

    setSelectedViewDirty(selectedView === null || selectedParams.toString() !== paramsCopy.toString());

    updateSearchStateParams();
  }, [selectedView, grouping, sorting, columnOrder, columnVisibility, columnFilterFns, columnFilters]);
  // #endregion

  // #region DROPDOWN LISTS
  const [jobStatusList, setJobStatusList] = useState<string[]>([]);
  const [testPackageList, setTestPackageList] = useState<string[]>([]);
  const [submitterList, setSubmitterList] = useState<string[]>([]);
  const [seasonList, setSeasonList] = useState<string[]>([]);
  const [branchList, setBranchList] = useState<string[]>([]);
  function setMultiSelectOptions(jobs: Job[], branches: Branch[] = []) {
    setSelectList(jobs, 'status', setJobStatusList);
    setSelectList(jobs, "testPackage", setTestPackageList);
    setSelectList(jobs, "submitter", setSubmitterList);
    setSelectList(jobs, "season", setSeasonList);
    setSelectList(jobs, "branchIds", setBranchList, branches);
  }

  function setSelectList(jobs: Job[], fieldName: keyof Job, setList: (list: any[]) => void, branches: Branch[] = []) {
    const all = jobs.map(job => _getField(job, fieldName, branches)).filter((value, index, self) => self.indexOf(value) === index).flat();
    const distinct = [...new Set(all)].filter(value => value !== undefined).sort();
    setList(distinct);

    function _getField(job: Job, fieldName: keyof Job, branches: Branch[] = []) {
      if (fieldName === "branchIds") {
        return getBranchField(job, branches);
      } else {
        return job[fieldName];
      }
    }
  }

  function getBranchField(job: Job, b: Branch[] = []) {
    const branchIds = job.branchIds;
    if (!branchIds) { 
      return ""; 
    }
    if (!branchIds.length) { 
      return ""; 
    }

    const branchId = branchIds[0];
    var branch = branches.find(branch => branch.id === branchId);
    if (!branch) { 
      branch = b.find(branch => branch.id === branchId);
    }
    if (!branch) { 
      return "";
    }

    return branch.name;
  }
  // #endregion
  
  // #region FUNCTIONS
  useEffect(() => {
    // React advises to declare the async function directly inside useEffect
    async function getData() {
      try {
        setIsLoading(true);
        if (userContext == undefined || !userContext.id) {
          Sentry.captureMessage("userContext is undefined: JobsDashboardTable.tsx", "info");
          toast.error(INVALID_USERCONTEXT);
          return;
        }
        const user = await API.Data.Contacts.get.byId(userContext.id);
        const companies = await API.Data.Companies.get.byContact(user);
        
        setUser(user);
        companies.sort((companyA, companyB) => {
          if (companyA.name === "Rogo AG" || companyA.name === "ROGO Software") return -1;
          if (companyB.name === "Rogo AG" || companyB.name === "ROGO Software") return 1;
          return companyA.name.localeCompare(companyB.name);
        });
        setCompanyOptions(companies);
        if (companies.length === 1) {
          await onCompanyChanged(companies[0]);
        } else if (companies.length > 1) {
          const urlCompanyId = searchParams.get('company');
          if (urlCompanyId) {
            const company = companies.find(company => company.id === urlCompanyId);
            if (company) {
              await onCompanyChanged(company);
            }
          }
        }
      } catch (error) {
        Oops.handleError(error, "JobsDashboardTable.getData");
      } finally {
        setIsLoading(false);
      }

    };

    if (!jobs.length) {
      getData();
    }
  }, [userContext]);

  async function onCompanyChanged(company: Company | null) {
    setIsLoading(true);
    try {
      setSelectedCompany(company);
      if (companyOptions.length > 1) {
        // updateSearchParams('company', value?.id ?? '');
        updateSearchStateParams();
      }
      setRowSelection({});
      let jobs: Job[] = [];

      const newViews: ViewCollection = user?.portalSettings.views ?? [];

      let branches: Branch[] = [];
      if (company) {
        jobs = await API.Data.Jobs.get.byCompany(company);
        branches = await API.Data.Branches.get.byCompany(company);
        setBranches(branches);
        newViews.push(...company.portalViews);
      }

      newViews.push(...DEFAULT_VIEWS);

      setViews(newViews);

      // if (!searchParams.toString()) {
      // TODO we should actually get the last visited view, or the customer default view here
      const defaultView = views.filter(view => view.name === DEFAULT_VIEW_NAME)[0];
      const urlParams = new URLSearchParams(defaultView.url);
      urlParams.sort();
      const dashboardState = urlParamsToDashboardState(urlParams);
      let grouping = dashboardState.grouping;
      if (branches.length <= 1) {
        grouping = grouping.filter(group => group !== 'branch');
      }
      setSorting(dashboardState.sorting);
      setGrouping(grouping);
      setColumnOrder(dashboardState.columnOrder);
      setColumnVisibility(dashboardState.columnVisibility);
      setSelectedView(defaultView);
      setSearchParams(urlParams);
      // }

      setMultiSelectOptions(jobs, branches);
      setJobs(jobs);
    } catch {
      // handle error
    } finally {
      setIsLoading(false);
      setMobileMenuElement(null);
    }
  }

  async function onSavedViewChanged(event: any, value: any) {
    event.stopPropagation();
    if (!value) {
      setSelectedView(value);
      return;
    }

    try {
      const urlParams = new URLSearchParams(value.url);
      urlParams.sort();
      const dashboardState = urlParamsToDashboardState(urlParams);
      setSorting(dashboardState.sorting);
      let grouping = dashboardState.grouping;
      // This is special logic to filter out the branch grouping if there is only one branch
      if (branches.length <= 1) {
        grouping = grouping.filter(group => group !== 'branch');
      }
      setGrouping(grouping);
      setColumnOrder(dashboardState.columnOrder);
      setColumnVisibility(dashboardState.columnVisibility);
      setColumnFilters(dashboardState.columnFilterValues);
      setColumnFilterFns(dashboardState.columnFilterFunctions);

      setSearchParams(urlParams);
      setSelectedView(value);
    } catch (error) {
      Oops.handleError(error, "JobsDashboardTable.onSavedViewChanged");
    } finally {
      setMobileMenuElement(null);
    }
  };

  async function refreshJobs() { await loadJobs(false); }
  async function loadAllJobs() { await loadJobs(true); }
  async function loadJobs(loadAll: boolean = false) {
    try {
      if (!selectedCompany) {
        return;
      }
      setIsLoading(true);
      const jobs = await API.Data.Jobs.get.byCompany(selectedCompany, loadAll);
      setMultiSelectOptions(jobs);
      setJobs(jobs);
    } catch (error) {
      Oops.handleError(error);
    } finally {
      setIsLoading(false);
      setMobileMenuElement(null);
    }
  }

  async function updateBoundaries() {
    try {
      // setUpdatingBoundaries(true);

      const selectedJobs = Object.keys(rowSelection).map((key) => {
        const index = parseInt(key);
        if (!isNaN(index) && rowSelection[key]) {
          return jobs[index];
        }
      });

      // open the drawer if rows are selected
      props.setDrawerOpenState(Object.keys(rowSelection).length > 0);

      const displayedBoundaries: Record<string, FeatureCollection<Polygon> | FeatureCollection<MultiPolygon>> = {};
      const displayedPoints: Record<string, FeatureCollection<Point>> = {};
      const displayedZones: Record<string, FeatureCollection<Polygon> | FeatureCollection<MultiPolygon>> = {};
      await Promise.all(selectedJobs.map(async job => {
        if (!job) {
          return;
        }

        const [boundary, points, zones] = await Promise.all([
          GeoJSON.getBoundary(job),
          GeoJSON.getPoints(job),
          GeoJSON.getZones(job),
        ]);
        if (boundary) { displayedBoundaries[job.id] = boundary; }
        if (points) { displayedPoints[job.id] = points; }
        if (zones) { displayedZones[job.id] = zones; }
      }));

      props.setDisplayBoundaries(displayedBoundaries);
      props.setDisplayedPoints(displayedPoints);
      props.setDisplayedZones(displayedZones);
      setSelectionDisplayedOnMap(rowSelection);
      return [Object.keys(displayedBoundaries).length, Object.keys(displayedPoints).length, Object.keys(displayedZones).length] as const;
    } catch (error) {
      Oops.handleError(error, "JobsDashboardTable.updateBoundaries");
      return [0, 0, 0] as const;
    }
  }

  async function showOnMap() {
    const loadingBoundariesToast = toast.loading('Updating map...');
    const [boundaryCount, pointCount, zoneCount] = await updateBoundaries();
    toast.dismiss(loadingBoundariesToast);
    toast.info(`Displaying ${boundaryCount} boundaries, ${pointCount} point groups and ${zoneCount} zones`);
    setMobileMenuElement(null);
  }
  
  function getMarkReadyText(unreadySelectedJobs: Job[]): React.ReactNode {
    switch (unreadySelectedJobs.length) {
      case 0:
        return 'Mark Jobs Ready';
      case 1:
        return 'Mark 1 Job Ready';
      default:
        return `Mark ${unreadySelectedJobs.length} Jobs Ready`;
    }
  }
  
  // need to also ensure that individual files aren't zipped
  function downloadFiles(files: IAirtableAttachment[], columnName: string, rowName: string, mergeCSV: boolean = false) {
    return async () => {
      const toastDownloading = toast.loading('Downloading files...');
  
      try {
        await DownloadFile.fromAttachments(files, getFileName(), files.length > 1, mergeCSV);
      } catch (error) {
        Oops.handleError(error, "JobsDashboardTable.downloadFiles");
      } finally {
        toast.dismiss(toastDownloading);
      }
  
      function getFileName() {
        var fileName = "";
  
        if (files.length > 1) {
          fileName = rowName ? `${rowName} - ${columnName}` : '';
        }
  
        return fileName;
      }
    }
  }

  // PROOF OF CONCEPT
  // this function is pretty rudementary, and will need improvement before it can go live.
  // also, it doesn't yet work properly with `onColumnFilterFnsChange: setColumnFilterFns,`
  const booleanFilterFn: MRT_FilterFn<any> = (row, columnId, filterValue) => {
    const columnValue = row.getValue<string>(columnId).toLowerCase();
    const queryElements = filterValue.match(/\w+/g);
    const terms = queryElements?.filter((element: string) => element !== "AND" && element !== "OR").map((element: string) => element.toLowerCase());
    const operators = filterValue.match(/(AND|OR)/g); // Extract operators
    
    if (!terms || terms.length === 0) {
      return true; // No filter applied
    }
    
    const evaluateAnds = (terms: string[], columnValue: string) => {
      return terms.reduce((acc, term) => acc && columnValue.includes(term), true);
    };

    let result = false;
    let currentTerms: string[] = [];
    let currentOperator = "OR";

    for (let i = 0; i < terms.length; i++) {
      currentTerms.push(terms[i]);
      const nextOperator = operators?.[i] || "OR";

      if (nextOperator === "OR" || i === terms.length - 1) {
      const andResult = evaluateAnds(currentTerms, columnValue);
      if (currentOperator === "AND") {
        result = result && andResult;
      } else {
        result = result || andResult;
      }
      currentTerms = [];
      currentOperator = nextOperator;
      }
    }
  
    return result || false;
  };

  function getShowMapText(): React.ReactNode {
    return selectionDisplayedOnMap !== rowSelection && props.drawerOpen ? "Update Map" : "Show on Map";
  }

  function deleteView() {
    if (selectedView) {
      const newViews: ViewCollection = [...views];
      const index = newViews.indexOf(selectedView);
      newViews.splice(index, 1);
      setViews(newViews);
      setSelectedView(null);

      updateViews(selectedView, newViews);
    }
    setMobileMenuElement(null);
  }

  async function copyLinkToClipboard() {
    try {
      // if this fails, it will throw
      await navigator.clipboard.writeText(window.location.href);

      // otherwise if we got here, then it should have worked
      toast.success('Link copied to clipboard');
    } catch (error) {
      Oops.handleError(error, "JobsDashboardTable.copyLinkToClipboard");
    } finally {
      setMobileMenuElement(null);
    }
  }

  async function saveCurrentView() {
    let newName = selectedView?.name;
    // TODO prompt for name
    const result = await new Promise<[string, ViewGroup] | undefined>((resolve) => {
      setInputDialogState({
        open: true,
        title: 'Save current view',
        content: 'Please enter a name for this view',
        submitText: 'Save',
        submit: (result) => {
          resolve(result);
          setInputDialogState({ ...inputDialogState, open: false });
        },
        viewName: selectedView?.name ?? '',
      });
    });

    if (!result) {
      setMobileMenuElement(null);
      return;
    }

    const [name, type] = result;

    const newViews: ViewCollection = [
      ...views,
    ];

    const newView: ViewDefinition = {
      name,
      group: type,
      url: searchParams.toString(),
      lastUpdated: new Date().toISOString()
    };
    if (selectedView && name === selectedView.name && type === selectedView.group) {
      newViews[newViews.indexOf(selectedView)] = newView;
    } else {
      // look for a view that has the same group type as our new value
      for (const view of newViews) {
        if (view.group === newView.group || view.group === ViewGroup.Default) {
          // insert the view right before the first view with the same group
          newViews.splice(newViews.indexOf(view), 0, newView);
          break;
          // because we always sort the Default group to the 
          // end of the list, we can break here 
        }
      }
    }
    setViews(newViews);
    setSelectedView(newView);

    updateViews(newView, newViews);
    setMobileMenuElement(null);
  }

  function updateViews(newView: ViewDefinition, newViews: ViewCollection) {
    if (newView.group === ViewGroup.User) {
      const userViews = newViews.filter(view => view.group === ViewGroup.User);
      if (userContext) {
        API.Data.Contacts.update.field(userContext.id, "Portal Settings", { views: JSON.stringify(userViews) });
      }
    } else if (newView.group === ViewGroup.Company) {
      const companyViews = newViews.filter(view => view.group === ViewGroup.Company);
      API.Data.Companies.update.field(selectedCompany!.id, "Portal Views", JSON.stringify(companyViews));
    }
  }

  // #endregion

  // #region MENUS / TOOLBAR
  function toolbar(table: any) {
    const noJobs = jobs.length === 0;
    const noViewSelected = !selectedView;
    const viewIsReadonly = !!selectedView && selectedView.group === ViewGroup.Default;

    // we will actually always allow the save button when we have a default view selected
    // that way we will allow the user to "copy" the view by saving it under their own name
    const canSaveViewState = !viewIsReadonly && !selectedViewDirty;
    const cantSaveViewState = !canSaveViewState;
    const saveViewDisabled = noJobs && cantSaveViewState;
    const deleteViewDisabled = viewIsReadonly || noJobs || noViewSelected;

    return (
      <Box
        sx={(theme) => ({
          backgroundColor: lighten(theme.palette.background.default, 0.05),
          display: 'flex',
          pl: '8px',
        })}
      >
        <div id="MRT-sub-components" className='horizontal-button-group'>
          <MRT_GlobalFilterTextField table={table} />
          <MRT_ToggleFiltersButton table={table} />
          <MRT_ShowHideColumnsButton table={table} />
          <MRT_ToggleDensePaddingButton table={table} />
          <MRT_ToggleFullScreenButton table={table} />
        </div>

        <div id="right-controls">
          {/* only show controls if NOT expanded */}
          {!props.drawerOpen && (<>
            <div id="company-view-dropdowns" className="hide-on-mobile-screen">
              {companyOptions.length > 1 &&
                <CustomAutocomplete
                  optionLabelField="name"
                  loading={false}
                  options={companyOptions}
                  selectedOption={selectedCompany}
                  onChange={onCompanyChanged}
                  className="dashboard-dropdown"
                  label="Company"
                  // tooltip="Select a company to view their jobs and views. This is only for ROGO employees"
                  placement='right'
                  size="small" />}
              <Autocomplete
                className="dashboard-dropdown"
                options={views}
                value={selectedView}
                disabled={jobs.length === 0}
                groupBy={(option) => option.group === 'Default' ? 'System Views' : option.group.toString()}
                getOptionKey={(option) => option.group + option.name}
                getOptionLabel={(option) => option.name}
                onChange={onSavedViewChanged}
                renderInput={(params) => <TextField {...params} label="Saved View" variant="outlined" size="small" />} />
            </div>
            <div id="view-button" className="horizontal-button-group hide-on-small-screen">
              <Tooltip title="Refresh jobs">
                <IconButton
                  id="refresh-jobs-button"
                  onClick={refreshJobs}
                  disabled={jobs.length === 0}
                  className='icon-button'>
                  <RefreshOutlined fontSize='medium' style={{ 'color': jobs.length === 0 ? 'grey' : 'blue' }} />
                </IconButton>
              </Tooltip>
              <Tooltip title="Save current view">
                <IconButton
                  id="save-view-button"
                  onClick={saveCurrentView}
                  className='icon-button'
                  disabled={saveViewDisabled}>
                  <Save fontSize='medium' style={{ 'color': saveViewDisabled ? 'grey' : 'green' }} />
                </IconButton>
              </Tooltip>
              <Tooltip title="Copy link to clipboard">
                <IconButton
                  id="copy-link-button"
                  onClick={copyLinkToClipboard}
                  className='icon-button'
                  disabled={noJobs}>
                  <ContentCopy fontSize='medium' style={{ 'color': noJobs || noViewSelected ? 'grey' : 'blue' }} />
                </IconButton>
              </Tooltip>
              <Tooltip title="Delete view">
                <IconButton
                  id="delete-view-button"
                  onClick={deleteView}
                  className='icon-button'
                  disabled={deleteViewDisabled}>
                  <Delete fontSize='medium' style={{ 'color': deleteViewDisabled ? 'grey' : 'red' }} />
                </IconButton>
              </Tooltip>
            </div>
          </>)}
          <div id="toolbar-menu" className='horizontal-button-group'>
            <div>
              <IconButton
                onClick={(event) => { setMobileMenuElement(event.currentTarget); } }
                className='icon-button'
                id="toolbar-menu-button">
                <MoreHoriz />
              </IconButton>
            </div>
          </div>
          <div id="big-blue-buttons" className="horizontal-button-group">
            <div id="mark-ready-buttons" className="multi-screen-size-button-group hide-on-small-screen">
              <Button
                id="mark-ready-button"
                className='text-button big-screen-button'
                onClick={() => { setMarkJobsReadyVisible(true); } }
                color="primary"
                variant="contained">
                {getMarkReadyText(unreadySelectedJobs)}
              </Button>
              <Tooltip title={getMarkReadyText(unreadySelectedJobs)}>
                <IconButton className='icon-button medium-screen-button' onClick={() => { setMarkJobsReadyVisible(true); } }>
                  <Check fontSize='medium' />
                </IconButton>
              </Tooltip>
            </div>
            <div id="show-on-map-buttons" className='multi-screen-size-button-group'>
              <Button
                id="show-on-map-button"
                onClick={showOnMap}
                className='text-button big-screen-button'
                color="primary"
                variant="contained"
                disabled={Object.keys(selectionDisplayedOnMap).length === Object.keys(rowSelection).length &&
                  Object.keys(rowSelection).every((key) => !!selectionDisplayedOnMap[key])}>
                {getShowMapText()}
              </Button>
              <Tooltip title={getShowMapText()}>
                <IconButton className='icon-button medium-screen-button' onClick={showOnMap}>
                  <Map fontSize='medium' />
                </IconButton>
              </Tooltip>
              {props.drawerOpen ? (<IconButton
                onClick={() => { props.setDrawerOpenState(!props.drawerOpen); } }
                className='icon-button'
                id="right-drawer-button"
                disabled={noJobs}>
                <KeyboardDoubleArrowRight fontSize='large' style={{ 'color': noJobs ? 'grey' : undefined }} />
              </IconButton>
              ) : <></>}
            </div>
          </div>
        </div>
      </Box>
    );
  }

  interface FileMenuItem {
    label: string;
    property: keyof Job["attachments"];
  }

  const fileMenuList: FileMenuItem[] = [
    { label: "Boundary", property: "boundarySHP" },
    { label: "Points", property: "pointsSHP" },
    { label: "Sample Zones", property: "zonesSHP" },
    { label: "Executed Points", property: "executedPointsSHP" },
    { label: "Executed Boundary", property: "executedBoundarySHP" },
    { label: "Executed Points + Boundary", property: "executedPointsBoundarySHP" },
    { label: "Lab Results", property: "labResults" },
    { label: "Lab Checkin Sheet", property: "labPDF" },
    { label: "Sampling CSV", property: "labCSV" },
  ];

  function downloadMenu() {
    var downloadMenuItems = [];

    downloadMenuItems.push(<MenuItem>
      <strong>Files for {getItemName()}</strong>
    </MenuItem>);

    downloadMenuItems.push(<MenuItem onClick={downloadFiles(getAllFiles(), "All Files", getItemName())}>
      <Download /> All Files
    </MenuItem>);

    fileMenuList.forEach(fileMenuItem => {
      addDownloadButton(fileMenuItem);
    });
    if (!actionMenuRow || actionMenuRow?.getIsGrouped()) {
      const csvs = getFiles({ label: "Sampling CSV", property: "labCSV" });
      downloadMenuItems.push(<MenuItem onClick={downloadFiles(csvs, "Sampling CSV (Merged)", getItemName(), true)}>
        <Download /> Sampling CSV (Merged)
      </MenuItem>);
    }

    if (user?.isAdmin && getJobID() != "") {
      downloadMenuItems.push(<MenuItem onClick={copyJobID}>
        <strong>{getJobID()}</strong>
      </MenuItem>);
    }

    return <Menu
      anchorEl={actionMenuElement}
      open={Boolean(actionMenuElement)}
      onClose={() => setActionMenuElement(null)} >
      { downloadMenuItems }
    </Menu>;



    function copyJobID(){
      navigator.clipboard.writeText(getJobID()).then(() => {
        toast.success('Job ID copied to clipboard');
      }).catch(() => {
        toast.error('Failed to copy Job ID to clipboard');
      });
      return;
    }

    function getJobID() {
      var jobid = "";

      if (!actionMenuRow) {
        if (selectedJobs.length === 0) {
          jobid = "";
        } else {
          jobid = "";
        }
      } else if (actionMenuRow.getIsGrouped()) {
        jobid = ""
      } else {
        var job = actionMenuRow?.original;
        jobid = job.id;
      }

      return jobid;
    }

    function getAllFiles() {
      const allFiles: IAirtableAttachment[] = [];

      fileMenuList.forEach(fileMenuItem => {
        const files = getFiles(fileMenuItem);
        allFiles.push(...files);
      });

      return allFiles;
    }

    function getItemName() {
      let itemName = "";

      if (!actionMenuRow) {
        if (selectedJobs.length === 0) {
          itemName = "All Jobs";
        } else {
          itemName = "Selected Jobs";
        }
      } else if (actionMenuRow.getIsGrouped()) {
        itemName = actionMenuRow.groupingValue as string;
      } else {
        var job = actionMenuRow?.original;
        itemName = job.fieldName;
      }

      return itemName;
    }

    function addDownloadButton(fieldNames: FileMenuItem) {
      const rowName = getRowName();
      const files = getFiles(fieldNames);

      if (files?.length > 0) {
        downloadMenuItems.push(downloadButton(fieldNames.label, files, rowName));
      } else {
        downloadMenuItems.push(<MenuItem disabled>
          <Download /> {fieldNames.label}
        </MenuItem>);
      }

      function getRowName() {
        let rowName = "";
        if (!actionMenuRow) {
          if (selectedJobs.length === 0) {
            rowName = "All Jobs";
          } else {
            rowName = "Selected Jobs";
          }
        } else if (actionMenuRow.getIsGrouped()) {
          rowName = actionMenuRow.groupingValue as string;
        } else {
          rowName = actionMenuRow.original.fieldName;
        }
        return rowName;
      }
    }

    function getFiles(fileMenuItem: FileMenuItem) {
      const files: IAirtableAttachment[] = [];

      if (!actionMenuRow){ 
        // All files
        if (selectedJobs.length === 0) { 
          for (let i = 0; i < (jobs.length ?? 0); i++) {
            getFilesFromJob(jobs[i]);
          }
        // Selected files
        } else { 
          for (let i = 0; i < (selectedJobs.length ?? 0); i++) {
            getFilesFromJob(selectedJobs[i]!);
          }
        }
      // Grouped files
      } else if (actionMenuRow.getIsGrouped()) { 
        const getFilesRecursive = (subRows: MRT_Row<Job>[]) => {
          subRows.forEach(subRow => {
            if (subRow.subRows?.length) {
              getFilesRecursive(subRow.subRows);
            } else {
              getFilesFromJob(subRow.original);
            }
          });
        };

        getFilesRecursive(actionMenuRow?.subRows ?? []);
      // Single file
      } else { 
        getFilesFromJob(actionMenuRow?.original);
      }

      return files;

      function getFilesFromJob(job: Job | undefined) {
        if (job) { 
          const file = job.attachments[fileMenuItem.property];
          if (file) files.push(...file);
        }
      }
    }

    function downloadButton(label: string, files: IAirtableAttachment[], groupName: string) {
      return <MenuItem onClick={downloadFiles(files, label, groupName)}>
        <Download /> {label}
      </MenuItem>;
    }
  };

  function mobileMenu() {
    return (
    <Menu
      anchorEl={mobileMenuElement}
      open={Boolean(mobileMenuElement)}
      onClose={() => setMobileMenuElement(null)}>
      {companyOptions.length > 1 &&
        <CustomAutocomplete
          id="company-dropdown"
          optionLabelField="name"
          loading={false}
          options={companyOptions}
          selectedOption={selectedCompany}
          onChange={onCompanyChanged}
          className="dashboard-dropdown show-on-mobile-screen"
          label="Company"
          // tooltip="Select a company to view their jobs and views. This is only for ROGO employees"
          placement='right'
          size="small" />}
      <Autocomplete
        id="view-dropdown"
        className="dashboard-dropdown show-on-mobile-screen"
        options={views}
        value={selectedView}
        disabled={jobs.length === 0}
        groupBy={(option) => option.group === 'Default' ? 'System Views' : option.group.toString()}
        getOptionKey={(option) => option.group + option.name}
        getOptionLabel={(option) => option.name}
        onChange={onSavedViewChanged}
        renderInput={(params) => <TextField {...params} label="Saved View" variant="outlined" size="small" />} />
      <MenuItem onClick={refreshJobs} className='show-on-small-screen'>
        <RefreshOutlined /><div className="right-menu-text">Refresh Jobs</div>
      </MenuItem>
      <MenuItem onClick={saveCurrentView} className='show-on-small-screen'>
        <Save /><div className="right-menu-text">Save Current View</div>
      </MenuItem>
      <MenuItem onClick={copyLinkToClipboard} className='show-on-small-screen'>
        <ContentCopy /><div className="right-menu-text">Copy Link to Clipboard</div>
      </MenuItem>
      <MenuItem onClick={deleteView} className='show-on-small-screen'>
        <Delete /><div className="right-menu-text">Delete View</div>
      </MenuItem>
      <MenuItem onClick={() => { setMarkJobsReadyVisible(true); } } className='show-on-small-screen'>
        <Check /><div className="right-menu-text">Mark Jobs Ready</div>
      </MenuItem>
      <MenuItem onClick={showOnMap} className='show-on-small-screen'>
        <Map /><div className="right-menu-text">Show on Map</div>
      </MenuItem>
      <MenuItem onClick={loadAllJobs}>
        <HourglassBottom /><div className="right-menu-text">Load All Jobs</div>
      </MenuItem>
    </Menu>);
  }
  // #endregion

  // #region TABLE
  const columns = useMemo<MRT_ColumnDef<Job>[]>(
    () =>
      [
        textColumn("Grower", { accessorKeyOrFn: "growerName", size: 200 }),
        textColumn("Field", { accessorKeyOrFn: "fieldName" }),
        textColumn("Farm", { accessorKeyOrFn: "farmName" }),
        multiSelectColumn("Job Status", { accessorKeyOrFn: "status", filterSelectOptions: jobStatusList }),
        numericColumn("Boundary Acres", { accessorKeyOrFn: "boundaryAcres", aggregationFn: 'sum', slider: false }),
        multiSelectColumn("Branch", { accessorKeyOrFn: getBranchField, filterSelectOptions: branchList }),
        dateColumn("Field Ready Date", { accessorKeyOrFn: "dateFieldReady", isRangeFilter: true }),
        dateColumn("Sample Date", { accessorKeyOrFn: "dateSampled", isRangeFilter: true }),
        dateColumn("Creation Date", { accessorKeyOrFn: "dateCreated", isRangeFilter: true }),
        dateColumn("Drop/Ship Date", { accessorKeyOrFn: "dateShipped", defaultText: 'Not shipped', isRangeFilter: true }),
        multiSelectColumn("Test Package", { accessorKeyOrFn: "testPackage", filterSelectOptions: testPackageList }),
        checkboxColumn("Boundary Change?", { accessorKeyOrFn: "isBoundaryChanged" }),
        checkboxColumn("Boundary Approved?", { accessorKeyOrFn: "isBoundaryApproved" }),
        checkboxColumn("Points Change?", { accessorKeyOrFn: "isPointsChanged" }),
        multiSelectColumn("Submitter", { accessorKeyOrFn: "submitter", filterSelectOptions: submitterList }),
        multiSelectColumn("Season", { accessorKeyOrFn: "season", filterSelectOptions: seasonList }),
        checkboxColumn("Approved Billing?", { accessorKeyOrFn: "isBillingApproved" }),
        checkboxMultiselectColumn('Job Flags', 'Map Issue', "jobFlags" ),
        dateColumn("Lab Sent Results", { accessorKeyOrFn: "dateLabResultsSent", defaultText: 'Not Sent', isRangeFilter: true }),
        textColumn("Event ID", { accessorKeyOrFn: "eventId" }),
        textColumn("Lab Submittal ID", { accessorKeyOrFn: "labSubmittalId" }),
        numericColumn("Depth", { accessorKeyOrFn: "depth", aggregationFn: 'sum', slider: false, sliderStepSize: 1 }),
        textColumn("Sample Changes", { accessorKeyOrFn: "sampleChanges" }),
      ],
    [branches],
  );

  const table = useMaterialReactTable({
    columns,
    data: jobs, //data must be memoized or stable (useState, useMemo, defined outside of this component, etc.)
    enableColumnFilterModes: false,
    enableColumnFilters: true,
    enableColumnOrdering: true,
    enableColumnDragging: true,
    enableColumnPinning: true,
    enableGrouping: true,
    enableHiding: true,
    enableFacetedValues: true,
    enableStickyHeader: true,
    enableStickyFooter: true,
    enableTableFooter: true,
    enableMultiSort: true,
    enableGlobalFilterModes: true,
    enableGlobalFilterRankedResults: true,
    enableRowSelection: true,
    enableSubRowSelection: true,
    enableRowActions: true,
    positionActionsColumn: 'last',
    columnFilterDisplayMode: 'subheader',
    muiTableContainerProps: { sx: { height: tableHeight }, },
    muiTablePaperProps: { sx: { height: '100vh - 30px)' } },
    maxMultiSortColCount: 3,
    initialState: {
      expanded: true,
      showGlobalFilter: true,
      density: 'compact',
      pagination: {
        pageSize: 20,
        pageIndex: 0
      },
      columnVisibility: {
        ["Grower"]: false
      },
      columnPinning: {
        left: ['mrt-row-select'],
        right: ['mrt-row-actions'],
      },
    },
    filterFns: {
      'booleanFilter': booleanFilterFn,
    },
    selectAllMode: 'all',
    state: {
      // URL based state
      columnVisibility,
      grouping,
      sorting,
      columnOrder,
      columnFilters: columnFilters,
      columnFilterFns: columnFilterFns,
      rowSelection,
      isLoading: isLoading || updatingBoundaries,
    },
    muiCircularProgressProps: {
      Component: <LoadingComponent />,
    },
    onGroupingChange: setGrouping,
    onRowSelectionChange: setRowSelection,
    onColumnVisibilityChange: setColumnVisibility,
    onColumnOrderChange: setColumnOrder,
    onColumnFilterFnsChange: setColumnFilterFns,
    onColumnFiltersChange: setColumnFilters,
    onSortingChange: setSorting,
    groupedColumnMode: false,
    displayColumnDefOptions: {
      'mrt-row-select': {
        size: 40,
        visibleInShowHideMenu: true,
      },
      'mrt-row-actions': {
        header: 'Files',
        size: 40,
        visibleInShowHideMenu: true,
        Header: () => {
          return [
            <Box className="download-button">
              <IconButton color="primary" onClick={(event) => { setActionMenuElement(event?.currentTarget); setActionMenuRow(null); }}>
                <Download />
              </IconButton>
              <Box className="hide-on-small-screen">Files</Box>
            </Box>
          ];
        },
        AggregatedCell: ({ row }) => {
          return (
            <Box className="download-button">
              <IconButton color="primary" onClick={(event) => { setActionMenuElement(event?.currentTarget); setActionMenuRow(row); }}>
                <Download />
              </IconButton>
              <Box className="hide-on-small-screen">Files</Box>
            </Box>
          );
        },
        Cell: ({ row }) => {
          return (
            <Box className="download-button">
              <IconButton color="primary" onClick={(event) => { setActionMenuElement(event?.currentTarget); setActionMenuRow(row); }}>
                <Download />
              </IconButton>
              <Box className="hide-on-small-screen">Files</Box>
            </Box>
          );
        },
      },
      'mrt-row-expand': {
        Header: () => (
          <Stack direction="row" alignItems="center">
            <MRT_ExpandAllButton table={table} />
            <Box>Groups</Box>
          </Stack>
        ),
        // @ts-ignore
        GroupedCell: ({ row, column }) => {
          const value = row.getValue(grouping[grouping.length - 1]);
          if (value instanceof Date) {
            return value.toLocaleDateString();
          }
          return value;
        },
        Cell: ({ row, column }) => {
          const groupingId = grouping[Math.min(row.depth, grouping.length - 1)];
          const value = row.getValue(groupingId);

          let label: string | unknown = value
          if (value instanceof Date) {
            label = value.toLocaleDateString();
          }
          return (
            <Stack direction="row" alignItems="center">
              <MRT_ExpandButton row={row} table={table} />
              <Box>{label?.toString() || "n/a"}</Box>
            </Stack>
          );
        },
        muiTableBodyCellProps: ({ row }) => ({
          sx: (theme) => ({
            color:
              row.depth === 0
                ? theme.palette.primary.main
                : row.depth === 1
                  ? theme.palette.secondary.main
                  : undefined,
          }),
        }),
        size: 200,
        visibleInShowHideMenu: true,
      },
    },
    paginationDisplayMode: 'default',
    positionToolbarAlertBanner: 'bottom',
    muiSearchTextFieldProps: {
      size: 'small',
      variant: 'outlined',
    },
    muiPaginationProps: {
      rowsPerPageOptions: [10, 15, 20, 30, 50],
      showRowsPerPage: !props.drawerOpen,
    },
    renderTopToolbar: ({ table }) => { return toolbar(table); },
  });
  
  return <>
    <MaterialReactTable table={table} />
    <ViewSaveDialog {...inputDialogState} />
    <MarkJobReadyPopover
      open={markJobsReadyVisible}
      jobs={jobs.filter(job => !job.dateFieldReady)}
      intialSelectedJobs={ markJobsReadyVisible ? unreadySelectedJobs : [] }
      setLoading={setIsLoading}
      onSuccess={refreshJobs}
      setOpen={(open) => setMarkJobsReadyVisible(open)}
    />
    {downloadMenu()}
    {mobileMenu()}
  </>;
  // #endregion

};
// #endregion

