import { AuthenticationAPI } from "./routers/authentication";
import { S3_API } from "./routers/aws";
import { ProagricaAPI } from "./routers/proagrica";
import { REQUEST_CONFIG_AUTH } from "./common";
import { AgVanceAPI } from "./routers/agvance";
import axios from "axios";
import { BoxesAPI, BoxShipmentsAPI, BranchesAPI, CompaniesAPI, ContactsAPI, CourierPickupsAPI, DealsAPI, DropoffsAPI, GrowersAPI, JobsAPI, JobsReadyAPI, LabsAPI, LabTestsAPI, RobotsAPI, ShiftDropoffsAPI, ShiftsAPI, ShiftTimesAPI } from "./routers/data/tables";

export * from './types/data_models';
// export * from './common';
export * as ProagricaTypes from './types/proagrica';

export namespace API {
    export const Authentication = new AuthenticationAPI();
    export namespace AWS {
        export const S3 = new S3_API();
        // export const SES = null;
        // export const DynamoDB = null;
    }
    export namespace Integrations {
        export const Proagrica = new ProagricaAPI();
        export const AgVance = new AgVanceAPI();
        // export const Waypoint = null;
        // export const Brookside = null;
    }
    export namespace Data {
        export async function clearCache(){
            await axios.get(`${import.meta.env.VITE_ROGO_API_URL}/cache/clear`, REQUEST_CONFIG_AUTH());
        }
        export const Contacts = new ContactsAPI();
        export const Jobs = new JobsAPI();
        export const Deals = new DealsAPI();
        export const Companies = new CompaniesAPI();
        export const Growers = new GrowersAPI();
        export const Branches = new BranchesAPI();
        export const Labs = new LabsAPI();
        export const Shifts = new ShiftsAPI();
        export const ShiftTimes = new ShiftTimesAPI();
        export const Robots = new RobotsAPI();
        export const Dropoffs = new DropoffsAPI();
        export const ShiftDropoffs = new ShiftDropoffsAPI();
        export const LabTests = new LabTestsAPI();
        export const Boxes = new BoxesAPI();
        export const BoxShipments = new BoxShipmentsAPI();
        export const CourierPickups = new CourierPickupsAPI();
        export const JobsReady = new JobsReadyAPI();
    }
}
