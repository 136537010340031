import axios from 'axios';
import { AuthUser, getLoginToken, NEW_USER_TOKEN, REQUEST_CONFIG_AUTH, REQUEST_CONFIG_NOAUTH, setJwtToken, setLoginToken, setRefreshToken, USER_NOT_FOUND, } from '../common';

export class AuthenticationAPI {
    public async resetPassword(email: string) {
        const response = await axios.post(`${import.meta.env.VITE_ROGO_API_URL}/auth/reset-password`, { email }, REQUEST_CONFIG_NOAUTH);
        return response.data;
    }

    public async validateNewPasswordToken(token: string) {
        const response = await axios.post(`${import.meta.env.VITE_ROGO_API_URL}/auth/validate-token`, { "token": token }, REQUEST_CONFIG_NOAUTH);
        return response.data.email;
    }

    public async updatePassword(email: string = '', password: string = '', token: string = '') {
        const params = new URLSearchParams();
        params.append('grant_type', 'password');
        params.append('username', email);
        params.append('password', password);
        params.append('scope', '');
        params.append('client_id', '');
        params.append('client_secret', token);
        
        const response = await axios.post<{ access_token: string, token_type: string }>(`${import.meta.env.VITE_ROGO_API_URL}/auth/update-password`, params);
        return response.status === 200;
    }

    public async registerUser(email: string = '', password: string = '', firstName: string = '', lastName: string = '') {
        let params = { 
            "username": email, 
            "password": password,
            "firstName": firstName,
            "lastName": lastName
        }
        const response = await axios.post<{ access_token: string, token_type: string }>(`${import.meta.env.VITE_ROGO_API_URL}/auth/register`, params);

        const token = response.data.access_token;
        if (token == NEW_USER_TOKEN){
            const user = {};
            return { ...user, token, refreshToken: '' };
        } else {
            const user = await this._login(token);

            return { ...user, token, refreshToken: '' } as AuthUser;
        }
    }

    public async login(email: string, password: string) {
        const params = new URLSearchParams();
        params.append('grant_type', 'password');
        params.append('username', email || "");
        params.append('password', password || "");
        params.append('scope', '');
        params.append('client_id', '');
        params.append('client_secret', '');

        const response = await axios.post<{ access_token: string, token_type: string }>(`${import.meta.env.VITE_ROGO_API_URL}/auth/login`, params);
        const token = response.data.access_token;

        const user = await this._login(token);
    
        return { ...user, token, refreshToken: '' } as AuthUser;
    }

    private async _login(token: string) {
        setLoginToken(token);
        const user = await this.getCurrentUser();
        if (!user?.username) { throw new Error(USER_NOT_FOUND); }
        setJwtToken(token);
        setRefreshToken('');
        this._storeProgagricaTokens(user);
        return user;
    }

    public async getCurrentUser() {
        let queryParams: string[] = [];
        queryParams.push(`use_cache=false`);
        queryParams.push(`environment_name=${import.meta.env.VITE_ENVIRONMENT_NAME}`);
        queryParams.push(`app_version=${import.meta.env.VITE_APP_VERSION}`);
        let queryString = queryParams.length ? `?${queryParams.join('&')}` : "";
        const url = `${import.meta.env.VITE_ROGO_API_URL}/auth/me${queryString}`;

        const response = await axios.get<AuthUser>(url, REQUEST_CONFIG_AUTH());
        const user = response.data;

        this._storeProgagricaTokens(user);
        
        return { ...user, token: getLoginToken()! };
    }
    
    private _storeProgagricaTokens(user: AuthUser){
        // TODO if we are going to store these here we should also use expiring cookies 
        localStorage.setItem('proagrica_access_token', user.proagrica_auth?.access_token || '');
        localStorage.setItem('proagrica_refresh_token', user.proagrica_auth?.refresh_token || '');
        // localStorage.setItem('proagrica_auth', JSON.stringify(user.proagrica_auth));
    }
}