import { MRT_Cell, MRT_ColumnDef } from "material-react-table";
import { Accessor } from "../../../utilities/types";
import { FieldSet } from 'airtable';
import { Box } from '@mui/material';
import { BaseDataModel } from "../../../api";

interface NumericColumnOptions<T extends FieldSet, U extends BaseDataModel<T>> {
  accessorKeyOrFn?: Accessor<number, T, U>;
  money?: boolean;
  decimals?: number;
  aggregationFn?: 'sum' | 'average' | 'min' | 'max';
  slider?: boolean;
  sliderStepSize?: number;
}

function getNumericColumnText<T extends FieldSet, U extends BaseDataModel<T>>(cell: MRT_Cell<U, unknown>, money: boolean, decimals: number) {
  return cell.getValue<number>()
    ? `${money ? '$' : ''}${Math.round(cell.getValue<number>() * (1 / decimals)) / (1 / decimals)}`
    : '-';
}

export const numericColumn = <T extends FieldSet, U extends BaseDataModel<T>>(
  header: string, 
  {
    accessorKeyOrFn = undefined,
    money = false,
    decimals = 0.01,
    aggregationFn = undefined,
    slider = false,
    sliderStepSize = 10

  }: NumericColumnOptions<T, U> = {}
) => ({
  id: header.toLowerCase(),
  accessorFn: (row) => typeof accessorKeyOrFn === 'function' ? accessorKeyOrFn(row) : (accessorKeyOrFn ? (row[accessorKeyOrFn] as number | undefined) ?? 0 : 0),
  header,
  size: 100,
  filterVariant: slider ? 'range-slider' : 'range',
  filterFn: 'betweenInclusive',
  aggregationFn: aggregationFn,
  muiFilterSliderProps: (props) => ({
    marks: false,
    step: sliderStepSize,
  }),
  Cell: ({ cell }) => (
    <Box
      component="span"
      sx={(theme) => ({
        borderRadius: '0.25rem',
        maxWidth: '9ch',
        p: '0.25rem',
        textAlign: 'right',
        display: 'block',
      })}
    >
      {getNumericColumnText<T, U>(cell, money, decimals)}
    </Box>
  ),
  AggregatedCell: !!aggregationFn ? ({ cell }) => (
    <Box
      component="span"
      sx={(theme) => ({
        fontWeight: 'bold',
        borderRadius: '0.25rem',
        color: '#000',
        maxWidth: '9ch',
        p: '0.25rem',
        textAlign: 'right',
        display: 'block',
      })}
    >
      {getNumericColumnText<T, U>(cell, money, decimals)}
    </Box>
  ) : null,
}) as MRT_ColumnDef<U>;
