import { Drawer, Box, Button } from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { FeatureCollection, Point } from "geojson";
import { useState } from "react";
import GoogleMapComponent from "./GoogleMapComponent";
import { JobsDashboardTable } from "./JobsDashboardTable";

interface Props {
    setLoading: (loading: boolean) => void;
}

const JobsDashboardWithLocalizationProvider = (props: Props) => {
    const [rightSideDrawerOpen, setRightSideDrawerOpen] = useState(false);
    const [displayBoundaries, setDisplayBoundaries] = useState<Record<string, any>>({});
    const [displayedZones, setDisplayedZones] = useState<Record<string, any>>({});
    const [displayedPoints, setDisplayedPoints] = useState<Record<string, FeatureCollection<Point>>>({});
    //App.tsx or AppProviders file
    return (
        <LocalizationProvider dateAdapter={AdapterDayjs} >
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    maxHeight: '100vh',
                    width: '100%'
                }}
            >
                {/* border={2} */}
                <Box
                    sx={{
                        flexGrow: 1,
                        overflow: 'auto',
                        transition: 'margin 0.3s',
                        marginRight: rightSideDrawerOpen ? '0vw' : '-40vw',
                    }}
                >
                    <JobsDashboardTable
                        setDrawerOpenState={(open: boolean) => setRightSideDrawerOpen(open)}
                        setDisplayBoundaries={setDisplayBoundaries}
                        setDisplayedZones={setDisplayedZones}
                        setDisplayedPoints={setDisplayedPoints}
                        setLoading={props.setLoading}
                        drawerOpen={rightSideDrawerOpen}
                    />
                </Box>
                {/* Right side MUI drawer */}
                <Drawer
                    variant='persistent'
                    anchor="right"
                    open={rightSideDrawerOpen}
                    onClose={() => {
                        setRightSideDrawerOpen(false);
                    }}
                    sx={{
                        flexShrink: 0,
                        width: '40vw',
                        '& .MuiDrawer-paper': {
                            width: '40vw',
                            boxSizing: 'border-box'
                        }
                    }}
                >
                    <Box sx={{
                        p: '1rem',
                        height: '100%',
                        overflow: 'auto'
                    }}>
                        <GoogleMapComponent
                            showPointsAtZoom={14}
                            boundaries={displayBoundaries}
                            points={displayedPoints}
                            zones={displayedZones}
                        />
                    </Box>
                </Drawer >
            </Box>
        </LocalizationProvider >
    )
};

export default JobsDashboardWithLocalizationProvider;
