import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import { useTheme } from '@mui/material';

interface ButtonAppBarProps {
  drawerOpen: boolean;
  setDrawerVisibility: (open: boolean) => void;
  title: string;
}

export default function CustomToolbar(props: ButtonAppBarProps) {
  const theme = useTheme();

  return (
    <Box style={{ zIndex: theme.zIndex.drawer + 1 }} sx={{ flexGrow: 1 }}>
      <AppBar position="static">
        <Toolbar>
          <IconButton
            size="large"
            edge="start"
            color="inherit"
            aria-label="menu"
            onClick={() => props.setDrawerVisibility(!props.drawerOpen)}
            sx={{ mr: 2 }}
          >
            <MenuIcon />
          </IconButton>
          <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
            {props.title}
          </Typography>
        </Toolbar>
      </AppBar>
    </Box>
  );
}