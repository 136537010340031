import { useEffect, useState, useContext } from "react";
import { MarkJobReadyForm, submitJobReadyForm } from "./MarkJobReadyForm";

import { UserContext } from '../../hooks/UserContext';
import CustomAutocomplete from "../helpers/CustomAutocomplete";
import { Box } from "@mui/material";
import { toast } from "react-toastify";
import { LoadingComponent } from "../helpers/LoadingComponent";
import { sleep } from "../../utilities/utils";
import * as Sentry from "@sentry/react";
import { API, Company, Job } from '../../api';
import { INVALID_USERCONTEXT } from "../../utilities/constants";
import { Oops } from "../../utilities/oops";

interface Props {
    setLoading: (loading: boolean) => void;
}

export function MarkJobReadyPage(props: Props) {
    const [jobs, setJobs] = useState<Job[]>([]);
    const [companyOptions, setCompanyOptions] = useState<Company[]>([]);
    const [selectedCompany, setSelectedCompany] = useState<Company | null>(null);
    const [isLoading, setIsLoading] = useState(false);

    const userContext = useContext(UserContext);

    async function onCompanyChanged(company: Company | null) {
        try {
            setIsLoading(true);
            setJobs([]);
            setSelectedCompany(company);
            if (company) {
                const jobs = await API.Data.Jobs.get.byCompany(company);
                const unreadyJobs = jobs.filter(job => !job.dateFieldReady);
                setJobs(unreadyJobs);
            }
        } catch (error: any) {
            Oops.handleError(error, "MarkJobReadyPage.onCompanyChanged");
        } finally {
            setIsLoading(false);
        }
    }

    async function markJobsReady(data: any) {
        setIsLoading(true);
        setIsLoading(true);
        await submitJobReadyForm(data.fieldPriority, data.submissionNotes, data.selectedJobs);
        await sleep(1000);
        await onCompanyChanged(selectedCompany);
        toast.success(`Marked ${data.selectedJobs.length} jobs as ready!`);
        setIsLoading(false);
    }

    useEffect(() => {
        // React advises to declare the async function directly inside useEffect
        async function getData() {
            try {
                setIsLoading(true);
                if (userContext == undefined || !userContext.id) {
                  Sentry.captureMessage("userContext is undefined: MarkJobReadyPage.tsx", "info");
                  toast.error(INVALID_USERCONTEXT);
                  return;
                }
                const user = await API.Data.Contacts.get.byId(userContext.id);
                const companyRecords = await API.Data.Companies.get.byContact(user);
                companyRecords.sort((companyA, companyB) => {
                    if (companyA.name === "Rogo AG" || companyA.name === "ROGO Software") return -1;
                    if (companyB.name === "Rogo AG" || companyB.name === "ROGO Software") return 1;
                    return companyA.name.localeCompare(companyB.name);
                });
                setCompanyOptions(companyRecords);
                if (companyRecords.length === 1) {
                    const companyRecord = companyRecords[0];
                    await onCompanyChanged(companyRecord);
                }
            } catch (error) {
                Oops.handleError(error, "MarkJobReadyPage.getData");
            } finally {
                setIsLoading(false);
            }
        };

        // You need to restrict it at some point
        // This is just dummy code and should be replaced by actual
        if (!jobs.length) {
            getData();
        }
    }, [userContext]);

    return (<>
        <br />
        <div id="choose-your-company">
            {companyOptions.length > 1 &&
                <CustomAutocomplete
                    optionLabelField="name"
                    loading={false}
                    options={companyOptions}
                    selectedOption={selectedCompany}
                    onChange={onCompanyChanged}
                    label="Choose your company"
                    style={{ margin: 10 }}
                    tooltip="Select a company to submit jobs for. This is only for ROGO employees"
                />
            }
            {isLoading ?
                <Box display="flex" justifyContent="center" alignItems="center">
                    <LoadingComponent />
                </Box> :
                <MarkJobReadyForm
                    intialSelectedJobs={[]}
                    jobs={jobs}
                    onSubmit={markJobsReady}
                    onCancel={() => { }} />
            }
        </div >
    </>);
}