import { Box, Button, TextField, Typography } from "@mui/material";
import React, { ChangeEvent } from "react";
import { useNavigate } from "react-router-dom";
import { LoadingComponent } from "../helpers/LoadingComponent";
import { API } from "../../api";
import { Oops } from "../../utilities/oops";
import { DEFAULT_PAGE } from "../../utilities/constants";
import { NEW_USER_TOKEN } from "../../api/common";

interface RegisterProps {
    setLoggedIn: (user: any) => void;
}

export default function Register(props: RegisterProps) {
    const [username, setEmail] = React.useState('');
    const [firstName, setFirstName] = React.useState('');
    const [lastName, setLastName] = React.useState('');
    const [emailError, setEmailError] = React.useState('');
    const [password, setPassword] = React.useState('');
    const [passwordError, setPasswordError] = React.useState('');
    const [confirmPassword, setConfirmPassword] = React.useState('');
    const [confirmPasswordError, setConfirmPasswordError] = React.useState('');
    const [loading, setLoading] = React.useState(false);
    const [error, setError] = React.useState('');

    const navigate = useNavigate();

    const handleSubmit = async (event: { preventDefault: () => void; }) => {
        event.preventDefault();
        setLoading(true);
        if (emailError || passwordError || confirmPasswordError) {
            setLoading(false);
            return;
        }
        setError('');

        if (!isUsernameAndPasswordValid()) { return; }

        try {
            const user = await API.Authentication.registerUser(username, password, firstName, lastName);
            if (user) {
                if (user.token == NEW_USER_TOKEN){
                    navigate("/registration-notice");
                } else {
                    props.setLoggedIn(user);
                    navigate(DEFAULT_PAGE);
                }
            }
        } catch (error: any) {
            Oops.handleError(error, "Authentication.Register.handleSubmit", username);
        } finally {
            setLoading(false);
        }
    };

    const emailPattern = /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/;

    function isUsernameAndPasswordValid() {
        var isValid = true;

        if (!username) {
            setEmailError('Email cannot be empty');
            setLoading(false);
            isValid = false;
        }
        if (!password) {
            setPasswordError('Password cannot be empty');
            setLoading(false);
            isValid = false;
        }

        if (!confirmPassword) {
            setConfirmPasswordError('Password cannot be empty');
            setLoading(false);
            isValid = false;
        }

        if (password !== confirmPassword) {
            setConfirmPasswordError('Passwords do not match');
            setLoading(false);
            isValid = false;
        }

        return isValid;
    }

    function handleEmailChange(event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>): void {
        //throw new Error("Function not implemented.");
        setEmailError('');
        // validate that username is an email with regex
        if (!emailPattern.test(event.target.value.toString())) {
            setEmailError("Invalid email address");
        }

        setEmail(event.target.value);
    }

    function handlePasswordChange(event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>): void {
        setPasswordError('');
        const password = event.target.value;
        if (password == '') {
            setPasswordError('Password cannot be empty')
            return;
        }

        if (password.length < 8) {
            setPasswordError('Password must be at least 8 characters');
            return;
        }


        setPassword(password);
        if (password != confirmPassword) {
            setConfirmPasswordError('Passwords do not match');
            return;
        }

    }

    function handleConfirmPasswordChange(event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>): void {
        setConfirmPasswordError('');
        const confirmPassword = event.target.value;
        if (confirmPassword == '') {
            setConfirmPasswordError('Password cannot be empty')
            return;
        }

        if (confirmPassword.length < 8) {
            setConfirmPasswordError('Password must be at least 8 characters');
            return;
        }

        setConfirmPassword(password);
        if (confirmPassword != password) {
            setConfirmPasswordError('Passwords do not match');
            return;
        }
    }

    return (
        <Box
            maxWidth="md" margin="auto"
            component="form"
            onSubmit={handleSubmit}
            noValidate
            sx={{
                mt: 1,
            }}
        >
            {(loading) && (
                <Box display="flex" justifyContent="center" alignItems="center">
                    <LoadingComponent />
                </Box>
            )}
            <Box display="flex" justifyContent="space-between">
                <TextField
                    variant="outlined"
                    margin="normal"
                    required
                    disabled={loading}
                    fullWidth
                    id="firstName"
                    label="First Name"
                    name="firstName"
                    autoFocus
                    onBlur={(e) => setFirstName(e.target.value)}
                    sx={{ width: '48%' }}
                    data-sentry-component="First Name Field"
                />
                <TextField
                    variant="outlined"
                    margin="normal"
                    required
                    disabled={loading}
                    fullWidth
                    id="lastName"
                    label="Last Name"
                    name="lastName"
                    autoFocus
                    onBlur={(e) => setLastName(e.target.value)}
                    sx={{ width: '48%' }}
                    data-sentry-component="Last Name Field"
                />
            </Box>
            <TextField
                variant="outlined"
                margin="normal"
                required
                error={!!emailError}
                helperText={emailError}
                disabled={loading}
                fullWidth
                id="email"
                label="Email"
                name="email"
                autoComplete="email"
                autoFocus
                onBlur={handleEmailChange}
                data-sentry-component="Email Field"
            />
            <TextField
                variant="outlined"
                margin="normal"
                error={!!passwordError}
                helperText={passwordError}
                required
                disabled={loading}
                fullWidth
                name="password"
                label="Password"
                type="password"
                id="password"
                autoComplete="current-password"
                //value={password}
                onBlur={handlePasswordChange}
                data-sentry-component="Password Field"
            />
            <TextField
                variant="outlined"
                margin="normal"
                error={!!confirmPasswordError}
                helperText={confirmPasswordError}
                required
                disabled={loading}
                fullWidth
                name="Confirm password"
                label="Confirm Password"
                type="password"
                id="Confirm password"
                autoComplete="confirm-password"
                //value={password}
                onBlur={handleConfirmPasswordChange}
                data-sentry-component="Confirm Password Field"
            />
            {error &&
                <Typography
                    variant="body2"
                    color="textSecondary"
                    align="center"
                    style={{ color: 'red' }}
                >
                    {error}
                </Typography>}

            <Button type="submit" fullWidth variant="contained" color="primary" disabled={loading} data-sentry-component="Submit Button">
                Register
            </Button>
        </Box>
    );
}
