import { MRT_ColumnDef, MRT_FilterFn, MRT_TableInstance } from "material-react-table";
import { Accessor } from "../../../utilities/types";
import { FieldSet } from 'airtable';
import { Autocomplete, TextField, Tooltip, Typography } from '@mui/material';
import { BaseDataModel } from "../../../api";

interface MultiSelectColumnOptions<T extends FieldSet, U extends BaseDataModel<T>> {
  accessorKeyOrFn?: Accessor<string, T, U>;
  size?: number;
  filterVariant?: string;
  filterSelectOptions?: string[];
  filterFn?: MRT_FilterFn<any>;
}
export const multiSelectColumn = <T extends FieldSet, U extends BaseDataModel<T>>(
  header: string,
  {
    accessorKeyOrFn = undefined,
    size = 100,
    filterVariant = 'multi-select',
    filterSelectOptions = [],
    filterFn = 'arrIncludes'
  }: MultiSelectColumnOptions<T,U> = {}
) => ({
  id: header.toLowerCase(),
  accessorFn: (row) => {
    const value = (typeof accessorKeyOrFn === 'function') ? accessorKeyOrFn(row) : (accessorKeyOrFn ? row[accessorKeyOrFn]?.toString() ?? "" : "");
    return Array.isArray(value) ? value.join(', ') : value;
  },
  header,
  minSize: size,
  maxSize: size,
  // enableGlobalFilter: true,
  filterVariant: filterVariant,
  filterSelectOptions: filterSelectOptions,
  filterFn: filterFn,
  Filter: ({ column, table }: { column: any; table: MRT_TableInstance<any> }) => (
    <Autocomplete
      multiple
      options={filterSelectOptions}
      onChange={(event, value) => {
        column.setFilterValue(value);
      }}
      renderInput={(params) => (
        <TextField {...params} variant="standard" placeholder="Filter" size="small" sx={{ paddingTop: '3px' }} />
      )}
        />
      ),
  GroupedCell: ({ cell }) => {
    const value = cell.getValue<string>();
    return (
      <Tooltip title={value}>
        <Typography noWrap>B. {value}</Typography>
      </Tooltip>
    );
  }
}) as MRT_ColumnDef<U>;
