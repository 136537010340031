import { useContext, useEffect, useState } from "react";
import { Controller, SubmitErrorHandler, SubmitHandler, useForm } from "react-hook-form";
import { UserContext } from "../../hooks/UserContext";
import { JobsPriorityOvrd } from "../../utilities/types";
import { TextField, Checkbox, Select, MenuItem, Button, Box, Typography, Grid, Accordion, AccordionDetails, Autocomplete, Tooltip, createFilterOptions } from "@mui/material";
import AccordianSummary from '@mui/material/AccordionSummary';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import FormField from "../helpers/FormField";
import { CREATE_NEW_GROWER } from "../../utilities/constants";
import { toast } from "react-toastify";
import PortalTooltip from "../helpers/PortalTooltip";
import { LoadingComponent } from "../helpers/LoadingComponent";
import { API, Branch, Company, Deal, Grower, Job } from "../../api";
import { Oops } from "../../utilities/oops";
import { Publish, Upload } from "@mui/icons-material";

const CreateNewGrower = { 
    id: CREATE_NEW_GROWER, 
    "name": CREATE_NEW_GROWER 
} as Grower;

interface ManualEntryFormProps extends Partial<Job> {
    isZone: boolean;
    growers: Grower[];
    selectedDeal: Deal;
    allDeals: Deal[];
    company: Company | null;
    selectedBranches: Branch[];
}


const filter = createFilterOptions<Grower | { id: string, "name": string }>();

interface Job_Extended extends Job {
    sendGrowerSamplingNotification: boolean;
}

export function ManualEntryForm(props: ManualEntryFormProps) {
    const userContext = useContext(UserContext);
    const [growerSelected, setGrowerSelected] = useState<Grower | { id: string, "name": string }>();
    const [loading, setLoading] = useState<boolean>(false);
    const [deal, setDeal] = useState<Deal | { id: 'custom', label: 'Create New Configuration' }>(props.selectedDeal);
    const [jobType, setJobType] = useState('');
    const [growerPhone, setGrowerPhone] = useState('');

    const { register, handleSubmit, watch, control, reset, setValue, trigger, formState: { errors } } = useForm<Job_Extended>({
        defaultValues: {
            ...props,
            growerIds: [CreateNewGrower.id],
            fieldPriority: 'Regular Turn (default)',
            submitterEmail: userContext?.email,
            submitterName: userContext?.full_name,
        }
    });

    useEffect(() => {
        updateDeal();
    }, [watch("frequencyOfMicros"), watch("density"), watch("depth"), watch("testPackage"), watch("dealIds"), deal]);

    // useEffect(() => {
    //     if (watch("growerRecord")?.id === CREATE_NEW_GROWER) {
    //         setValue("growerRecordId", CREATE_NEW_GROWER);
    //     } else {
    //         setValue("growerRecordId", watch("growerRecord")?.id);
    //     }
    // }, [watch("growerRecord")]);

    const updateDeal = (() => {
        const deal = props.allDeals.find((deal: Deal) => {
            return deal.id === watch("dealIds")?.at(0);
        });
        if (deal) {
            setDeal(deal);
            setValue("dealIds", [deal.id]);
            deal.type == undefined ? setJobType('Grid') : setJobType(deal.type);
        } else {
            setDeal({ id: 'custom', label: 'Create New Configuration' });
            setValue("dealIds", ['custom']);
            setJobType('');
        }
    });

    const getDealLabel = (option: Deal | { id: string, label: string }) => {
        if (option && option.id === 'custom') {
            return 'Create New Configuration';
        }

        const d = option as Partial<Deal>;
        const density = d.density || 'Not specified';
        const depth = d.depth || 'Not specified';
        const dealType = d.type || 'Not specified';
        const testPackage = d.testPackage || 'No test package specified';
        const dealLabelFields = [density, dealType, depth, testPackage];
        const specialTag = d.specialTag;
        if (specialTag) {
            dealLabelFields.push(specialTag);
        }
        const season = d.season || 'Not specified';
        dealLabelFields.push(season);
        return `${density} Acre ${dealLabelFields.join(" - ")}`;
    };


    const onSubmit: SubmitHandler<Job> = async data => {
        setLoading(true);
        var username = userContext?.email ?? "";
        try {
            if (data.dealIds?.at(0) === 'custom') {
                if (!window.confirm('Custom Configuration Selected. Proceed?')) {
                    return;
                }
            }
            data.dealIds?.at(0) === 'custom' ? data.dealIds = [] : data.dealIds = data.dealIds;
            if (data.growerIds?.at(0) === CREATE_NEW_GROWER) {
                data.growerIds = [];
            }
            if (data.growerName.startsWith('New Grower: ')) {
                data.growerName = data.growerName.replace(/^New Grower: /, '');
            }
            if (props.selectedBranches.length) {
                data.branchIds = [props.selectedBranches[0].id];
            }

            if (data.growerIds.length > 0 && data.growerIds[0] !== '' && data.growerPhoneNumber !== '') {
                API.Data.Growers.update.field(data.growerIds[0], "Sampling Notification Phone Number", data.growerPhoneNumber);
            }
            await toast.loading('Job submitting...');

            const newJob: Job = {
                id: data.id || '',
                name: data.name || '',
                edits: data.edits || '',
                submitter: data.submitter || '',
                status: data.status || '',
                testPackage: data.testPackage || '',
                season: data.season || '',
                sampleChanges: data.sampleChanges || '',
                fieldId: data.fieldId || '',
                fieldName: data.fieldName || '',
                eventId: data.eventId || '',
                externalId: data.externalId || '',
                jobFlags: data.jobFlags || [],
                boundaryChangeType: data.boundaryChangeType,
                fieldPriority: data.fieldPriority || 'Regular Turn (default)',
                boundaryAcres: data.boundaryAcres || 0,
                depth: data.depth || 0,
                density: data.density,
                frequencyOfMicros: data.frequencyOfMicros,
                isBillingApproved: data.isBillingApproved || false,
                isMarkedReady: data.isMarkedReady || false,
                isBoundaryChanged: data.isBoundaryChanged || false,
                isBoundaryApproved: data.isBoundaryApproved || false,
                isPointsChanged: data.isPointsChanged || false,
                isTestJob: data.isTestJob || false,
                isPointsAttached: data.isPointsAttached || false,
                isReadyToSample: data.isReadyToSample || false,
                dateCreated: data.dateCreated || new Date(),
                dateFileLastEdited: data.dateFileLastEdited,
                dateSampled: data.dateSampled,
                dateShipped: data.dateShipped,
                dateLabResultsSent: data.dateLabResultsSent,
                dateFieldReady: data.dateFieldReady,
                submissionNotes: data.submissionNotes,
                submitterEmail: data.submitterEmail,
                submitterName: data.submitterName,
                labSubmittalId: data.labSubmittalId || '',
                labName: data.labName,
                labAccountNumber: data.labAccountNumber,
                labSubmissionCode: data.labSubmissionCode,
                labInstructions: data.labInstructions || '',
                attachments: data.attachments || {},
                files: data.files || {},
                companyName: data.companyName || '',
                farmName: data.farmName,
                growerName: data.growerName || '',
                growerPhoneNumber: data.growerPhoneNumber,
                branchIds: data.branchIds || [],
                dealIds: data.dealIds || [],
                growerIds: data.growerIds || [],
            };

            await API.Data.Jobs.create.one(newJob, "Created Portal Single");
            await toast.dismiss();
            setLoading(false);
            await toast.success('Job submitted successfully!');

            // TODO: need to reset form
            reset({
                ...props,
                growerIds: [CREATE_NEW_GROWER],
                fieldPriority: 'Regular Turn (default)',
                submitterEmail: userContext?.email,
                submitterName: userContext?.full_name,
                growerName: '',
                growerPhoneNumber: '',
                fieldName: '',
                files: undefined,
                // boundaryGeoJSON: undefined,
                // recordBoundary: false,
                // collectBoundary: false,
            });
            setGrowerSelected(undefined);
            setDeal(props.selectedDeal);
            setJobType('');
        } catch (error: any) {
            await toast.dismiss();
            Oops.handleError(error, "SubmitJobs.ManualEntryForm.onSubmit");
        } finally {
            setLoading(false);
        }
    }

    const onError: SubmitErrorHandler<Job> = (errors) => {
        const data = watch("growerName");
        toast.error('Please fill in all required fields correctly.');
        for (const errorKey of Object.keys(errors)) {
            // @t-ignore
            toast.error((errors as Record<string, any>)[errorKey]?.message);
        }
    };

    function FileField(
        title: string,
        helpText: string,
        key: keyof Job["files"],
        data: Job,
        required?: boolean,
        hidden?: boolean,

    ) {
        if (hidden) return;
        const handleRemoveFile = (index: number) => {
            const updatedFiles = Array.from(data.files[key] as File[]);
            updatedFiles.splice(index, 1);
            setValue(`files.${key}`, updatedFiles.length ? updatedFiles : undefined);
        };

        const isRequired = () => {
            if (key === "boundarySHP") {
                return jobType === 'grid' || jobType === 'modgrid';
            }
            if (key === "zonesSHP") {
                return jobType === 'zones';
            }
            return required;
        };

        return (
            <>
                <FormField
                    title={title}
                    required={required}
                    helpText={helpText}
                    // @ts-ignore
                    error={errors[key]?.message}
                    input={
                        <Box
                            sx={{
                                '& > :not(style)': { m: 1 },
                            }}
                        >
                            <input
                                accept="*/*"
                                style={{ display: 'none' }}
                                id={`contained-button-file-${key}`}
                                multiple
                                type="file"
                                {...register(`files.${key}`, {
                                    required: required ? "File upload required for Job/Configuration type" : undefined,
                                })}
                            />
                            <label htmlFor={`contained-button-file-${key}`}>
                                <Button variant="contained" component="span">
                                    <Upload className="icon-in-button" /> Upload
                                </Button>
                            </label>
                            {data.files && data.files[key] && (
                                <div>
                                    {Array.from(data.files[key] ? data.files[key] as File[] : []).map((file, index) => (
                                        <Box key={index} display="flex" alignItems="center">
                                            <Typography>{file.name}</Typography>
                                            <Button
                                                onClick={() => handleRemoveFile(index)}
                                                variant="outlined"
                                                color="error"
                                                size="small"
                                                sx={{ ml: 1 }}
                                            >
                                                Remove
                                            </Button>
                                        </Box>
                                    ))}
                                </div>
                            )}
                        </Box>
                    }
                />
            </>
        );
    }

    const isReadyToSample = watch("isReadyToSample");

    const syncMethod = props.selectedDeal.syncMethod;
    const secondSyncMethod = props.selectedDeal.syncMethod2;
    const labIDRequired = syncMethod === 'Lab ID' || syncMethod === 'Lab ID Immut' || secondSyncMethod === 'Lab ID';

    const filesNotNeeded = props.company ? props.company.portalConfigurationOptions.includes("Submission: No Files Needed") : false;
    const filesNeeded = !filesNotNeeded;

    const syncIDIsEventID = syncMethod === 'Event ID' || syncMethod === 'Event ID Immut';
    const eventIDRequired = filesNeeded && syncIDIsEventID;

    return (
        <>
            {loading && (
                <Box
                    sx={{
                        position: 'fixed',
                        top: 0,
                        left: 0,
                        width: '100%',
                        height: '100%',
                        backgroundColor: 'rgba(255, 255, 255, 0.8)',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        zIndex: 1300,
                    }}
                >
                    <LoadingComponent />
                    <Typography variant="h5">Submitting Job...</Typography>
                </Box>
            )}
            <Grid
                alignItems="stretch"
                alignContent="stretch"
                container
                maxWidth="md"
                margin="auto"
                component="form"
                spacing={1}
                onSubmit={handleSubmit(onSubmit, onError)}
            >
                <Grid item xs={12} className="single-upload-group">
                    <Accordion defaultExpanded={false}>
                        <AccordianSummary expandIcon={<ExpandMoreIcon />}>
                            <Tooltip title="Change your Sampling configuration here" arrow>
                                <Typography variant='h6'>Sampling Configuration Edits</Typography>
                            </Tooltip>
                        </AccordianSummary>
                        <AccordionDetails>
                            <Grid
                                alignItems="stretch"
                                alignContent="stretch"
                                container
                                maxWidth="md"
                                margin="auto"
                                component="form"
                                spacing={1}
                            >
                                <FormField
                                    title="Update Deal:"
                                    helpText="Select a deal"
                                    required
                                    error={errors.dealIds?.message}
                                    input={
                                        <Autocomplete
                                            fullWidth={true}
                                            size="small"
                                            options={[...props.allDeals, { id: 'custom', label: 'Create New Configuration' }]}
                                            isOptionEqualToValue={(option, value) => option.id === value.id}
                                            value={props.allDeals.find((deal) => deal.id === watch("dealIds")?.at(0)) || { id: 'custom', label: 'Create New Configuration' }}
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params} />
                                            )}

                                            // onBlur allows searching for the deal by name
                                            onBlur={(event) => {
                                                const inputValue = (event.target as HTMLInputElement).value;
                                                const selectedDeal = (props.allDeals.find(deal => getDealLabel(deal) === inputValue) || { id: 'custom', label: 'Create New Configuration' }) as Deal;

                                                if (selectedDeal.id === 'custom') {
                                                    setDeal({ id: 'custom', label: 'Create New Configuration' });
                                                    setValue("dealIds", ['custom']);
                                                } else {
                                                    setDeal(selectedDeal);
                                                    setValue("dealIds", [selectedDeal.id]);
                                                    setValue("frequencyOfMicros", props.selectedDeal.frequencyOfMicros);
                                                    setValue("density", props.selectedDeal.density);
                                                    setValue("depth", props.selectedDeal.depth);
                                                    const labPackage = props.selectedDeal.testPackage;
                                                    setValue("testPackage", Array.isArray(labPackage) ? labPackage[0] : labPackage || 'No test package specified');
                                                }
                                            }}
                                            getOptionLabel={getDealLabel}
                                        />
                                    }

                                />
                                <FormField
                                    title="Frequency of Micros"
                                    helpText="Frequency of Micros in the configuration"
                                    required
                                    error={errors.frequencyOfMicros?.message}
                                    input={
                                        <TextField
                                            size="medium"
                                            variant="outlined"
                                            value={watch("frequencyOfMicros")}
                                            fullWidth {...register("frequencyOfMicros", {
                                                required: "Frequency of Micros is required",
                                                pattern: {
                                                    value: /^[1-9][0-9]*$/,
                                                    message: 'Please enter a valid integer'
                                                }
                                            })
                                            }
                                            {...updateDeal}
                                        />}
                                />
                                <FormField
                                    title={'Depth (in.)'}
                                    helpText="Enter the depth in inches"
                                    required
                                    error={errors.depth?.message}

                                    input={
                                        <TextField variant="outlined" fullWidth color={errors.depth ? "error" : "primary"} {
                                            ...register("depth", {
                                                required: "Enter a valid depth in inches",
                                                pattern: {
                                                    value: /-?\d+(\.\d+)?/,
                                                    message: 'Please enter a valid number in inches'
                                                }
                                            })
                                        }
                                            {...updateDeal}
                                        />}
                                />

                                <FormField
                                    title={'Density'}
                                    required
                                    error={errors.density?.message}
                                    helpText="Enter the density of the configuration"
                                    input={
                                        <TextField variant="outlined" fullWidth color={errors.density ? "error" : "primary"} {
                                            ...register("density", {
                                                required: "Enter a valid density",
                                                pattern: {
                                                    value: /-?\d+(\.\d+)?/,
                                                    message: 'Please enter a valid number in density'
                                                }
                                            })
                                        }
                                            {...updateDeal}
                                        />}
                                />

                                <FormField
                                    title={'Lab Package'}
                                    error={errors.testPackage?.message}
                                    required
                                    helpText="Enter the lab package for the configuration"
                                    input={
                                        <TextField variant="outlined" fullWidth color={errors.testPackage ? "error" : "primary"} {
                                            ...register("testPackage", {
                                                required: "Enter a valid lab package",
                                            })
                                        }
                                            {...updateDeal}
                                        />}
                                />
                            </Grid>
                        </AccordionDetails>
                    </Accordion>
                </Grid>

                {/* spacing */}
                {/* <Grid item xs={12}>
                    <Box mt={4} />
                </Grid> */}


                <Grid item xs={12} className="single-upload-group">
                    <Accordion defaultExpanded={true}>
                        <AccordianSummary expandIcon={<ExpandMoreIcon />}>
                            <PortalTooltip title="Grower Details">
                                <Typography variant='h6'>Grower Details</Typography>
                            </PortalTooltip>
                        </AccordianSummary>
                        <AccordionDetails >
                            <Grid
                                alignItems="stretch"
                                alignContent="stretch"
                                container
                                maxWidth="md"
                                margin="auto"
                                spacing={1}
                            >
                                <FormField
                                    title={"Grower Name"}
                                    required
                                    error={errors.growerName?.message}
                                    input={
                                        <Controller
                                            name="growerName"
                                            control={control}
                                            rules={{ required: "Grower Name is Required" }}
                                            render={({ field: { onChange, value } }) => (
                                                <Autocomplete
                                                    fullWidth={true}
                                                    value={growerSelected}
                                                    options={[...props.growers]}
                                                    freeSolo
                                                    renderInput={(params) => (
                                                        <TextField
                                                            {...params}
                                                            label="Grower Name"
                                                            variant="outlined"
                                                        />
                                                    )}
                                                    filterOptions={(options, params) => {
                                                        const filtered = filter(options, params);

                                                        if (params.inputValue !== '' && !props.growers.some(grower => grower.name.toLowerCase() === params.inputValue.toLowerCase())) {
                                                            filtered.push({
                                                                id: CREATE_NEW_GROWER,
                                                                "name": `New Grower: ${params.inputValue}`
                                                            });
                                                        }

                                                        return filtered;
                                                    }}
                                                    getOptionLabel={(grower) => {
                                                        if (watch("growerIds")?.at(0) !== CREATE_NEW_GROWER && watch("growerIds")?.at(0) !== undefined && watch("growerIds")?.at(0) !== "") {
                                                            return (grower as Grower)?.name || grower as string;
                                                        }
                                                        if (typeof grower === 'string') {
                                                            return grower.startsWith("New Grower") ? grower : `New Grower: ${grower}`;
                                                        }

                                                        return (grower as Grower)?.name ?? "";
                                                    }}
                                                    onChange={(event, value) => {
                                                        if (event.type == 'blur' || event.type == 'keydown') {
                                                            if (typeof value === 'string') {
                                                                const newValue = (value as string).startsWith("New Grower:") ? value.replace("New Grower: ", "") : value;
                                                                if (newValue !== '' && !props.growers.some(grower => grower.name.toLowerCase() === newValue.toLowerCase())) {
                                                                    setValue("growerName", newValue);
                                                                    setValue("growerIds", [CREATE_NEW_GROWER]);
                                                                    setGrowerSelected({ id: CREATE_NEW_GROWER, "name": value });
                                                                    trigger("growerName");
                                                                    return;
                                                                }
                                                                const grower = props.growers.find(grower => grower.name.toLowerCase() === newValue.toLowerCase());
                                                                if (grower) {
                                                                    setValue("growerName", grower.name as string);
                                                                    setValue("growerIds", [grower?.id]);
                                                                    setGrowerSelected(grower as Grower);
                                                                }
                                                            } else if (value && value.id === CREATE_NEW_GROWER) {
                                                                setValue("growerName", (value as Grower)?.name ?? "");
                                                                setValue("growerIds", [CREATE_NEW_GROWER]);
                                                                setGrowerSelected(value);
                                                                trigger("growerName");
                                                            } else if (typeof value === 'object') {
                                                                setValue("growerName", (value as Grower)?.name ?? "");
                                                                setValue("growerIds", value?.id ? [value?.id] : []);
                                                                setGrowerSelected(value as Grower);
                                                            }
                                                            return;
                                                        }
                                                        if (typeof value === 'string') {
                                                            setValue("growerName", value);
                                                            setValue("growerIds", [CREATE_NEW_GROWER]);
                                                            setGrowerSelected({ id: CREATE_NEW_GROWER, "name": value });
                                                        } else if (value && value.id === CREATE_NEW_GROWER) {
                                                            setValue("growerName", (value as Grower)?.name ?? "");
                                                            setValue("growerIds", [CREATE_NEW_GROWER]);
                                                            setGrowerSelected(value);
                                                        } else {
                                                            setValue("growerName", (value as Grower)?.name ?? "");
                                                            setValue("growerIds", value?.id ? [value?.id] : []);
                                                            setGrowerSelected(value as Grower);
                                                        }
                                                        trigger("growerName");
                                                    }}
                                                    autoSelect={true}
                                                    selectOnFocus
                                                    clearOnBlur
                                                    handleHomeEndKeys
                                                />
                                            )}
                                        />
                                    }
                                />

                                <FormField
                                    title={'Grower Cell Number'}
                                    required={watch("sendGrowerSamplingNotification")}
                                    error={errors.growerPhoneNumber?.message}
                                    input={
                                        <TextField variant="outlined"
                                            //value={watch("growerName")}

                                            color={errors.growerPhoneNumber ? "error" : "primary"}
                                            fullWidth
                                            // value={growerPhone}
                                            {...register("growerPhoneNumber", {
                                                required: !watch("sendGrowerSamplingNotification") ? undefined : 'Please provide a cell phone number to enable grower text notifications',
                                                pattern: {
                                                    value: /^\+?(?=.*\d.*\d.*\d.*\d.*\d.*\d.*\d.*\d.*\d.*\d)(?=.*\d?.*\d?.*\d?.*\d?.*\d?.*\d?.*\d?.*\d?.*\d?.*\d?.*\d?).*$/,
                                                    message: 'Please enter a valid 10 or 11 digit cell phone number'
                                                }
                                            })
                                            }
                                        />}
                                />
                            </Grid>
                        </AccordionDetails>
                    </Accordion>
                </Grid>


                <Grid item xs={12} className="single-upload-group">
                    <Accordion defaultExpanded={true}>
                        <AccordianSummary expandIcon={<ExpandMoreIcon />}>
                            <PortalTooltip title="Job Data">
                                <Typography variant='h6'>Job Data</Typography>
                            </PortalTooltip>
                        </AccordianSummary>
                        <AccordionDetails >
                            <Grid
                                alignItems="stretch"
                                alignContent="stretch"
                                container
                                maxWidth="md"
                                margin="auto"
                                spacing={1}
                            >


                                <FormField
                                    title="Field Name"
                                    error={errors.fieldName?.message}
                                    required
                                    helpText="Enter the name of the field"
                                    input={
                                        <TextField
                                            variant="outlined"
                                            color={errors.fieldName ? "error" : "primary"}
                                            fullWidth {
                                            ...register("fieldName", {
                                                required: "Field name is required"
                                            })
                                            }
                                        />
                                    }
                                />

                                <FormField
                                    title="Farm Name"
                                    helpText="Only required if field name is not unique"
                                    input={
                                        <TextField
                                            variant="outlined"
                                            fullWidth {...register("farmName")}
                                        />}
                                />


                                <FormField
                                    title="Event/Sync Code"
                                    helpText="This is used to sync your data with the appropriate software"
                                    input={
                                        <TextField
                                            variant="outlined"
                                            color={errors.eventId ? "error" : "primary"}
                                            fullWidth
                                            {
                                            ...register("eventId")
                                            }
                                        />}
                                    required={eventIDRequired}
                                    hidden={!eventIDRequired}
                                />


                                <FormField
                                    title="Ready to Sample Now"
                                    helpText="Select if field is ready to sample now"
                                    input={
                                        <Checkbox {
                                            ...register("isReadyToSample")
                                        }
                                        />
                                    }
                                />

                                {isReadyToSample && <FormField
                                    title="Field Priority"
                                    helpText="Select the priority of the field"
                                    input={
                                        <Select
                                            fullWidth
                                            value={watch("fieldPriority")}
                                            {
                                            ...register("fieldPriority", {
                                                required: "Configuration is required",
                                            })
                                            }>
                                            {(JobsPriorityOvrd).map((priority, index) => {
                                                return (<MenuItem key={index} value={priority}>{priority}</MenuItem>);
                                            })}
                                        </Select>}
                                />}

                                {FileField("Field Boundary", "This is for your field boundary shp file", "boundarySHP", watch(), !filesNotNeeded, filesNotNeeded)}

                                {FileField("Zone Regions", "This is for your Zone shp file", "zonesSHP", watch(), !filesNotNeeded && jobType == "Zone", filesNotNeeded || !(jobType == "Zone"))}

                                <FormField
                                    title="Attach Points?"
                                    helpText="Select if you want to attach points (points should be attached for Zones and ModGrid), then upload points file"
                                    input={
                                        <Checkbox {
                                            ...register("isPointsAttached")
                                        }
                                        />}
                                    hidden={filesNotNeeded}
                                />

                                {FileField("Points", "This is for your point shp file", "pointsSHP", watch(), watch("isPointsAttached"), filesNotNeeded || !watch("isPointsAttached"))}

                                {/* <FormField
                                    title="Record HD Boundary?"
                                    helpText="Select this is you want us to collect a boundary on your field"
                                    input={
                                        <Checkbox {
                                            ...register("collectBoundary")
                                        }
                                        />}
                                /> */}

                                <FormField
                                    title="Lab Presubmission Code"
                                    helpText="This is the code for the lab submission"
                                    input={
                                        <TextField
                                            variant="outlined"
                                            color={errors.eventId ? "error" : "primary"}
                                            fullWidth
                                            {
                                            ...register("eventId")
                                            }
                                        />}
                                    required={labIDRequired}
                                    hidden={!labIDRequired}
                                />



                                <FormField
                                    title="Special Lab Instructions"
                                    helpText="Enter any special notes for lab"
                                    input={
                                        <TextField
                                            variant="outlined"
                                            multiline
                                            rows={3}
                                            fullWidth {
                                            ...register("labInstructions")
                                            }
                                        />}
                                    hidden={props.company && props.company.portalConfigurationOptions.includes("Submission: Show Special Lab Instructions") || true}
                                />

                                <FormField
                                    title="Submission Notes"
                                    helpText="Enter any notes for the submission(restrictions, special notes)"
                                    input={
                                        <TextField
                                            variant="outlined"
                                            multiline
                                            rows={3}
                                            fullWidth {
                                            ...register("submissionNotes")
                                            }
                                        />}
                                />

                                {FileField("Field Notes", "This is for any additional field files, like pullin instructions", "fieldNotes", watch(), false, false)}

                                {/* TODO we have a better superuser construct but that may be only in the cloud
                                It's also not a big deal of someone finds this code and makes a test job */}
                                {userContext?.email.includes('@rogoag.com') &&
                                    <FormField
                                        title="Testing?"
                                        helpText="Enable if this is a testing job"
                                        input={
                                            <Checkbox {
                                                ...register("isTestJob")
                                            }
                                            />}
                                    />
                                }

                            </Grid>
                        </AccordionDetails>
                    </Accordion>
                </Grid>
                <div style={{ padding: 5, paddingLeft: 8 }}>
                    <Typography> Fields marked with <a style={{ color: 'red' }}>*</a> are required</Typography>
                </div>



                <Grid item xs={12} className="single-upload-group">
                    <Button fullWidth type="submit" variant="contained" color="primary">
                        <Publish className="icon-in-button" />Create Job
                    </Button>
                </Grid>
            </Grid>
            <br />
            <br />
            <br />
            <br />
            <br />
        </>)
}