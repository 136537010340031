import * as React from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import { useNavigate } from 'react-router-dom';
import { Typography } from '@mui/material';
import { LoadingComponent } from '../helpers/LoadingComponent';
import { API } from '../../api';
import { Oops } from '../../utilities/oops';
import { DEFAULT_PAGE } from '../../utilities/constants';
import { AuthUser } from '../../api/common';

interface LoginProps {
  setLoggedIn: (user: AuthUser) => void;
}

export default function LoginForm(props: LoginProps) {
  const [username, setEmail] = React.useState('');
  const [password, setPassword] = React.useState('');
  const [loading, setLoading] = React.useState(false);
  const [emailError, setEmailError] = React.useState('');
  const [passwordError, setPasswordError] = React.useState('');
  const [error, setError] = React.useState('');
  const navigate = useNavigate();

  const emailPattern = /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/;
  const handleEmailChange = (event: { target: { value: React.SetStateAction<string>; }; }) => {
    setEmailError('');
    // validate that username is an email with regex
    if (!emailPattern.test(event.target.value.toString())) {
      setEmailError("Invalid email address");
    }

    setEmail(event.target.value);
  };

  const handlePasswordChange = (event: { target: { value: React.SetStateAction<string>; }; }) => {
    setPassword(event.target.value);
  };

  const handleSubmit = async (event: { preventDefault: () => void; }) => {
    try {
      setError('');
      if (!username) {
        setEmailError('');
        return;
      }
      if (!password) {
        setPasswordError('');
        return;
      }
      setLoading(true);
      event.preventDefault();
      const user = await API.Authentication.login(username, password);
      if (user) {
        props.setLoggedIn(user);
        navigate(DEFAULT_PAGE);
      }
    } catch (error: any) {
      Oops.handleError(error, "Authentication.Login.handleSubmit", username);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Box
      className='login-form'
      maxWidth="md" margin="auto"
      component="form"
      onSubmit={handleSubmit}
      noValidate
      sx={{
        mt: 1,
      }}
    >
      <TextField
        variant="outlined"
        margin="normal"
        required
        error={!!emailError}
        disabled={loading}
        fullWidth
        id="email"
        label="Email"
        name="email"
        autoComplete="email"
        autoFocus
        value={username}
        onChange={handleEmailChange}
        data-sentry-component="Email Field"
      />
      <TextField
        variant="outlined"
        margin="normal"
        error={!!passwordError}
        required
        disabled={loading}
        fullWidth
        name="password"
        label="Password"
        type="password"
        id="password"
        autoComplete="current-password"
        value={password}
        onChange={handlePasswordChange}
        data-sentry-component="Password Field"
      />
      <Button
        type="submit"
        disabled={loading || !username || !password || !!emailError || !!passwordError}
        fullWidth
        variant="contained"
        sx={{ mt: 3, mb: 2 }}
        data-sentry-component="Submit Button"
      >
        Sign In
      </Button>
      <Typography
        variant="body2"
        color="textSecondary"
        align="center"
        style={{ color: 'red' }}
      >
        {error}
      </Typography>
      {(loading) && (
        <Box display="flex" justifyContent="center" alignItems="center">
          <LoadingComponent />
        </Box>
      )}
      <Box sx={{ alignContent: 'center', justifyContent: 'center' }} display="flex" >
        <Button
          style={{ margin: 2 }}
          disabled={loading}
          onClick={() => navigate('/register')}
          color="primary"
          variant='contained'
          data-sentry-component="Register Button"
        >
          Register
        </Button>
        <Button
          style={{ margin: 2 }}
          disabled={loading}
          onClick={() => navigate('/forgot-password')}
          color="primary"
          variant='outlined'
          data-sentry-component="Forgot Password Button"
        >
          Forgot Password
        </Button>
      </Box>
    </Box>
  );
}
