import { Box, Button, List, ListItem, Typography } from "@mui/material";
import { useState } from "react";
import JSZip from "jszip";
import ClearIcon from '@mui/icons-material/Clear';
import PortalTooltip from "../../helpers/PortalTooltip";
import { DownloadFile } from "../../../utilities/downloadFile";

interface ListOfFilesProps<T extends File> {
    files: T[];
    truncate?: number;
    onClear?: (file: File) => void;
}

function getRogoFileShapeTooltip<T extends File>(file: T) {
    const shapeCounts = 'shapeCounts' in file ? file.shapeCounts : {};
    if (!shapeCounts) {
        return 'No shape counts available';
    }
    const shapeTypes = Object.keys(shapeCounts);
    // @ts-ignore TODO fix this typing...
    const shapeCountsStr = shapeTypes.map(type => `${type}: ${shapeCounts[type]}`).join('\n');
    return `Shape Counts:
${shapeCountsStr}`;
}

// yup, a silly name, but FileList is already taken...
export function ListOfFiles<T extends File>(props: ListOfFilesProps<T>) {
    const [truncated, setTruncated] = useState<boolean>(true);
    const files = props.files;
    // console.log(files);
    return (
        <List>
            {(truncated && props.truncate ? files.slice(0, props.truncate) : files).map(file => (
                <PortalTooltip title={<Typography>{getRogoFileShapeTooltip(file)}</Typography>}>
                    <ListItem key={file.name}>
                        {/* show filename and delete button */}
                        {!!props.onClear && <Button onClick={() => props.onClear!(file)}><ClearIcon /></Button> }
                        {file.name}
                    </ListItem>
                </PortalTooltip>
            ))}
            <ListItem>
                <Box display={'flex'} marginBlockEnd={5}>
                    {truncated && props.truncate && 
                    <Button style={{ marginRight: 5 }} disabled={props.files.length < props.truncate} variant='contained' onClick={() => {
                        setTruncated(false);
                    }}>{`Show ${props.files.length > props.truncate ? props.files.length - props.truncate : 0} More`}</Button>}

                    {!truncated && props.truncate &&
                        <Button style={{ marginRight: 5 }} disabled={props.files.length < props.truncate} variant='contained' onClick={() => {
                            setTruncated(true);
                        }}>Show Less</Button>}

                    <Button style={{ marginRight: 5 }} variant='contained' onClick={() => {
                        DownloadFile.fromFiles(files, `files-${Date.now()}.zip`);
                    }}>Download Files As Zip</Button>
                </Box>
            </ListItem>
        </List>
    );
}