
import JSZip from "jszip";
import { IAirtableAttachment } from "@rogoag/airtable";
import axios from "axios";
import { Merge } from '@rogoag/utilities';
import { API } from "../api";
import { FileResponseType } from "../api/routers/aws";

const DEFAULT_FILE_NAME = 'rogo_files.zip';

//these namespaces are dependent on the API, so moving them will have to wait until the API is moved

/**
 * Operations for downloading to the user's computer
 */
export namespace DownloadFile {

    export async function fromFiles(files: File[], filename: string = "") {
        const zip = new JSZip();
        files.forEach(file => {
            zip.file(file.name, file);
        });
        await DownloadFile.fromZip(zip, filename);
    }

    interface GroupOfFiles {
        key: string;
        files: File[];
    }

    export async function fromGroupsOfFiles(files: GroupOfFiles[], filename: string = "") {
        function addFilesToZip(files: File[], folderName: string) {
            const folder = zip.folder(folderName);
            files.forEach(file => {
                folder?.file(file.name, file);
            });
        };

        const zip = new JSZip();
        files.forEach(file => {
            addFilesToZip(file.files, file.key);
        });
        await DownloadFile.fromZip(zip, filename);
    }

    export async function fromAttachment(attachment: IAirtableAttachment, filename: string = "", zip: boolean = false, jobId: string = "") {
        return await DownloadFile.fromAttachments([attachment], filename, zip, false, jobId);
    }

    export async function fromAttachments(attachments: IAirtableAttachment[], filename: string = "", zip: boolean = false, mergeCSV: boolean = false, jobId: string = "") {
        if (attachments.length === 1) {
            await DownloadFile.fromURL(attachments[0].url, filename || attachments[0].filename, zip);
        } else {
            if (mergeCSV) {
                const csvs = (await Promise.all(attachments.map(f => LoadFile.fromAttachment(f, jobId, 'text')))) as string[];
                const mergedCSV = Merge.CSV(csvs);
                const blob = new Blob([mergedCSV], { type: 'text/csv' });
                DownloadFile.fromBlob(blob, `${filename}.csv`);
            } else {
                const blobs = await getBlobs(attachments);
                const zipFile = zipBlobs(blobs, attachments);
                await DownloadFile.fromZip(zipFile, filename);
            }
        }
    }
    
    export async function fromURL(url: string, filename: string = "", zip: boolean = false) {
        const blob = await getBlob(url);
    
        if (zip) {
            const zip = zipBlob(blob, filename);
            await DownloadFile.fromZip(zip, filename);
        } else {
            DownloadFile.fromBlob(blob, filename);
        }
    }

    export async function fromZip(zip: JSZip, filename: string = "") {
        const content = await zip.generateAsync({ type: 'blob' });
        if (!filename.endsWith('.zip')) filename += '.zip';
        DownloadFile.fromBlob(content, filename);
    }

    export function fromBlob(content: Blob, filename: string = DEFAULT_FILE_NAME) {
        const u = URL.createObjectURL(content);
        const a = document.createElement('a');
        a.href = u;
        a.download = filename;
        a.click();
        URL.revokeObjectURL(u);
    }
}

/**
 * Operations for downloading data into the frontend (not to the user's computer)
 */
export namespace LoadFile {
    export async function fromAttachment<T = ArrayBuffer | Blob | string>(attachment: IAirtableAttachment, recordId: string, responseType: FileResponseType) {
        if (await API.AWS.S3.doesFileExist(attachment.filename, recordId)) {
            // first we will try to download the file from S3
            //  - because it's more trustworthy than Airtable
            //  - and because most of these files are already in S3
            return await API.AWS.S3.download<T>(attachment.filename, responseType, recordId);
        } else {
            // if that fails, we will try to download the attachment directly
            return await LoadFile.fromURL<T>(attachment.url, responseType);
        }
    }

    export async function fromURL<T = ArrayBuffer | Blob | string>(url: string, responseType: FileResponseType) { 
        const response = await axios.get<T>(url, { responseType });
        if (response.status === 200) return response.data;
    }
}

async function getBlobs(files: IAirtableAttachment[]) {
    return await Promise.all(files.map(async (file) => {
        return await getBlob(file.url);
    }));
}

async function getBlob(url: string) {
    const response = await fetch(url);
    const blob = await response.blob();
    return blob;
}

function zipBlobs(blobs: (Blob | null)[], files: IAirtableAttachment[]) {
    const zip = new JSZip();
    const validBlobs = blobs.filter(blob => !!blob);
    validBlobs.forEach((blob, i) => {
        zip.file(files[i].filename, blob);
    });
    return zip;
}

function zipBlob(blob: Blob, filename: string) {
    const zip = new JSZip();
    zip.file(filename, blob);
    return zip;
}



