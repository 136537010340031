import { GrowersFieldIdMapping } from "@rogoag/airtable/rogo.at";
import { Growers, } from '@rogoag/airtable';
import { Company, Grower } from '../../../types/data_models';
import { Filter, FilterFieldIds, FilterField } from '../../../common';
import { DataAPI_Base, GetAPI_Base } from "../base";

class GetAPI_Growers extends GetAPI_Base<Growers, Grower> {
    constructor(fieldIds: FilterFieldIds | undefined = undefined) { super("growers", GrowersFieldIdMapping, fieldIds); }

    public async byCompany(company: Company) {
        if (!company.name) return [];
        const filter = { fields: [{ companyName: company.name } as FilterField] } as Filter;
        const growers = await super.byFilter(filter);
        return growers;
    }
}

export class GrowersAPI extends DataAPI_Base<Growers, typeof GrowersFieldIdMapping, Grower> {
    get: GetAPI_Growers;

    constructor() { 
        const filterFieldIds = { companyName: GrowersFieldIdMapping['Clients Unique'] } as FilterFieldIds;
        super("growers", GrowersFieldIdMapping, filterFieldIds);
        this.get = new GetAPI_Growers(filterFieldIds);
     }
}