import { MRT_Cell, MRT_ColumnDef } from "material-react-table";
import { Accessor } from "../../../utilities/types";
import { FieldSet } from 'airtable';
import { BaseDataModel } from "../../../api";

interface DateColumnOptions<T extends FieldSet, U extends BaseDataModel<T>> {
  accessorKeyOrFn?: Accessor<Date, T, U>;
  defaultText?: string;
  isRangeFilter?: boolean;
}

const getDateColumnText = <T extends FieldSet, U extends BaseDataModel<T>>(cell: MRT_Cell<U, unknown>, defaultText: string) => {
  const date = cell.getValue<Date>();
  return !date ? defaultText : date.toLocaleDateString();
};

export const dateColumn = <T extends FieldSet, U extends BaseDataModel<T>>(
  header: string,
  {
    accessorKeyOrFn = undefined,
    defaultText = 'Not Sampled',
    isRangeFilter = false
  }: DateColumnOptions<T, U> = {}
) => ({
  id: header.toLowerCase(),
  // @ts-ignore TODO Need to make this a better type based paradigm...
  accessorFn: (row) => typeof accessorKeyOrFn === 'function' ? accessorKeyOrFn(row) : (accessorKeyOrFn ? row[accessorKeyOrFn] as Date | undefined : undefined),
  header,
  size: 100,
  visibleInShowHideMenu: true,
  filterVariant: isRangeFilter ? 'date-range' : 'date',
  filterFn: isRangeFilter ? 'betweenInclusive' : 'greaterThanOrEqualTo',
  enableGrouping: true,
  Cell: ({ cell }) => { return getDateColumnText<T, U>(cell, defaultText); },
  GroupedCell: ({ cell }) => { return getDateColumnText<T, U>(cell, defaultText); },
}) as MRT_ColumnDef<U>;
